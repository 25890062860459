import { Switch, Route, Redirect } from "react-router-dom";
import AppLayout from "@components/Layouts/AppLayout";
import PolicyTypesPage from "./policy-types";
import CompaniesPage from "./companies";
import ReferencesPage from "./references";
import ClientsPage from "./clients";
import PoliciesPage from "./policies";
import AgenciesPage from "./agencies";
import RenewalPoliciesPage from "./renewalPolicies";
// import SearchPolicyPage from './searchPolicy'
import LicencesPage from "./licences";

function AuthPage() {
  return (
    <AppLayout>
      <Switch>
        <Route exact path="/app/renewal-policies" component={RenewalPoliciesPage} />
        <Route exact path="/app/clients" component={ClientsPage} />
        <Route exact path="/app/policies" component={PoliciesPage} />
        <Route exact path="/app/agencies" component={AgenciesPage} />
        <Route exact path="/app/policy-Types" component={PolicyTypesPage} />
        <Route exact path="/app/companies" component={CompaniesPage} />
        <Route exact path="/app/references" component={ReferencesPage} />
        <Route exact path="/app/licences" component={LicencesPage} />
        <Redirect to="/app/renewal-policies" />
      </Switch>
    </AppLayout>
  );
}

export default AuthPage;

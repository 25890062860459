import React from "react";
import "./index.scss";
// import CustomModal from "@components/CustomModal";
import CompanyForm from "@components/Companies/CompaniesForm";
import AddOrEditModal from "@components/AddOrEditModal";

const CompaniesAddOrEditModal = ({ isEdit, component, data }) => {
  return <AddOrEditModal component={component} formInitialData={data} isEdit={isEdit} title={isEdit ? "Edit company" : "Add company"} formComponent={CompanyForm} />;
};

export default CompaniesAddOrEditModal;

import React, { useRef } from "react";
import "./pdf.css";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import { Tooltip, Space } from "antd";

const DownloadPDF = ({ row, withLabel }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <div>
      {withLabel ? (
        <Space onClick={handlePrint}>
          <DownloadOutlined /> Download
        </Space>
      ) : (
        <Tooltip placement="bottom" title="Download">
          <DownloadOutlined onClick={handlePrint} />{" "}
        </Tooltip>
      )}

      <div style={{ display: "none" }}>
        <ComponentToPrint row={row} ref={componentRef}></ComponentToPrint>
      </div>
    </div>
  );
};
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      row: { vehicle_number, client_name, client_address, fees, client_phone, policy_number, start_date, vehicle_make, end_date, chassis_no, sum_insured, engine_no, premium, model }
    } = this.props;

    return (
      <div className="main">
        <div>{moment().format("DD-MM-YYYY")}</div>

        <div className="title">ABHISHEK AUTO ADVISER</div>
        <div className="address">
          <span>34, SUNRISE CHEMBER MINI BAZAR VARACHHA ROAD SURAT</span>
          <span style={{ marginBottom: "34px" }}>PHONE NO :- 8550003 , 8545660</span>
        </div>

        <div className="vhnumDiv">
          <span className="vhnum">Vehicle Number : - {vehicle_number}</span>
        </div>

        <div className="tableDiv">
          <div className="pdf-table">
            <div className="row mb-2">
              <div className="w-15  ">
                <strong>Name</strong>
              </div>
              <div className="value">{client_name}</div>
            </div>

            <div className="row mb-2">
              <div className="w-15  ">
                <strong>Address</strong>
              </div>
              <div className="value">{client_address}</div>
            </div>

            <div className="row mb-2">
              <div className="w-15 ">
                <strong>Phone No</strong>
              </div>
              <div className="value">+{client_phone}</div>
            </div>
          </div>
        </div>

        <div className="tableDiv">
          <div className="pdf-table">
            <div className="table-row mb-2">
              <div className="w-30">
                {" "}
                <strong>Policy No</strong>
              </div>
              <div className="value w-20">{policy_number}</div>
              <div className="w-30">
                {" "}
                <strong>Vehicle Make</strong>
              </div>
              <div className="value w-20">{vehicle_make}</div>
            </div>

            <div className="table-row mb-2">
              <div className="w-30">
                {" "}
                <strong>Start Date</strong>
              </div>
              <div className="value w-20">{moment(start_date).format("DD-MM-YYYY")}</div>
              <div className="w-30">
                {" "}
                <strong>End Date</strong>
              </div>
              <div className="value w-20">{moment(end_date).format("DD-MM-YYYY")} </div>
            </div>

            <div className="table-row mb-2">
              <div className="w-30">
                {" "}
                <strong>Chasis No</strong>
              </div>
              <div className="value w-20">{chassis_no}</div>
              <div className="w-30">
                {" "}
                <strong>Eng No</strong>
              </div>
              <div className="value w-20">{engine_no}</div>
            </div>

            <div className="table-row mb-2">
              <div className="w-30">
                {" "}
                <strong>Sum Insured</strong>
              </div>
              <div className="value w-20"> {!!sum_insured ? `₹ ${sum_insured}` : "₹ 0"}</div>
              <div className="w-30">
                {" "}
                <strong>Premium</strong>
              </div>
              <div className="value w-20">{!!premium ? `₹ ${premium}` : "₹ 0"}</div>
            </div>

            <div className="table-row mb-2">
              <div className="w-30">
                {" "}
                <strong>Model</strong>
              </div>
              <div className="value w-20">{model}</div>
              <div className="w-30">
                {" "}
                <strong>Fees</strong>
              </div>
              <div className="value w-20">{!!fees ? `₹ ${fees}` : "₹ 0"}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadPDF;

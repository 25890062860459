import axios from "axios";
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/v1`, //${process.env.REACT_APP_BACKEND_URL}/api/v1
  headers: {
    common: {
      "Content-Type": "application/json"
    }
  }
});

instance.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user-token")}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// response inceptors for handling response
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (401 === error.response.status) {
      localStorage.clear();
      window.location.href = "/auth/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;

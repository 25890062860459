import React from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { addCompany, editCompany } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";

const layout = {
  labelCol: {
    span: 10
  },
  wrapperCol: {
    span: 24
  }
};

const CompaniesForm = ({ isEdit, handleClose, data = {} }) => {
  const dispatch = useDispatch();
  const { id, name = "" } = data;
  const { loading } = useSelector(({ companies }) => {
    return {
      loading: companies.manipulateCompanyLoading
    };
  });

  const onFinish = formData => {
    const { name = "" } = formData;
    const body = {
      name
    };

    if (isEdit) {
      dispatch(editCompany(id, body))
        .then(() => {
          handleClose();
          message.success("Company updated successfully.");
        })
        .catch(err => message.error("Something went wrong"));
    } else {
      dispatch(addCompany(body)).then(() => {
        handleClose();
        message.success("Company added successfully.");
      });
    }
  };

  return (
    <Form {...layout} layout="vertical" onFinish={onFinish} key={"company-form"} initialValues={{ name }} size="small">
      <Row>
        <Col xs={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is required"
              }
            ]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
      </Row>

      <div className="modal-actions">
        <Button size="small" onClick={handleClose} className="cancel-button" tabIndex="-1">
          Cancel
        </Button>
        <Button size="small" type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default CompaniesForm;

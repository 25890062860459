import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, Badge, Dropdown, Image } from "antd";
// import { ReactComponent as NotificationSvg } from "@icons/notification.svg";
import UsernameAvatar from "@components/UsernameAvatar";
// import Notifications from "./notifications";
import UserMenu from "./user-menu";
import { MenuOutlined } from "@ant-design/icons";
import Logo from "@images/logo.png";
import styles from "./index.module.scss";
import classnames from "classnames";
import { isMobile } from "react-device-detect";
const Header = ({ onMenuClick }) => {
  // const [visibleNotifications, setVisibleNotifications] = React.useState(false);
  const [visibleUserMenu, setVisibleUserMenu] = React.useState(false);
  const { pathname } = useLocation();
  const {
    user: { first_name, last_name }
  } = useSelector(({ auth }) => {
    return {
      user: auth.userData
    };
  });
  // const notifications = useSelector(state => state.notifications.notifications);
  // const unackedCount = notifications.filter(notif => !notif.Ack).length;

  React.useEffect(() => {
    // setVisibleNotifications(false);
    setVisibleUserMenu(false);
  }, [pathname]);

  return (
    <Layout.Header className={classnames(styles.header)}>
      {isMobile ? <MenuOutlined className={styles.drawerToggle} onClick={onMenuClick} /> : ""}

      {/* <div style={{ flex: 1 }} /> */}

      {/* <Dropdown
        trigger="click"
        overlay={<Notifications />}
        visible={visibleNotifications}
        onVisibleChange={setVisibleNotifications}
      >
        <Badge count={unackedCount} className={styles.notification}>
          <NotificationSvg />
        </Badge>
      </Dropdown> */}

      <Image className={styles.headerLogo} preview={false} src={Logo} />

      <Dropdown trigger="click" overlay={<UserMenu closeMenu={() => setVisibleUserMenu(false)} />} visible={visibleUserMenu} onVisibleChange={setVisibleUserMenu}>
        <Badge dot className={styles.avatar}>
          {/* <Avatar size={40} src={(user && user.LogoUrl) || AvatarUrl} /> */}
          <UsernameAvatar username={first_name[0] + last_name[0]} backgroundColor="rgb(209, 0, 28)" hideTooltip={true} />
        </Badge>
      </Dropdown>
    </Layout.Header>
  );
};

export default Header;

import { Tooltip, Avatar } from "antd";

const COLORS = [
  "#009FD4",
  "#B381B3",
  "#939393",
  "#80884e",
  "#11887b",
  "#420303",
  "#840000",
  "#020035",
  "#34414e",
  "#040348",
  "#214559",
  "#373e02",
  "#3e6257",
  "#062e03",
  "#00022e",
  "#112222",
  "#29304e",
  "#004953",
  "#35654d",
  "#033500",
  "#044a05",
  "#d1001c",
  "#c65102",
  "#e6ba45",
  "#d5b60a",
  "#b4262a",
  "#391285",
  "#203e4a",
  "#184343",
  "#E3BC00",
  "#D47500",
  "#DC2A2A",
  "#00AA55"
];

const UsernameAvatar = ({ username, backgroundColor, hideTooltip }) => {
  if (!username) return;
  const colorIndex = username.charCodeAt(0) - 97;
  const color = COLORS[colorIndex];

  return hideTooltip ? (
    <Avatar style={{ color: "white", backgroundColor: backgroundColor || color }}>{username.slice(0, 1).toUpperCase()}</Avatar>
  ) : (
    <Tooltip title={username}>
      <Avatar size="small" style={{ color: "white", backgroundColor: backgroundColor || color }}>
        {username.slice(0, 1).toUpperCase()}
      </Avatar>
    </Tooltip>
  );
};

export default UsernameAvatar;

/* eslint-disable no-unused-vars */
import { map, remove } from "lodash";
import {
  ADD_AGENCY,
  ADD_AGENCY_SUCCESS,
  ADD_AGENCY_FAILURE,
  EDIT_AGENCY,
  EDIT_AGENCY_SUCCESS,
  EDIT_AGENCY_FAILURE,
  REMOVE_AGENCY,
  REMOVE_AGENCY_SUCCESS,
  REMOVE_AGENCY_FAILURE,
  GET_AGENCIES,
  GET_AGENCIES_SUCCESS,
  GET_AGENCIES_FAILURE,
  GET_AGENCIES_BY_COMPANY_ID,
  GET_AGENCIES_BY_COMPANY_ID_SUCCESS,
  GET_AGENCIES_BY_COMPANY_ID_FAILURE,
  HENDLE_PAGINATION_AGENCY,
  HANDLE_SEARCH_AGENCY
} from "../action/actionType";

let initialState = {
  //AGENCY
  manipulateAgencyLoading: false,
  manipulateAgencyError: null,
  removeAgencyLoading: false,
  removeAgencyError: null,

  agencies: [],
  fetchingAgenciesLoading: false,
  fetchingAgenciesError: null,

  error: null,
  totalRecord: 0,

  searchValue: null,

  fetchingAgenciesByCompanyIdLoading: false,
  fetchingAgenciesByCompanyId: null,
  fetchingAgenciesByCompanyError: null
};

const policyReducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD AGENCY
    case ADD_AGENCY:
      return {
        ...state,
        manipulateAgencyLoading: true
      };
    case ADD_AGENCY_SUCCESS:
      return {
        ...state,
        manipulateAgencyLoading: false,
        agencies: [action.payload.agency, ...state.agencies]
      };
    case ADD_AGENCY_FAILURE:
      return {
        ...state,
        manipulateAgencyLoading: false,
        cyError: action.payload.error
      };

    //EDIT AGENCY
    case EDIT_AGENCY:
      return {
        ...state,
        manipulateAgencyLoading: true
      };
    case EDIT_AGENCY_SUCCESS:
      let agencies = map(state.agencies, agency => {
        if (agency.id === action.payload.agency.id) {
          return action.payload.agency;
        } else {
          return agency;
        }
      });
      return {
        ...state,
        manipulateAgencyLoading: false,
        agencies: agencies
      };

    case EDIT_AGENCY_FAILURE:
      return {
        ...state,
        manipulateAgencyLoading: false,
        manipulateAgencyError: action.payload.error
      };

    //REMOVE AGENCY
    case REMOVE_AGENCY:
      return {
        ...state,
        removeAgencyLoading: true
      };
    case REMOVE_AGENCY_SUCCESS:
      remove(state.agencies, { id: action.payload.agencyId });
      return {
        ...state,
        removeAgencyLoading: false
      };

    case REMOVE_AGENCY_FAILURE:
      return {
        ...state,
        removeAgencyLoading: false,
        removeAgencyError: action.payload.error
      };

    //GET AGENCIES
    case GET_AGENCIES:
      return {
        ...state,
        fetchingAgenciesLoading: true
      };
    case GET_AGENCIES_SUCCESS:
      return {
        ...state,
        agencies: action.payload.agencies,
        fetchingAgenciesLoading: false
      };
    case GET_AGENCIES_FAILURE:
      return {
        ...state,
        fetchingAgenciesLoading: true,
        fetchingAgenciesError: action.payload.error
      };

    //GET GET_AGENCIES_BY_COMPANY_ID
    case GET_AGENCIES_BY_COMPANY_ID:
      return {
        ...state,
        fetchingAgenciesByCompanyIdLoading: true
      };
    case GET_AGENCIES_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        fetchingAgenciesByCompanyId: action.payload,
        fetchingAgenciesByCompanyIdLoading: false
      };
    case GET_AGENCIES_BY_COMPANY_ID_FAILURE:
      return {
        ...state,
        fetchingAgenciesByCompanyIdLoading: false,
        fetchingAgenciesByCompanyError: action.payload.error
      };

    case HENDLE_PAGINATION_AGENCY:
      return {
        ...state,
        totalRecord: action.payload
      };

    case HANDLE_SEARCH_AGENCY:
      return {
        ...state,
        searchValue: action.payload
      };

    default:
      return state;
  }
};

export default policyReducer;

import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Menu, Modal, Tooltip } from "antd";
import { PoweroffOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import UsernameAvatar from "@components/UsernameAvatar";
import { logout } from "@redux/action";
import styles from "./index.module.scss";
import { socket } from "../../../AppLayout";

const menus = [
  // { path: "/account", label: "View Profile", icon: UserOutlined },
  // { path: "/settings", label: "Settings", icon: SettingOutlined }
];

const UserMenu = ({ closeMenu }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const selectedIndex = menus.findIndex(menu => menu.path === pathname);

  const {
    user: { username, first_name, last_name }
  } = useSelector(({ auth }) => {
    return {
      user: auth.userData
    };
  });

  const handleLogOut = () => {
    dispatch(logout());
    socket.disconnect();
    localStorage.clear();
  };

  const onClickLogOut = () => {
    closeMenu();
    Modal.confirm({
      title: "Are you sure you want to logout now?",
      icon: <QuestionCircleOutlined />,
      okText: "Log Out",
      onOk: () => {
        return handleLogOut();
      }
    });
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <UsernameAvatar username={first_name[0] + last_name[0]} backgroundColor="rgb(209, 0, 28)" hideTooltip={true} />
        <div className={styles.textEllipsis}>
          <Tooltip placement="bottom" title={first_name && last_name && first_name + " " + last_name}>
            <h4 className={styles.textEllipsis}>{first_name && last_name && first_name + " " + last_name}</h4>
          </Tooltip>

          <Tooltip placement="bottom" title={username && username}>
            <span className={styles.textEllipsis} type="secondary">
              {username && username}
            </span>
          </Tooltip>
        </div>
      </div>
      <Menu mode="inline" selectedKeys={[`${selectedIndex}`]} className={styles.menu}>
        {menus.map((menu, index) => (
          <Menu.Item key={index} icon={<menu.icon />}>
            <Link to={menu.path}> {menu.label}</Link>
          </Menu.Item>
        ))}
        <Menu.Item icon={<PoweroffOutlined />} onClick={() => onClickLogOut()}>
          Log Out
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default UserMenu;

import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { FileExcelOutlined, ExportOutlined, FilePdfOutlined } from "@ant-design/icons";

const PrintDropdown = ({ handleMenuSelect }) => {
  const handleMenuClick = e => {
    handleMenuSelect(e);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={1} icon={<FilePdfOutlined />}>
        PDF
      </Menu.Item>
      <Menu.Item key={2} icon={<FileExcelOutlined />}>
        Excel
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} className="print-dropdown" trigger={["click"]}>
      <Button size="small">
        Download <ExportOutlined />
      </Button>
    </Dropdown>
  );
};

export default PrintDropdown;

import {
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  AUTH_USER_LOGOUT
  // AUTH_USER_LOGOUT_SUCCESS,
  // AUTH_USER_LOGOUT_FAILURE
} from "./actionType";
import axios from "@config/axios";

//LOGIN

export const authLogin = payload => {
  return {
    type: AUTH_LOGIN,
    payload: payload
  };
};

export const authLoginSuccess = payload => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload: payload
  };
};

export const authLoginFailure = payload => {
  return {
    type: AUTH_LOGIN_FAILURE,
    payload: payload
  };
};

export const login = (body, handleLoginSuccess, handleLoginFailure) => async dispatch => {
  dispatch(authLogin());

  axios
    .post(`/login`, body)
    .then(res => {
      dispatch(authLoginSuccess(res.data.data.user));
      localStorage.setItem("user-token", res.data.data.token);
      handleLoginSuccess && handleLoginSuccess();
    })
    .catch(error => {
      dispatch(authLoginFailure(error.response ? error.response.data.message : "Something went wrong on server!"));
      handleLoginFailure && handleLoginFailure();
    });
};

export const getUser = payload => {
  return {
    type: FETCH_USER,
    payload: payload
  };
};

export const getUserSuccess = payload => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: payload
  };
};

export const getUserFailure = payload => {
  return {
    type: FETCH_USER_FAILURE,
    payload: payload
  };
};

export const fetchUser = handleSuccess => async dispatch => {
  dispatch(getUser());

  axios
    .get(`/user-profile`)
    .then(res => {
      dispatch(getUserSuccess(res.data.data));
      handleSuccess && handleSuccess();
    })
    .catch(error => {
      dispatch(getUserFailure(error.response ? error.response.data.message : "Something went wrong on server!"));
    });
};

export const logout = payload => {
  return {
    type: AUTH_USER_LOGOUT,
    payload: payload
  };
};

import React, { useState } from "react";
import { Table, Tooltip, Card, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { deleteAgency } from "@redux/action";
import Confirmation from "@components/Confirmation";
import AgenciesAddOrEditModal from "@components/Agencies/AgenciesAddOrEditModal";
import UsernameAvatar from "@components/UsernameAvatar";
import { isMobile } from "react-device-detect";

const AgenciesTable = () => {
  // const dispatch = useDispatch();

  const {
    data,
    loading,
    removeLoading,
    user: { is_master_access }
  } = useSelector(({ agency, auth }) => {
    return {
      data: agency.agencies,
      loading: agency.fetchingAgenciesLoading,
      removeLoading: agency.removeAgencyLoading,
      user: auth.userData
    };
  });

  // useEffect(() => {
  //   !data.length && dispatch(getAgencies());
  // }, [dispatch, data]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      render(text, record) {
        return {
          children: (
            <Card style={{ padding: "10px", fontSize: "12px", width: "100%", border: "transparent" }}>
              <Space direction="vertical">
                <div style={{ fontWeight: "700" }}>{record.name}</div>
                <div>{record.company_name}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Space className="action-buttons" size="middle">
                    <AgenciesAddOrEditModal
                      component={
                        <Tooltip title="Edit" placement="bottom">
                          <EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />
                        </Tooltip>
                      }
                      isEdit={true}
                      data={record}
                    />
                    {is_master_access && (
                      <Confirmation
                        title="Are you sure to delete this?"
                        dispatchActionMethod={deleteAgency}
                        loading={removeLoading}
                        data={record}
                        component={
                          <Tooltip title="Delete" placement="bottom">
                            <DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />
                          </Tooltip>
                        }
                      />
                    )}
                  </Space>
                </div>
              </Space>
            </Card>
          )
        };
      },
      responsive: ["xs"]
    },

    {
      title: "Agency Name",
      dataIndex: "name",
      key: "name",
      filteredValue: filteredInfo.name || null,
      sorter: (a, b) => a.name.localeCompare(b.name),
      // sorter: false, // (a, b) => a.name.length - b.name.length
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      responsive: ["sm"]
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      filteredValue: filteredInfo.company_name || null,
      sorter: false, // (a, b) => a.company_name.length - b.company_name.length
      sortOrder: sortedInfo.columnKey === "company_name" && sortedInfo.order,
      ellipsis: true,
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "updated_by",
      key: "updated_by",
      align: "center",
      width: 40,
      render(text) {
        return {
          children: <UsernameAvatar username={text} />
        };
      },
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      ellipsis: true,
      fixed: isMobile ? "unset" : "right",
      width: 100,
      render(text, record) {
        return {
          children: record ? (
            <Space className="action-buttons">
              <AgenciesAddOrEditModal
                component={
                  <Tooltip title="Edit" placement="bottom">
                    <EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />
                  </Tooltip>
                }
                isEdit={true}
                data={record}
              />
              {is_master_access && (
                <Confirmation
                  title="Are you sure to delete this?"
                  dispatchActionMethod={deleteAgency}
                  loading={removeLoading}
                  data={record}
                  component={
                    <Tooltip title="Delete" placement="bottom">
                      <DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />
                    </Tooltip>
                  }
                />
              )}
            </Space>
          ) : (
            ""
          )
        };
      },
      responsive: ["sm"]
    }
  ];

  return <Table bordered size="small" columns={columns} scroll={isMobile ? {} : { x: 1160 }} dataSource={data} onChange={handleChangeTable} loading={loading} />;
};

export default AgenciesTable;

/* eslint-disable no-unused-vars */
import { GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_FAILURE } from "../action/actionType";

let initialState = {
  report: [],
  fetchingReportLoading: false,
  fetchingReportError: null,
  error: null,
  searchValue: null
};

const policyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    //get report
    case GET_REPORT:
      return {
        ...state,
        fetchingReportLoading: true
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload.report,
        searchValue: action.payload.searchValue,
        fetchingReportLoading: false
      };
    case GET_REPORT_FAILURE:
      return {
        ...state,
        fetchingReportLoading: true,
        fetchingReportError: action.payload.error
      };

    default:
      return state;
  }
};

export default policyTypeReducer;

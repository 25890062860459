import React from "react";
import { pickBy, identity } from "lodash";
import { Form, Input, Button, Row, Col, message } from "antd";
import { addVehicleName } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";

const layout = {
  labelCol: {
    span: 16
  },
  wrapperCol: {
    span: 24
  }
};

const VehicleNamesForm = ({ isEdit, handleClose, data = {}, callBack }) => {
  const dispatch = useDispatch();
  const { name = "" } = data;
  const [form] = Form.useForm();

  const { loading } = useSelector(({ policy }) => {
    return {
      loading: policy.manipulateVehicleNameLoading
    };
  });

  const onFinish = formData => {
    let { name = "" } = form.getFieldsValue(true);

    let body = pickBy(
      {
        name: name.toUpperCase()
      },
      identity
    );

    dispatch(addVehicleName(body)).then(res => {
      handleClose();
      message.success("Vehicle name added successfully.");
      callBack && callBack(res);
    });
  };

  return (
    <Form {...layout} layout="vertical" form={form} onFinish={onFinish} key={"client-form"} initialValues={{ name }} size="small">
      <Row wrap={true} gutter={16}>
        <Col span={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is required"
              }
            ]}
          >
            <Input autoFocus style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </Col>
      </Row>

      <div className="modal-actions">
        <Button size="small" onClick={handleClose} className="cancel-button" tabIndex="-1">
          Cancel
        </Button>
        <Button size="small" type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default VehicleNamesForm;

/* eslint-disable no-unused-vars */
import { map, remove } from "lodash";
import {
  ADD_CLIENT,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  EDIT_CLIENT,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE,
  REMOVE_CLIENT,
  REMOVE_CLIENT_SUCCESS,
  REMOVE_CLIENT_FAILURE,
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
  HENDLE_PAGINATION_CLIENT,
  HANDLE_SEARCH_CLIENT,
  GET_CLIENTS_BY_SEARCH_NAME,
  GET_CLIENTS_BY_SEARCH_NAME_SUCCESS,
  GET_CLIENTS_BY_SEARCH_NAME_FAILURE,
  CLEAR_CLIENTS_BY_SEARCH_NAME,
  LOCAL_ADD_CLIENT_BY_SOCKET,
  LOCAL_UPDATE_CLIENT_BY_SOCKET,
  LOCAL_DELETE_CLIENT_BY_SOCKET
} from "../action/actionType";

let initialState = {
  //AGENCY
  manipulateClientLoading: false,
  manipulateClientError: null,
  removeClientLoading: false,
  removeClientError: null,

  clients: [],
  fetchingClientLoading: false,
  fetchingClientError: null,

  searchValue: null,

  error: null,
  totalRecord: 0,

  clientsSearchByName: [],
  fetchingClientSearchByNameLoading: false,
  fetchingClientSearchByNameError: null
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD POLICYTYPE
    case ADD_CLIENT:
      return {
        ...state,
        manipulateClientLoading: true
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        manipulateClientLoading: false,
        clients: [action.payload, ...state.clients]
      };

    case LOCAL_ADD_CLIENT_BY_SOCKET:
      return {
        ...state,
        clients: [action.payload, ...state.clients]
      };
    case ADD_CLIENT_FAILURE:
      return {
        ...state,
        manipulateClientLoading: false,
        error: action.payload.error
      };

    //edit POLICYTYPE
    case EDIT_CLIENT:
      return {
        ...state,
        manipulateClientLoading: true
      };
    case EDIT_CLIENT_SUCCESS:
      let clients = map(state.clients, agency => {
        if (agency.id === action.payload.client.id) {
          return action.payload.client;
        } else {
          return agency;
        }
      });
      return {
        ...state,
        manipulateClientLoading: false,
        clients: clients
      };

    case LOCAL_UPDATE_CLIENT_BY_SOCKET:
      let localClients = map(state.clients, agency => {
        if (agency.id === action.payload.id) {
          return action.payload;
        } else {
          return agency;
        }
      });
      return {
        ...state,
        clients: localClients
      };

    case EDIT_CLIENT_FAILURE:
      return {
        ...state,
        manipulateClientLoading: false,
        manipulateClientError: action.payload.error
      };

    //remove POLICYTYPE
    case REMOVE_CLIENT:
      return {
        ...state,
        removeClientLoading: true
      };
    case REMOVE_CLIENT_SUCCESS:
      remove(state.clients, { id: action.payload });
      return {
        ...state,
        removeClientLoading: false
      };

    case LOCAL_DELETE_CLIENT_BY_SOCKET:
      remove(state.clients, { id: action.payload.id });
      return {
        ...state
      };

    case REMOVE_CLIENT_FAILURE:
      return {
        ...state,
        removeClientLoading: false,
        removePolicyTypeError: action.payload.error
      };
    //get POLICYTYPE
    case GET_CLIENTS:
      return {
        ...state,
        // clients: action.payload ? [] : [...state.clients],
        fetchingClientLoading: true
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, ...action.payload],
        fetchingClientLoading: false
      };
    case GET_CLIENTS_FAILURE:
      return {
        ...state,
        fetchingClientLoading: true,
        fetchingClientError: action.payload.error
      };

    case HANDLE_SEARCH_CLIENT:
      return {
        ...state,
        searchValue: action.payload
      };

    case HENDLE_PAGINATION_CLIENT:
      return {
        ...state,
        totalRecord: action.payload
      };

    // search by name
    case GET_CLIENTS_BY_SEARCH_NAME:
      return {
        ...state,
        fetchingClientSearchByNameLoading: true,
        clientsSearchByName: []
      };
    case GET_CLIENTS_BY_SEARCH_NAME_SUCCESS:
      return {
        ...state,
        clientsSearchByName: action.payload,
        fetchingClientSearchByNameLoading: false
      };
    case GET_CLIENTS_BY_SEARCH_NAME_FAILURE:
      return {
        ...state,
        fetchingClientSearchByNameLoading: false,
        fetchingClientSearchByNameError: action.payload.error
      };
    case CLEAR_CLIENTS_BY_SEARCH_NAME:
      return {
        ...state,
        clientsSearchByName: []
      };

    default:
      return state;
  }
};

export default clientReducer;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { Layout, Menu, Image } from "antd";
import Logo from "@images/logo.png";
import styles from "./sider.module.scss";
import menus from "../../../../config/navigationConfig";
import { isMobile } from "react-device-detect";

const Sider = ({ className, onClose }) => {
  const { pathname } = useLocation();
  const selectedIndex = menus.findIndex(menu => menu.path === pathname);

  return isMobile ? (
    <Layout.Sider width={258} className={classnames(className, styles.sider)}>
      <div className={styles.logo}>
        <Link to="/">
          <Image preview={false} src={Logo} />
        </Link>
      </div>

      <Menu mode="inline" selectedKeys={[`${selectedIndex}`]} className={styles.menu}>
        {menus.map((menu, index) => (
          <Menu.Item
            key={index}
            icon={
              <span>
                <div></div>
              </span>
            }
            onClick={onClose}
          >
            {menu.path ? <Link to={menu.path}> {menu.label}</Link> : menu.label}
          </Menu.Item>
        ))}
      </Menu>
    </Layout.Sider>
  ) : (
    <div className={styles.sidebarDesktop}>
      <Menu selectedKeys={[`${selectedIndex}`]} mode="inline" inlineCollapsed={true} inlineIndent={12} style={{ maxWidth: "64px" }}>
        {menus.map((menu, index) => (
          <Menu.Item key={index} icon={menu.icon} title={menu.label}>
            <Link to={menu.path}> {menu.label}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default Sider;

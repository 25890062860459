import { combineReducers } from "redux";

import auth from "./auth.reducer";
import policy from "./policy.reducer";
import policyType from "./policyType.reducer";
import references from "./references.reducer";
import agency from "./agency.reducer";
import client from "./client.reducer";
import companies from "./companies.reducer";
import notifications from "./notification.reducer";
import report from "./report.reducer";
import renewalPolicy from "./renewalPolicy.reducer";
import licences from "./licences.reducer";


const appReducer = combineReducers({
  auth,
  policy,
  policyType,
  references,
  agency,
  client,
  companies,
  notifications,
  report,
  renewalPolicy,
  licences
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

import React, { useEffect, useState } from "react";
import { pickBy, identity } from "lodash";
import { Form, Button, Row, Col, Select, DatePicker } from "antd";
import { getPolicyType, getCompany, getAgencies, getClient } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import moment from "moment";
import PolicyTypeTag from "@components/PolicyTypes/PolicyTypeTag";

const rangeConfig = {
  rules: [
    // {
    //   type: "array",
    //   required: !true,
    //   message: "Please select time!"
    // }
  ]
};

const layout = {
  labelCol: {
    span: 16
  },
  wrapperCol: {
    span: 24
  }
};

const PoliciesFilterMenu = ({ isEdit, data = {}, closeMenu, startDate, endDate, filterPolicyDate }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { policy_type_id = "", company_id = "", agency_id = "", start_date = "", end_date = "", refer_id = "" } = data;

  const [agenciesOptionsListByCompany, setAgenciesOptionsListByCompany] = useState([]);
  const { loading, companies, policyTypes, agencies, clients, references } = useSelector(({ companies, policy, policyType, agency, client, references }) => {
    return {
      loading: policy.manipulatePolicyLoading,
      companies: companies.companies,
      policyTypes: policyType.policyTypes,
      agencies: agency.agencies,
      clients: client.clients,
      references: references.references
    };
  });

  useEffect(() => {
    !policyTypes.length && dispatch(getPolicyType());
  }, [policyTypes, dispatch]);

  useEffect(() => {
    !companies.length && dispatch(getCompany());
  }, [companies, dispatch]);

  useEffect(() => {
    !agencies.length && dispatch(getAgencies());
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    !clients.length && dispatch(getClient());
  }, [dispatch, clients]);

  const onFinish = formData => {
    const { agency_id = "", company_id = "", policy_type_id = "", start_date = "", end_date = "", compare_date = "", refer_id = "" } = form.getFieldsValue(true);

    //remove empty string and null values for backend validation
    let body = pickBy(
      {
        agency_id,
        company_id,
        refer_id,
        policy_type_id,
        compare_date,
        start_date: start_date ? moment(start_date) : "",
        end_date: end_date ? moment(end_date) : ""
      },
      identity
    );
    filterPolicyDate(body);
    closeMenu();
  };

  const handleSelectPolicyType = value => {
    // const selectedPolicyType = policyTypes.find(p_type => p_type.id === value);
  };

  const handleSelectCompany = value => {
    form.setFieldsValue({ company_id: value, agency_id: "" });

    const filterAgenciesOptionsListByCompany = agencies.filter(agency => agency.company_id === form.getFieldsValue().company_id);
    setAgenciesOptionsListByCompany(filterAgenciesOptionsListByCompany);
  };
  const handleChangeEndDate = value => {
    form.setFieldsValue({ end_date: value });
  };

  const handleChangeStartDate = value => {
    form.setFieldsValue({ start_date: value });
  };

  const handleResetFormData = () => {
    form.resetFields();
    closeMenu();
    filterPolicyDate(null);
  };

  return (
    <div className="card">
      <Form
        {...layout}
        layout="vertical"
        onFinish={onFinish}
        key={"policy-filter-form"}
        initialValues={{
          start_date: start_date ? moment(start_date) : "",
          end_date: end_date ? moment(end_date) : "",
          company_id,
          agency_id,
          policy_type_id,
          refer_id
        }}
        size="small"
        form={form}
      >
        <Row wrap={true} gutter={16}>
          <Col span={24}>
            <Form.Item label="Compare policy date" name="compare_date">
              <Select
                showSearch
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                // onChange={value => handleSelectPolicyType(value)}
                allowClear
              >
                {[
                  { name: "Start Date", value: "start_date" },
                  { name: "End Date", value: "end_date" }
                ].map((row, i) => {
                  return (
                    <Select.Option key={i} value={row.value}>
                      {row.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row wrap={true} gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="start_date" label="Start Date" {...rangeConfig}>
              <DatePicker format="DD-MM-YYYY" className="w-full" onChange={value => handleChangeStartDate(value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="end_date" label="End Date" {...rangeConfig}>
              <DatePicker format="DD-MM-YYYY" className="w-full" onChange={value => handleChangeEndDate(value)} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Policy type" name="policy_type_id">
              <Select
                showSearch
                filterOption={(input, option) => {
                  return option.children.props.text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={value => handleSelectPolicyType(value)}
                allowClear
              >
                {policyTypes.map((p_type, i) => {
                  return (
                    <Select.Option key={i} value={p_type.id}>
                      {/* {p_type.name} */}
                      <PolicyTypeTag color={p_type.color} text={p_type.name} />
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Company" name="company_id">
              <Select
                showSearch
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={value => handleSelectCompany(value)}
                allowClear
              >
                {companies.map((company, i) => {
                  return (
                    <Select.Option key={i} value={company.id}>
                      {company.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Agency" name="agency_id">
              <Select
                showSearch
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                allowClear
              >
                {agenciesOptionsListByCompany.map((agency, i) => {
                  return (
                    <Select.Option key={i} value={agency.id}>
                      {agency.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Reference" name="refer_id">
              <Select
                showSearch
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                allowClear
              >
                {references.map((agency, i) => {
                  return (
                    <Select.Option key={i} value={agency.id}>
                      {agency.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="filter-menu-actions">
          <div>
            <Button size="small" onClick={handleResetFormData} className="cancel-button" tabIndex="-1" type="link">
              Reset
            </Button>
          </div>
          <div>
            <Button size="small" onClick={closeMenu} className="cancel-button ml-8">
              Cancel
            </Button>
            <Button size="small" type="primary" htmlType="submit" loading={loading} className="ml-8">
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PoliciesFilterMenu;

// import { Link, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { Avatar, Menu, Modal, Tooltip } from "antd";
// import { PoweroffOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { useDispatch } from "react-redux";
// import AvatarUrl from "@images/avatar.svg";
// import { logout } from "@redux/action";
// import styles from "./index.module.scss";

// const UserMenu = ({ closeMenu }) => {
//   const dispatch = useDispatch();
//   const { pathname } = useLocation();

//   const {
//     user: { username, first_name, last_name }
//   } = useSelector(({ auth }) => {
//     return {
//       user: auth.userData
//     };
//   });

//   const handleLogOut = () => {
//     dispatch(logout());
//     localStorage.clear();
//   };

//   const onClickLogOut = () => {
//     closeMenu();
//     Modal.confirm({
//       title: "Are you sure you want to logout now?",
//       icon: <QuestionCircleOutlined />,
//       okText: "Log Out",
//       onOk: () => {
//         return handleLogOut();
//       }
//     });
//   };

//   return (
//     <div className={styles.card}>
//       <div className={styles.header}>
//         <Avatar size={40} src={AvatarUrl} style={{ minWidth: "40px" }} />
//         <div className={styles.textEllipsis}>
//           <Tooltip placement="bottom" title={first_name && last_name && first_name + " " + last_name}>
//             <h4 className={styles.textEllipsis}>{first_name && last_name && first_name + " " + last_name}</h4>
//           </Tooltip>

//           <Tooltip placement="bottom" title={username && username}>
//             <span className={styles.textEllipsis} type="secondary">
//               {username && username}
//             </span>
//           </Tooltip>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserMenu;

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

// Content - shows how tables can be integrated with any other pdf content

function headRows() {
  return [
    {
      sr: "No.",
      name: "Name",
      licence_no: "Licence No.",
      address: "Address",
      mobile: "Mob No.",
      computer_test_date: "Comp. Test Date",
      drive_test_date: "Dr. Test Date",
      expire_date: "Expire Date",
      application_status: "Application Status"
    }
  ];
}

function bodyRows(reportData) {
  var body = [];

  reportData.forEach((licence, i) => {
    body.push({
      sr: i + 1,
      name: licence.name ? licence.name : "",
      licence_no: licence.licence_no ? licence.licence_no : "",
      address: licence.address ? licence.address : "",
      mobile: licence.mobile ? licence.mobile : "",
      computer_test_date: licence.computer_test_date ? moment(licence.computer_test_date).format("DD-MM-YYYY h:mm a") : "",
      drive_test_date: licence.drive_test_date ? moment(licence.drive_test_date).format("DD-MM-YYYY h:mm a") : "",
      expire_date: licence.expire_date ? moment(licence.expire_date).format("DD-MM-YYYY") : "",
      application_status: licence.application_status ? licence.application_status.replace("IN_PROCESS", "IN PROCESS") : ""
    });
  });

  return body;
}

export function licenceReportPdf({ reportData }) {
  if (reportData.length) {
    if (reportData == null) return;
    var doc = new jsPDF("l", "mm", "a4");

    doc.setFontSize(14);
    doc.text(`Licences Report`, 130, 15);
    doc.setFontSize(11);
    doc.setTextColor(100);

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    doc.text(`Total : ${reportData.length} Licences`, 14, 20);
    doc.text(`${moment().format("DD-MM-YYYY")}`, 263, 20);
    doc.autoTable({
      head: headRows(),
      body: bodyRows(reportData),
      startY: 25,
      styles: {
        fontSize: 8
      }
      // showHead: "firstPage"
    });

    doc.save(`Licences Report (${moment().format("MMM YYYY")}).pdf`);
  } else {
    alert("There isn't any data to generate report!");
  }
}

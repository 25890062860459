import { useEffect } from "react";

export const setRoutes = config => {
  let routes = [...config.routes];

  if (config.settings || config.auth) {
    routes = routes.map(route => {
      let auth = config.auth ? [...config.auth] : null;
      auth = route.auth ? [...auth, ...route.auth] : auth;
      return {
        ...route,
        settings: { ...config.settings, ...route.settings },
        auth
      };
    });
  }

  return [...routes];
};
export const generateRoutesFromConfigs = configs => {
  let allRoutes = [];
  configs.forEach(config => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const hasPermission = (authArr, userRole) => {
  /**
   * If auth array is not defined
   * Pass and allow
   */
  if (authArr === null || authArr === undefined) {
    // console.info("auth is null || undefined:", authArr);
    return true;
  } else if (authArr.length === 0) {
    /**
     * if auth array is empty means,
     * allow only user role is guest (null or empty[])
     */
    // console.info("auth is empty[]:", authArr);
    return !userRole || userRole.length === 0;
  } else {
    /**
     * Check if user has grants
     */
    // console.info("auth arr:", authArr);
    /*
          Check if user role is array,
          */
    if (userRole && Array.isArray(userRole)) {
      return authArr.some(r => userRole.indexOf(r) >= 0);
    }

    /*
          Check if user role is string,
          */
    return authArr.includes(userRole);
  }
};
export const stringTruncator = (string, length) => {
  return string.slice(0, length) + "...";
};
export const generateQuery = where => {
  let condition = [],
    filterdWhere = "";
  if (!isEmpty(where)) {
    for (let i in Object.keys(where)) {
      // filterdWhere = " where ";
      condition.push(`${Object.keys(where)[i]}=${Object.values(where)[i]}`);
    }
  }

  filterdWhere = condition.length > 0 ? condition.join("&") : filterdWhere;
  return filterdWhere;
};

export const isEmpty = value => {
  return value === undefined || value === null || (typeof value === "object" && Object.keys(value).length === 0) || (typeof value === "string" && value.trim().length === 0);
};

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

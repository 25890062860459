import React, { useEffect } from "react";
import Section from "@components/Section";
import { Space, Button, Input, Typography, Select, Dropdown, Badge } from "antd";
import LicencesTable from "@components/Licences/LicencesTable";
import LicencesAddOrEditModal from "@components/Licences/LicencesAddOrEditModal";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getLicence } from "@redux/action";
import { useSelector } from "react-redux";
// import { useDebouncedEffect } from "@components/DebouncedEffect";
import _ from "lodash";
import debounce from "lodash.debounce";
import moment from "moment";
import LicencesFilterMenu from "@components/Licences/LicencesFilterMenu";
import PrintDropdown from "@components/PrintDropdown";
import DownloadCSV from "@components/GenerateCSV";
import { licenceReportPdf } from "@components/GeneratePDF/LicenceReportPdf";
import "./index.scss";
import { isMobile } from "react-device-detect";

const { Title } = Typography;

const options = [
  {
    label: `Since ${moment()
      .startOf("year")
      .add(-+1, "years")
      .format("DD-MM-YYYY")}
    `,
    value: 1
  },
  {
    label: `Since ${moment()
      .startOf("year")
      .add(-+2, "years")
      .format("DD-MM-YYYY")}
    `,
    value: 2
  },
  {
    label: `Since ${moment()
      .startOf("year")
      .add(-+5, "years")
      .format("DD-MM-YYYY")}
    `,
    value: 5
  },

  { label: "All", value: 0 }
];

const Licences = () => {
  const dispatch = useDispatch();

  const { data } = useSelector(({ licences }) => {
    return {
      data: licences.licences
    };
  });

  const [searchText, setSearchText] = React.useState("");
  const [selectedYear, setSelectedYear] = React.useState(
    localStorage.getItem("licence_selected_year") === "0" ? +localStorage.getItem("licence_selected_year") : +localStorage.getItem("licence_selected_year") || 1
  );
  const [filteredObj, setFilteredObj] = React.useState(null);
  const [startDate, setStartDate] = React.useState(
    moment()
      .startOf("year")
      .add(-+1, "years")
  );
  const [endDate, setEndDate] = React.useState(moment());
  const [visibleUserMenu, setVisibleUserMenu] = React.useState(false);

  const handleChange = _.debounce(e => {
    setSearchText(
      e.target.value
        ? e.target.value
            .toLowerCase()
            .trim()
            .split(" ")
            .map(word => `(?=.*${word})`)
            .join("")
        : ""
    );
  }, 200);

  const handleSelectDateFrom = value => {
    const debouncedSave = debounce(() => {
      setSelectedYear(value);
      localStorage.setItem("licence_selected_year", value);

      const startDate = moment()
        .startOf("year")
        .add(-+value, "years");

      const endDate = moment(startDate).add(20, "years");

      dispatch(
        getLicence({
          ...(+value && { end_date: moment(endDate).format() }),
          ...(+value && { start_date: moment(startDate).format() })
        })
      );
    }, 200);
    debouncedSave();
  };

  useEffect(() => {
    setStartDate(
      moment()
        .startOf("year")
        .add(-+selectedYear, "years")
    );
    setEndDate(moment());
  }, [selectedYear]); // eslint-disable-line

  const handleFilterData = body => {
    setFilteredObj(body);
  };

  let filteredData = data;

  if (searchText) {
    filteredData = filteredData.filter(licence => {
      return (
        (licence.name && licence.name.toLowerCase().match(new RegExp(searchText))) ||
        (licence.licence_no && licence.licence_no.toLowerCase().match(new RegExp(searchText))) ||
        (licence.mobile && licence.mobile.toLowerCase().match(new RegExp(searchText)))
      );
    });
  }

  if (filteredObj) {
    filteredData = filteredData.filter(licence => {
      return (
        (filteredObj?.application_status && licence.application_status === filteredObj.application_status) ||
        (filteredObj?.compare_date && moment(moment(licence[filteredObj.compare_date]).format("YYYY-MM-DD")).isBetween(filteredObj.start_date, filteredObj.end_date))
      );
    });
  }

  if (filteredObj) {
    filteredData = filteredData.sort((a, b) => {
      return filteredObj?.compare_date && moment(a[filteredObj.compare_date]).unix() - moment(b[filteredObj.compare_date]).unix();
    });
  }

  const handleMenuClick = e => {
    const { key } = e;

    key === "1" ? licenceReportPdf({ reportData: filteredData }) : csvdataRender();
  };

  function csvdataRender() {
    const csvData = !!filteredData.length
      ? filteredData.map((licence, i) => {
          const name = "Name";
          const licence_no = "Licence No";
          const address = "Address";
          const mobile = "Mob No.";
          const computer_test_date = "Computer Test Date";
          const drive_test_date = "Driving Test Date";
          const expire_date = "Expire Date";
          const application_status = "Application Status";
          const remark = "Remark";

          return {
            [name]: licence.name ? licence.name : "",
            [licence_no]: licence.licence_no ? licence.licence_no : "",
            [address]: licence.address ? licence.address : "",
            [mobile]: licence.mobile ? licence.mobile : "",
            [computer_test_date]: licence.computer_test_date ? moment(licence.computer_test_date).format("DD-MM-YYYY h:mm a") : "",
            [drive_test_date]: licence.drive_test_date ? moment(licence.drive_test_date).format("DD-MM-YYYY h:mm a") : "",
            [expire_date]: licence.expire_date ? moment(licence.expire_date).format("DD-MM-YYYY") : "",
            [application_status]: licence.application_status ? licence.application_status.replace("IN_PROCESS", "IN PROCESS") : "",
            [remark]: licence.remark ? licence.remark : ""
          };
        })
      : [];

    DownloadCSV({ csvData, exportFileName: `Licences Report` });
  }

  return (
    <Section className="licence-page">
      <Space wrap className="licence-table-toolbar">
        <Title className="title" level={3}>
          {`Licences ${data.length ? `(${data.length})` : ""}`}
        </Title>
        <Space wrap size={isMobile ? "middle" : "small"}>
          <Input className="search-field" size="small" allowClear placeholder="Search by Name, Licence No., Mobile..." onChange={e => handleChange(e)} />
          <Select
            className="date-selection-dropdown left-item"
            showSearch
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={value => handleSelectDateFrom(value)}
            value={selectedYear}
            size="small"
          >
            {options.map((date, i) => {
              return (
                <Select.Option key={i} value={date.value}>
                  {date.label}
                </Select.Option>
              );
            })}
          </Select>

          <Dropdown
            trigger="click"
            overlay={<LicencesFilterMenu closeMenu={() => setVisibleUserMenu(false)} startDate={startDate} endDate={endDate} filterLicenceData={handleFilterData} />}
            visible={visibleUserMenu}
            onVisibleChange={setVisibleUserMenu}
          >
            {filteredObj ? (
              <Badge dot color="blue">
                <Button size="small">Filter</Button>
              </Badge>
            ) : (
              <Button size="small">Filter</Button>
            )}
          </Dropdown>
          <PrintDropdown handleMenuSelect={handleMenuClick} className="print-dropdown" />
          <LicencesAddOrEditModal
            component={
              <Button type="primary" size="small" icon={<PlusOutlined />}>
                Add
              </Button>
            }
            data={{}}
          />
        </Space>
      </Space>
      <LicencesTable searchText={searchText} data={filteredData} />
    </Section>
  );
};

export default Licences;

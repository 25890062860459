import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Select, InputNumber, DatePicker, message, Typography, Divider, Tooltip, Space } from "antd";
import { PlusOutlined, FileAddOutlined, EditOutlined } from "@ant-design/icons";
import { times } from "lodash";
import { addPolicy, editPolicy, getPolicyType, getCompany, getAgencies, getClient, addRenewalPolicy, addPolicyRenewal } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import moment from "moment";
import PolicyTypeTag from "@components/PolicyTypes/PolicyTypeTag";
import ClientsAddOrEditModal from "@components/Clients/ClientsAddOrEditModal";
import VehicleNamesAddOrEditModal from "@components/VehicleNames/VehicleNamesAddOrEditModal";

const { Title } = Typography;

const layout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  }
};

const PoliciesForm = ({ isEdit, isRenew, handleClose, data = {} }) => {
  const dispatch = useDispatch();

  const {
    id,
    policy_type_id = "",
    company_id = "",
    agency_id = "",
    engine_no = "",
    sum_insured = 0,
    premium = 0,
    percentage = 0,
    fees = 0,
    policy_number = "",
    amount = 0,
    model = "",
    vehicle_number = "",
    vehicle_make = "",
    chassis_no = "",
    start_date = "",
    end_date = "",
    client_id = "",
    renewal_policy_id = "",
    isRenewal = false,
    policy_type_sms_format = ""
  } = data;

  const [agenciesOptionsListByCompany, setAgenciesOptionsListByCompany] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [smsTemplateFlowId, setSmsTemplateFlowId] = useState(policy_type_sms_format);
  const [form] = Form.useForm();

  const { loading, companies, policyTypes, agencies, clients, vehicleNames } = useSelector(({ companies, policy, policyType, agency, client }) => {
    return {
      loading: policy.manipulatePolicyLoading,
      companies: companies.companies,
      policyTypes: policyType.policyTypes,
      agencies: agency.agencies,
      clients: client.clients,
      vehicleNames: policy.vehicleNames
    };
  });

  useEffect(() => {
    !policyTypes.length && dispatch(getPolicyType());
  }, [policyTypes, dispatch]);

  useEffect(() => {
    !companies.length && dispatch(getCompany());
  }, [companies, dispatch]);

  useEffect(() => {
    !agencies.length && dispatch(getAgencies());
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    !clients.length && dispatch(getClient());
  }, [dispatch, clients]);

  const onFinish = formData => {
    const {
      client_id = "",
      agency_id = "",
      amount = 0,
      chassis_no = "",
      company_id = "",
      engine_no = "",
      fees = 0,
      model = "",
      percentage = 0,
      policy_number = "",
      policy_type_id = "",
      premium = 0,
      sum_insured = 0,
      vehicle_make = "",
      vehicle_number = "",
      start_date = "",
      end_date = ""
    } = form.getFieldsValue(true);

    //remove empty string and null values for backend validation
    let body = {
      client_id,
      agency_id,
      amount,
      chassis_no,
      company_id,
      engine_no,
      fees: fees || 0,
      model: model ? model + "" : "",
      commission: ((premium - premium * (100 / (100 + process.env.REACT_APP_GST_RATE))) * percentage) / 100,
      percentage,
      policy_number,
      policy_type_id,
      premium,
      sum_insured,
      vehicle_make,
      vehicle_number: vehicle_number ? vehicle_number.toUpperCase() : "",
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD")
    };

    if (isRenewal) {
      dispatch(addRenewalPolicy({ ...body, renewal_policy_id })).then(e => handleClose(e, "Policy Renewed successfully."));
    } else if (isRenew) {
      dispatch(addPolicyRenewal({ ...body, renewal_policy_id }))
        .then(e => {
          handleClose(e, "Policy Renewed successfully.");
          message.success("Policy Renewed successfully.");
        })
        .catch(err => {
          message.error("Something went wrong");
        });
    } else {
      if (isEdit) {
        dispatch(editPolicy(id, body))
          .then(() => {
            handleClose();
            message.success("Policy updated successfully.");
          })
          .catch(err => {
            message.error("Something went wrong");
          });
      } else {
        dispatch(addPolicy(body))
          .then(() => {
            handleClose();
            message.success("Policy added successfully.");
          })
          .catch(err => {
            message.error("Something went wrong");
          });
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      const selectedPolicyType = policyTypes.find(p_type => p_type.id === policy_type_id);

      if (isRenew) {
        form.setFieldsValue({ fees: selectedPolicyType.fees || 0, percentage: selectedPolicyType.percentage || 0, amount: 0 });
      } else {
        form.setFieldsValue({ fees: (fees ? fees : selectedPolicyType.fees) || 0, percentage: percentage ? percentage : selectedPolicyType.percentage, amount });
      }
    }
  }, [isEdit, isRenew]); // eslint-disable-line

  useEffect(() => {
    if (isEdit) {
      const { client_id } = form.getFieldsValue(true);
      setSelectedClientId(client_id);
    }
  }, [isEdit]); // eslint-disable-line

  useEffect(() => {
    if (isEdit) {
      const filterAgenciesOptionsListByCompany = agencies.filter(agency => agency.company_id === form.getFieldsValue(true).company_id);
      setAgenciesOptionsListByCompany(filterAgenciesOptionsListByCompany);
    }
  }, [form.getFieldsValue(true).company_id]); // eslint-disable-line

  const handleChangePremium = value => {
    const { fees = 0 } = form.getFieldsValue(true);
    form.setFieldsValue({ amount: value + fees });
  };

  const handleCallBackAddClient = client => {
    form.setFieldsValue({ client_id: client.id });
    setSelectedClientId(client.id);
  };

  const handleCallBackVehicleName = vehicle => {
    form.setFieldsValue({ vehicle_make: vehicle.name });
  };

  const handleChangeFees = value => {
    const { premium = 0 } = form.getFieldsValue(true);
    form.setFieldsValue({ amount: value + premium });
  };

  const handleChangeAmount = value => {
    const { premium = 0 } = form.getFieldsValue(true);
    form.setFieldsValue({ fees: value - premium });
  };

  const handleChangeStartDate = value => {
    form.setFieldsValue({ start_date: value, end_date: moment(value).add("days", 364) });
  };

  const handleSelectPolicyType = value => {
    const selectedPolicyType = policyTypes.find(p_type => p_type.id === value);
    const { premium = 0 } = form.getFieldsValue(true);
    if ((selectedPolicyType && isRenew) || !isEdit) {
      form.setFieldsValue({ fees: selectedPolicyType.fees || 0, percentage: selectedPolicyType.percentage, amount: (selectedPolicyType.fees || 0) + premium });
    }
    if (selectedPolicyType) {
      setSmsTemplateFlowId(selectedPolicyType.sms_template_flow_id);
    }
  };

  const handleSelectClient = value => {
    form.setFieldsValue({ client_id: value });
    setSelectedClientId(value);
  };

  const handleChangeCompany = value => {
    form.setFieldsValue({ company_id: value, agency_id: "" });

    const filterAgenciesOptionsListByCompany = agencies.filter(agency => agency.company_id === form.getFieldsValue().company_id);
    setAgenciesOptionsListByCompany(filterAgenciesOptionsListByCompany);
  };

  const selectedClient = clients.find(client => client.id === selectedClientId);

  return (
    <Form
      {...layout}
      layout="vertical"
      onFinish={onFinish}
      key={"policy-form"}
      initialValues={{
        client_id,
        company_id,
        agency_id,
        policy_type_id,
        engine_no,
        sum_insured,
        premium,
        percentage,
        fees: fees || 0,
        policy_number,
        amount,
        model,
        vehicle_number,
        vehicle_make,
        chassis_no,
        end_date: end_date ? moment(end_date) : "",
        start_date: start_date ? moment(start_date) : ""
      }}
      size="small"
      form={form}
    >
      <Row gutter={8}>
        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
          <Form.Item
            label={
              <div style={{ display: "flex" }}>
                <span>Client</span>
              </div>
            }
            name="client_id"
            rules={[
              {
                required: true,
                message: "Client is required"
              }
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              autoFocus
              allowClear
              // disabled={isRenew}
              onChange={value => handleSelectClient(value)}
            >
              {clients.map((client, i) => {
                return (
                  <Select.Option key={i} value={client.id}>
                    {`${client.name} ${client.mobile ? "(+" + client.mobile + ")" : ""}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} className="client-add-button">
          <Space>
            {selectedClient && (
              <ClientsAddOrEditModal
                component={
                  <Tooltip title="Edit" placement="bottom">
                    <Button type="primary" tabIndex="-1" shape="circle" icon={<EditOutlined />} size={"small"} />
                  </Tooltip>
                }
                isEdit={true}
                data={selectedClient}
              />
            )}
            <ClientsAddOrEditModal component={<Button tabIndex="-1" type="primary" shape="circle" icon={<PlusOutlined />} size={"small"} />} data={{}} callBack={res => handleCallBackAddClient(res)} />
          </Space>
        </Col>
      </Row>

      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Policy type"
            name="policy_type_id"
            rules={[
              {
                required: true,
                message: "Policy type is required"
              }
            ]}
          >
            <Select
              showSearch
              // disabled={isRenew}
              filterOption={(input, option) => {
                return option.children.props.text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              onChange={value => handleSelectPolicyType(value)}
              allowClear
            >
              {policyTypes.map((p_type, i) => {
                return (
                  <Select.Option key={i} value={p_type.id}>
                    {/* {p_type.name} */}
                    <PolicyTypeTag color={p_type.color} text={p_type.name} />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="policy_number"
            label="Policy number"
            rules={[
              {
                required: true,
                message: "Policy number is required"
              }
            ]}
          >
            <Input autoFocus={isRenew} />
          </Form.Item>
        </Col>
      </Row>

      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Start date"
            name="start_date"
            rules={[
              {
                required: true,
                message: "Start date required"
              }
            ]}
          >
            <DatePicker
              className="date-field"
              placeholder="Start date"
              onChange={value => handleChangeStartDate(value)}
              showToday={false}
              format="DD-MM-YYYY"
              allowClear={true}
              style={{ width: "100%" }}
              tabIndex={isRenew ? "-1" : ""}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="End date"
            name="end_date"
            rules={[
              {
                required: true,
                message: "End date required"
              }
            ]}
          >
            <DatePicker className="date-field" placeholder="End date" showToday={false} format="DD-MM-YYYY" allowClear={true} style={{ width: "100%" }} tabIndex={isRenew ? "-1" : ""} />
          </Form.Item>
        </Col>
      </Row>

      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="sum_insured"
            label="Sum insured"
            rules={[
              {
                type: "number",
                validator: (_, value) => {
                  if (value > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Sum Insured must be > 0"));
                }
              }
            ]}
          >
            <InputNumber style={{ width: "100%" }} formatter={value => `₹${value}`} parser={value => value.replace("₹", "")} min={0} defaultValue={0} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="premium"
            label="Premium"
            rules={[
              {
                type: "number",
                validator: (_, value) => {
                  if (value > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Premium must be > 0"));
                }
              }
            ]}
          >
            <InputNumber style={{ width: "100%" }} formatter={value => `₹${value}`} parser={value => value.replace("₹", "")} min={0} defaultValue={0} onChange={value => handleChangePremium(value)} />
          </Form.Item>
        </Col>
      </Row>

      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="percentage"
            label="Percentage"
            rules={[
              {
                type: "number",
                required: true,
                message: "Percentage is required"
              }
            ]}
          >
            <InputNumber style={{ width: "100%" }} formatter={value => `${value}%`} tabIndex={isRenew ? "-1" : ""} parser={value => value.replace("%", "")} min={0} max={100} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="fees"
            label="Fees"
            rules={[
              {
                type: "number",
                required: true,
                message: "Fees is required"
              }
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={value => `₹${value}`}
              tabIndex={isRenew ? "-1" : ""}
              parser={value => value.replace("₹", "")}
              min={0}
              onChange={value => handleChangeFees(value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                type: "number",
                required: true,
                message: "Amount is required"
              }
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={value => `₹${value}`}
              tabIndex={isRenew ? "-1" : ""}
              parser={value => value.replace("₹", "")}
              min={0}
              onChange={value => handleChangeAmount(value)}
            />
          </Form.Item>
        </Col>
      </Row>
      {smsTemplateFlowId === "MOTOR_POLICY_REMINDER" && (
        <>
          <Row wrap={true} gutter={16}>
            <Col span={24}>
              <Title level={5}>Vehicle details</Title>
              <Divider />
            </Col>
          </Row>
          <Row wrap={true} gutter={16}>
            <Col span={24}>
              <Form.Item
                name="vehicle_number"
                label="Vehicle number"
                rules={[
                  {
                    required: true,
                    message: "Vehicle number is required"
                  }
                ]}
              >
                <Input
                  // disabled={isRenew}
                  tabIndex={isRenew ? "-1" : ""}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
              <Form.Item
                label="Vehicle make"
                name="vehicle_make"
                rules={[
                  {
                    required: true,
                    message: "Vehicle make is required"
                  }
                ]}
              >
                <Select
                  showSearch
                  // disabled={isRenew}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  allowClear
                  tabIndex={isRenew ? "-1" : ""}
                >
                  {vehicleNames.map((vehicle, i) => {
                    return (
                      <Select.Option key={i} value={vehicle.name}>
                        {vehicle.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} className="client-add-button">
              <VehicleNamesAddOrEditModal
                component={
                  <Button
                    // disabled={isRenew}
                    tabIndex={isRenew ? "-1" : ""}
                    type="primary"
                    shape="circle"
                    icon={<FileAddOutlined />}
                    size={"small"}
                  />
                }
                data={{}}
                callBack={res => handleCallBackVehicleName(res)}
              />
            </Col>
          </Row>
          <Row wrap={true} gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Model"
                name="model"
                rules={[
                  {
                    required: true,
                    message: "Model is required"
                  }
                ]}
              >
                <Select
                  showSearch
                  // disabled={isRenew}
                  tabIndex={isRenew ? "-1" : ""}
                  filterOption={(input, option) => {
                    return ("" + option.children).includes(input);
                  }}
                  allowClear
                >
                  {times(moment().year() - 1989, number => {
                    return (
                      <Select.Option key={number} value={Number(moment().year() - number)}>
                        {moment().year() - number}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row wrap={true} gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="engine_no"
                label="Engine no."
                rules={[
                  {
                    required: true,
                    message: "Engine no. is required"
                  }
                ]}
              >
                <Input
                  // disabled={isRenew}
                  tabIndex={isRenew ? "-1" : ""}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="chassis_no"
                label="Chassis no."
                rules={[
                  {
                    required: true,
                    message: "Chassis no. is required"
                  }
                ]}
              >
                <Input
                  // disabled={isRenew}
                  tabIndex={isRenew ? "-1" : ""}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row wrap={true} gutter={16}>
        <Col span={24}>
          <Title level={5}>Company details</Title>
          <Divider />
        </Col>
      </Row>
      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Company"
            name="company_id"
            rules={[
              {
                required: true,
                message: "Company is required"
              }
            ]}
          >
            <Select
              showSearch
              onChange={value => handleChangeCompany(value)}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              allowClear
            >
              {companies.map((company, i) => {
                return (
                  <Select.Option key={i} value={company.id}>
                    {company.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Agency"
            name="agency_id"
            rules={[
              {
                required: true,
                message: "Agency is required"
              }
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              allowClear
            >
              {agenciesOptionsListByCompany.map((agency, i) => {
                return (
                  <Select.Option key={i} value={agency.id}>
                    {agency.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div className="modal-actions">
        <Button size="small" onClick={handleClose} className="cancel-button" tabIndex="-1">
          Cancel
        </Button>
        <Button size="small" type="primary" htmlType="submit" loading={loading}>
          {isRenew ? "Renew" : isEdit ? "Save" : "Add"}
        </Button>
      </div>
    </Form>
  );
};

export default PoliciesForm;

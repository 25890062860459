import { HistoryOutlined, GatewayOutlined, BookOutlined, PartitionOutlined, TeamOutlined, ForkOutlined, BlockOutlined, CarOutlined } from "@ant-design/icons";

const menus = [
  { path: "/app/renewal-policies", label: "Renewal Policies", icon: <HistoryOutlined /> },
  { path: "/app/policies", label: "Policies", icon: <BookOutlined /> },
  { path: "/app/clients", label: "Clients", icon: <TeamOutlined /> },
  { path: "/app/references", label: "References", icon: <ForkOutlined /> },
  { path: "/app/companies", label: "Companies", icon: <BlockOutlined /> },
  { path: "/app/agencies", label: "Agencies", icon: <GatewayOutlined /> },
  { path: "/app/policy-Types", label: "Policy Types", icon: <PartitionOutlined /> },
  { path: "/app/licences", label: "Licences", icon: <CarOutlined /> }
];

export default menus;

import React from "react";
import { pickBy, identity } from "lodash";
import { Form, Button, Row, Col, Select, DatePicker } from "antd";
import "./index.scss";
import moment from "moment";
import LicenceApplicationStatusTag from "../LicenceApplicationStatusTag";
import { licenceStatusList } from "@config/const";

const rangeConfig = {
  rules: [
    // {
    //   type: "array",
    //   required: !true,
    //   message: "Please select time!"
    // }
  ]
};

const layout = {
  labelCol: {
    span: 16
  },
  wrapperCol: {
    span: 24
  }
};

const LicencesFilterMenu = ({ isEdit, data = {}, closeMenu, startDate, endDate, filterLicenceData }) => {
  const [form] = Form.useForm();

  const { application_status = "", start_date = "", end_date = "" } = data;

  const onFinish = formData => {
    const { application_status = "", start_date = "", end_date = "", compare_date = "" } = form.getFieldsValue(true);

    //remove empty string and null values for backend validation
    let body = pickBy(
      {
        application_status,
        compare_date,
        start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : "",
        end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : ""
      },
      identity
    );
    filterLicenceData(body);
    closeMenu();
  };

  const handleChangeEndDate = value => {
    form.setFieldsValue({ end_date: value });
  };

  const handleChangeStartDate = value => {
    form.setFieldsValue({ start_date: value });
  };

  const handleResetFormData = () => {
    form.resetFields();
    closeMenu();
    filterLicenceData(null);
  };

  return (
    <div className="card">
      <Form
        {...layout}
        layout="vertical"
        onFinish={onFinish}
        key={"policy-filter-form"}
        initialValues={{
          start_date: start_date ? moment(start_date) : "",
          end_date: end_date ? moment(end_date) : "",
          application_status
        }}
        size="small"
        form={form}
      >
        <Row wrap={true} gutter={16}>
          <Col span={24}>
            <Form.Item label="Compare policy date" name="compare_date">
              <Select
                showSearch
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                // onChange={value => handleSelectPolicyType(value)}
                allowClear
              >
                {[
                  { name: "Computer Test Date", value: "computer_test_date" },
                  { name: "Driving Test Date", value: "drive_test_date" },
                  { name: "Expire Date", value: "expire_date" }
                ].map((row, i) => {
                  return (
                    <Select.Option key={i} value={row.value}>
                      {row.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row wrap={true} gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="start_date"
              label="Start Date"
              {...rangeConfig}
              rules={[
                {
                  required: form.getFieldsValue(true).compare_date,
                  message: "Start date is required"
                }
              ]}
            >
              <DatePicker format="DD-MM-YYYY" className="w-full" onChange={value => handleChangeStartDate(value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="end_date"
              label="End Date"
              {...rangeConfig}
              rules={[
                {
                  required: form.getFieldsValue(true).compare_date,
                  message: "End date is required"
                }
              ]}
            >
              <DatePicker format="DD-MM-YYYY" className="w-full" onChange={value => handleChangeEndDate(value)} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Status" name="application_status">
              <Select
                showSearch
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                allowClear
              >
                {licenceStatusList.map((status, i) => {
                  return (
                    <Select.Option key={status} value={status.value}>
                      <LicenceApplicationStatusTag application_status={status.value} text={status.label} />
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="filter-menu-actions">
          <div>
            <Button size="small" onClick={handleResetFormData} className="cancel-button" tabIndex="-1" type="link">
              Reset
            </Button>
          </div>
          <div>
            <Button size="small" onClick={closeMenu} className="cancel-button ml-8">
              Cancel
            </Button>
            <Button size="small" type="primary" htmlType="submit" className="ml-8">
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LicencesFilterMenu;

import React from "react";
import { Form, Input, InputNumber, Button, Row, Col, Select, message } from "antd";
import { addPolicyType, editPolicyType } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";
import { colorList, smsTemplateList } from "@config/const";

const layout = {
  labelCol: {
    span: 10
  },
  wrapperCol: {
    span: 24
  }
};

const PolicyTypesForm = ({ handleClose, isEdit, data = {} }) => {
  const dispatch = useDispatch();
  const { id, name = "", percentage = 0, fees = 0, color = "red", sms_template_flow_id = "" } = data;
  const { loading } = useSelector(({ policyType }) => {
    return {
      loading: policyType.manipulatePolicyTypeLoading
    };
  });

  const onFinish = formData => {
    const { name = "", percentage = 0, fees = 0, color = "", sms_template_flow_id = "" } = formData;
    const body = {
      name,
      percentage,
      fees,
      color,
      sms_template_flow_id
    };

    if (isEdit) {
      dispatch(editPolicyType(id, body)).then(() => {
        handleClose();
        message.success("PolicyType updated successfully.");
      });
    } else {
      dispatch(addPolicyType(body)).then(() => {
        handleClose();
        message.success("PolicyType added successfully.");
      });
    }
  };

  return (
    <Form {...layout} layout="vertical" key="policy-type-form" onFinish={onFinish} initialValues={{ name, percentage, fees, color, sms_template_flow_id }} size="small">
      <Row wrap={true} gutter={16}>
        <Col xs={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is required"
              }
            ]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item label="Color" name="color">
            <Select
              showSearch
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              allowClear
            >
              {colorList.map((color, i) => {
                return (
                  <Select.Option key={i} value={color.value}>
                    {color.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item label="Sms Template" name="sms_template_flow_id">
            <Select
              showSearch
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              allowClear
            >
              {smsTemplateList.map((sms, i) => {
                return (
                  <Select.Option key={i} value={sms.value}>
                    {sms.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="percentage"
            label="Percentage"
            rules={[
              {
                type: "number",
                required: !true,
                message: "Percentage is required"
              }
            ]}
          >
            <InputNumber style={{ width: "100%" }} formatter={value => `${value}%`} parser={value => value.replace("%", "")} min={0} max={100} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="fees"
            label="Fees"
            rules={[
              {
                type: "number",
                required: !true,
                message: "Fees is required"
              }
            ]}
          >
            <InputNumber style={{ width: "100%" }} formatter={value => `₹${value}`} parser={value => value.replace("₹", "")} min={0} />
          </Form.Item>
        </Col>
      </Row>

      <div className="modal-actions">
        <Button size="small" onClick={handleClose} className="cancel-button" tabIndex="-1">
          Cancel
        </Button>
        <Button size="small" type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default PolicyTypesForm;

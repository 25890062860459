import React from "react";
import "./index.scss";
// import CustomModal from "@components/CustomModal";
import PoliciesForm from "@components/Licences/LicencesForm";
import AddOrEditModal from "@components/AddOrEditModal";

const LicenceAddOrEditModal = ({ isEdit, component, data, isRenew }) => {
  return <AddOrEditModal component={component} formInitialData={data} isEdit={isEdit} title={isEdit ? "Edit licence" : "Add licence"} formComponent={PoliciesForm} isRenew={isRenew} />;
};

export default LicenceAddOrEditModal;

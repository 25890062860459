import { useState, useEffect } from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { fetchUser } from "../../redux/action/auth.action";

function AppLayout(props) {
  const dispatch = useDispatch();
  const [fetchLoading, setFetchLoading] = useState(true);
  const { userData, fetchUsrLoading } = useSelector(state => state.auth);

  const handleSuccess = async () => {
    setFetchLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("user-token")) {
      if (userData === null) dispatch(fetchUser(handleSuccess));
    } else {
      setFetchLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, dispatch]);

  return fetchUsrLoading || fetchLoading ? (
    <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Spin size="large" />
    </div>
  ) : (
    props.children
  );
}

export default AppLayout;

import {
  ADD_REFERENCE,
  ADD_REFERENCE_SUCCESS,
  ADD_REFERENCE_FAILURE,
  EDIT_REFERENCE,
  EDIT_REFERENCE_SUCCESS,
  EDIT_REFERENCE_FAILURE,
  REMOVE_REFERENCE,
  REMOVE_REFERENCE_SUCCESS,
  REMOVE_REFERENCE_FAILURE,
  GET_REFERENCES,
  GET_REFERENCES_SUCCESS,
  GET_REFERENCES_FAILURE
  // HENDLE_PAGINATION_REFERENCE
} from "./actionType";
import { generateQuery } from "@helpers";
import axios from "@config/axios";

//ADD reference
const createReferences = payload => {
  return {
    type: ADD_REFERENCE,
    payload: payload
  };
};
const createReferencesSuccess = payload => {
  return {
    type: ADD_REFERENCE_SUCCESS,
    payload: payload
  };
};
const createReferencesFailure = payload => {
  return {
    type: ADD_REFERENCE_FAILURE,
    payload: payload
  };
};
export const addReference = body => async dispatch => {
  dispatch(createReferences());
  return await axios
    .post(`/create-refer`, body)
    .then(res => {
      dispatch(createReferencesSuccess({ reference: res.data.data.refer }));
      return res.data.data.refer;
    })
    .catch(err => {
      dispatch(createReferencesFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//UPDATE reference
const updateReference = payload => {
  return {
    type: EDIT_REFERENCE,
    payload: payload
  };
};
const updateReferenceSuccess = payload => {
  return {
    type: EDIT_REFERENCE_SUCCESS,
    payload: payload
  };
};
const updateReferenceFailure = payload => {
  return {
    type: EDIT_REFERENCE_FAILURE,
    payload: payload
  };
};
export const editReference = (id, body) => async dispatch => {
  dispatch(updateReference());
  return await axios
    .put(`/update-refer/${id}`, body)
    .then(res => {
      dispatch(updateReferenceSuccess({ reference: res.data.data.refer }));
    })
    .catch(err => {
      dispatch(updateReferenceFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//REMOVE reference
const removeReference = payload => {
  return {
    type: REMOVE_REFERENCE,
    payload: payload
  };
};
const removeReferenceSuccess = payload => {
  return {
    type: REMOVE_REFERENCE_SUCCESS,
    payload: payload
  };
};
const removeReferenceFailure = payload => {
  return {
    type: REMOVE_REFERENCE_FAILURE,
    payload: payload
  };
};
export const deleteReference = id => async dispatch => {
  dispatch(removeReference());
  return await axios
    .delete(`/delete-refer/${id}`)
    .then(res => {
      dispatch(removeReferenceSuccess({ referenceId: id }));
    })
    .catch(err => {
      dispatch(removeReferenceFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//FETCH references
const fetchReferences = payload => {
  return {
    type: GET_REFERENCES,
    payload: payload
  };
};
const fetchReferencesSuccess = payload => {
  return {
    type: GET_REFERENCES_SUCCESS,
    payload: payload
  };
};
const fetchReferencesFailure = payload => {
  return {
    type: GET_REFERENCES_FAILURE,
    payload: payload
  };
};

// const handlePagination = payload => {
//   return {
//     type: HENDLE_PAGINATION_REFERENCE,
//     payload: payload
//   };
// };

export const getReferences =
  (where = {}) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchReferences());
    axios
      .get(`/get-all-refer?${filterdWhere}`)
      .then(res => {
        dispatch(fetchReferencesSuccess({ references: res.data.data.refers, searchValue: where }));
        // dispatch(handlePagination(res.data.data.total));
      })
      .catch(err => {
        dispatch(fetchReferencesFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

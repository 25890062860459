import {
  ADD_RENEWAL_POLICY,
  ADD_RENEWAL_POLICY_SUCCESS,
  ADD_RENEWAL_POLICY_FAILURE,
  UPDATE_RENEWAL_POLICY,
  UPDATE_RENEWAL_POLICY_SUCCESS,
  UPDATE_RENEWAL_POLICY_FAILURE,
  DELETE_RENEWAL_POLICY,
  DELETE_RENEWAL_POLICY_SUCCESS,
  DELETE_RENEWAL_POLICY_FAILURE,
  GET_RENEWAL_POLICIES,
  GET_RENEWAL_POLICIES_SUCCESS,
  GET_RENEWAL_POLICIES_FAILURE,
  HENDLE_SEARCH_RENEWAL_POLICY,
  LOCAL_UPDATE_RENEWAL_POLICY_BY_SOCKET,
  UPDATE_RENEWAL_POLICY_FROM_CLIENT
} from "./actionType";
import { generateQuery } from "@helpers";
import axios from "@config/axios";

//ADD Renewal policy
const createRenewalPolicy = payload => {
  return {
    type: ADD_RENEWAL_POLICY,
    payload: payload
  };
};
const createRenewalPolicySuccess = payload => {
  return {
    type: ADD_RENEWAL_POLICY_SUCCESS,
    payload: payload
  };
};
const createRenewalPolicyFailure = payload => {
  return {
    type: ADD_RENEWAL_POLICY_FAILURE,
    payload: payload
  };
};
export const addRenewalPolicy = body => async dispatch => {
  dispatch(createRenewalPolicy());
  return await axios
    .post(`/create-policy`, body)
    .then(res => {
      if (res.data.error) return res.data.message;
      dispatch(createRenewalPolicySuccess({ policy: res.data.data.policy, renewal_policy_id: body.renewal_policy_id }));
    })
    .catch(err => {
      dispatch(createRenewalPolicyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      throw new Error(err);
    });
};

//UPDATE Renewal policy
const updateRenewalPolicy = payload => {
  return {
    type: UPDATE_RENEWAL_POLICY,
    payload: payload
  };
};
export const updateRenewalPolicySuccess = payload => {
  return {
    type: UPDATE_RENEWAL_POLICY_SUCCESS,
    payload: payload
  };
};
const updateRenewalPolicyFailure = payload => {
  return {
    type: UPDATE_RENEWAL_POLICY_FAILURE,
    payload: payload
  };
};
export const editRenewalPolicy = (id, body) => async dispatch => {
  dispatch(updateRenewalPolicy());
  return await axios
    .put(`/update-policy/${id}`, body)
    .then(res => {
      dispatch(updateRenewalPolicySuccess({ policy: res.data.data }));
      throw new Error(res);
    })
    .catch(err => {
      dispatch(updateRenewalPolicyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//REMOVE Renewal policy
const removeRenewalPolicy = payload => {
  return {
    type: DELETE_RENEWAL_POLICY,
    payload: payload
  };
};
const removeRenewalPolicySuccess = payload => {
  return {
    type: DELETE_RENEWAL_POLICY_SUCCESS,
    payload: payload
  };
};
const removeRenewalPolicyFailure = payload => {
  return {
    type: DELETE_RENEWAL_POLICY_FAILURE,
    payload: payload
  };
};
export const deleteRenewalPolicy = id => async dispatch => {
  dispatch(removeRenewalPolicy());
  return await axios
    .delete(`/delete-policy/${id}`)
    .then(res => {
      dispatch(removeRenewalPolicySuccess({ policyId: id }));
    })
    .catch(err => {
      dispatch(removeRenewalPolicyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//FETCH Renewal policies
const fetchRenewalPolicies = payload => {
  return {
    type: GET_RENEWAL_POLICIES,
    payload: payload
  };
};
const fetchRenewalPoliciesSuccess = payload => {
  return {
    type: GET_RENEWAL_POLICIES_SUCCESS,
    payload: payload
  };
};
const fetchRenewalPoliciesFailure = payload => {
  return {
    type: GET_RENEWAL_POLICIES_FAILURE,
    payload: payload
  };
};

const handleSearchValue = payload => {
  return {
    type: HENDLE_SEARCH_RENEWAL_POLICY,
    payload: payload
  };
};

export const getRenewalPolicies =
  (where = {}, isSearch, isRedirect) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchRenewalPolicies(isSearch));
    axios
      .get(`/get-all-policy?${filterdWhere}`)
      .then(res => {
        dispatch(fetchRenewalPoliciesSuccess(res.data.data.policies));
        dispatch(handleSearchValue({ where, isRedirect }));
      })
      .catch(err => {
        dispatch(fetchRenewalPoliciesFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

export const localUpdateRenewalPolicyBySocket = payload => {
  return {
    type: LOCAL_UPDATE_RENEWAL_POLICY_BY_SOCKET,
    payload: payload
  };
};

export const updateRenewalPolicyFromClient = client => {
  return {
    type: UPDATE_RENEWAL_POLICY_FROM_CLIENT,
    payload: client
  };
};

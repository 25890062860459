import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, Select, message } from "antd";
import { addAgency, editAgency, getCompany } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";

const layout = {
  labelCol: {
    span: 10
  },
  wrapperCol: {
    span: 24
  }
};

const AgenciesForm = ({ isEdit, handleClose, data = {} }) => {
  const dispatch = useDispatch();

  const { id, name = "", company_id = "" } = data;

  const { loading, companies } = useSelector(({ agency, companies }) => {
    return {
      loading: agency.manipulateAgencyLoading,
      companies: companies.companies
    };
  });

  useEffect(() => {
    !companies.length && dispatch(getCompany());
  }, [dispatch, companies]);

  const onFinish = formData => {
    const { name = "", company_id = "" } = formData;
    const body = {
      name,
      company_id
    };

    if (isEdit) {
      dispatch(editAgency(id, body)).then(() => {
        handleClose();
        message.success("Agency updated successfully.");
      });
    } else {
      dispatch(addAgency(body)).then(() => {
        handleClose();
        message.success("Agency added successfully.");
      });
    }
  };

  return (
    <Form {...layout} layout="vertical" onFinish={onFinish} key={"agency-form"} initialValues={{ name, company_id }} size="small">
      <Row>
        <Col xs={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is required"
              }
            ]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Form.Item
            label="Company"
            name="company_id"
            rules={[
              {
                required: true,
                message: "Company is required"
              }
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              allowClear
            >
              {companies.map((company, i) => {
                return (
                  <Select.Option key={i} value={company.id}>
                    {company.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div className="modal-actions">
        <Button size="small" onClick={handleClose} className="cancel-button" tabIndex="-1">
          Cancel
        </Button>
        <Button size="small" type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default AgenciesForm;

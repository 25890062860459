import React, { useEffect, useMemo } from "react";
import Section from "@components/Section";
import { Space, Button, Input, Select, Dropdown, Typography, Badge } from "antd";
import "./index.scss";
import PoliciesTable from "@components/Policies/PoliciesTable";
import PoliciesAddOrEditModal from "@components/Policies/PoliciesAddOrEditModal";
import { PlusOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import moment from "moment";
import { getPolicies } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";
import DownloadCSV from "@components/GenerateCSV";
import { reportPdf } from "@components/GeneratePDF/ReportPdf";
import PrintDropdown from "@components/PrintDropdown";
// import { useDebouncedEffect } from "@components/DebouncedEffect";
import _ from "lodash";
import PoliciesFilterMenu from "@components/Policies/PoliciesFilterMenu";
import numeral from "numeral";
import { isMobile } from "react-device-detect";

const { Title } = Typography;

const options = [
  {
    label: `Since ${moment()
      .startOf("year")
      .add(-+1, "years")
      .format("DD-MM-YYYY")}
    `,
    value: 1
  },
  {
    label: `Since ${moment()
      .startOf("year")
      .add(-+2, "years")
      .format("DD-MM-YYYY")}
    `,
    value: 2
  },
  {
    label: `Since ${moment()
      .startOf("year")
      .add(-+5, "years")
      .format("DD-MM-YYYY")}
    `,
    value: 5
  },
  { label: "All", value: 0 }
];

const Policies = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = React.useState("");
  const [selectedYear, setSelectedYear] = React.useState(
    localStorage.getItem("policies_selected_year") === "0" ? +localStorage.getItem("policies_selected_year") : +localStorage.getItem("policies_selected_year") || 1
  );
  const [filteredObj, setFilteredObj] = React.useState(null);

  const [startDate, setStartDate] = React.useState(
    moment()
      .startOf("year")
      .add(-+1, "years")
  );
  const [endDate, setEndDate] = React.useState(moment());
  const [visibleUserMenu, setVisibleUserMenu] = React.useState(false);

  const { data, loading } = useSelector(({ policy }) => {
    return {
      data: policy.policies,
      loading: policy.fetchingPoliciesLoading,
      removeLoading: policy.removePolicyLoading
    };
  });

  let filteredData = data;

  if (searchText) {
    filteredData = filteredData.filter(client => {
      return (
        (client.policy_number && client.policy_number.toLowerCase().match(new RegExp(searchText))) ||
        (client.vehicle_number && client.vehicle_number.toLowerCase().match(new RegExp(searchText))) ||
        (client.client_name && client.client_name.toLowerCase().match(new RegExp(searchText))) ||
        (client.client_mobile && client.client_mobile.toLowerCase().match(new RegExp(searchText)))
      );
    });
  }

  if (filteredObj && filteredObj.agency_id) {
    filteredData = filteredData.filter(policy => {
      return filteredObj?.agency_id && policy.agency_id === filteredObj.agency_id;
    });
  }

  if (filteredObj && filteredObj.refer_id) {
    filteredData = filteredData.filter(policy => {
      return filteredObj?.refer_id && policy.refer_id === filteredObj.refer_id;
    });
  }

  if (filteredObj && filteredObj.company_id) {
    filteredData = filteredData.filter(policy => {
      return filteredObj?.company_id && policy.company_id === filteredObj.company_id;
    });
  }

  if (filteredObj && filteredObj.policy_type_id) {
    filteredData = filteredData.filter(policy => {
      return filteredObj?.policy_type_id && policy.policy_type_id === filteredObj.policy_type_id;
    });
  }

  if (filteredObj && filteredObj.compare_date) {
    filteredData = filteredData.filter(policy => {
      return (
        filteredObj?.compare_date &&
        moment(moment(policy[filteredObj.compare_date]).format("YYYY-MM-DD")).isBetween(moment(filteredObj.start_date).format("YYYY-MM-DD"), moment(filteredObj.end_date).format("YYYY-MM-DD"))
      );
    });
  }

  useEffect(() => {
    setStartDate(
      moment()
        .startOf("year")
        .add(-+selectedYear, "years")
    );
    setEndDate(moment());
  }, [selectedYear]); // eslint-disable-line

  const handleChangeSearchText = _.debounce(e => {
    setSearchText(
      e.target.value
        ? e.target.value
            .toLowerCase()
            .trim()
            .split(" ")
            .map(word => `(?=.*${word})`)
            .join("")
        : ""
    );
  }, 200);

  const handleSelectDateFrom = value => {
    const debouncedSave = debounce(() => {
      setSelectedYear(value);
      localStorage.setItem("policies_selected_year", value);
      const startDate = moment()
        .startOf("year")
        .add(-+value, "years");

      const endDate = moment(startDate).add(20, "years");

      dispatch(
        getPolicies({
          sort_field: "start_date:desc",
          ...(+value && { end_date: moment(endDate).format("YYYY-MM-DD") }),
          ...(+value && { start_date: moment(startDate).format("YYYY-MM-DD") })
        })
      );
    }, 200);
    debouncedSave();
  };

  const totalRecord = filteredData.length;

  const { totalPremium, totalFees, totalCommission } = useMemo(() => {
    let totalPremium = 0;
    let totalFees = 0;
    let totalCommission = 0;

    filteredData.forEach(currentValue => {
      totalPremium += currentValue.premium;
      totalFees += currentValue.fee;
      totalCommission += currentValue.commission;
    });

    return {
      totalPremium,
      totalFees,
      totalCommission
    };
  }, [filteredData]);

  const printFileDetails = {
    ...(selectedYear && { start: moment(startDate).format("DD-MM-YYYY") }),
    ...(selectedYear && { end: moment(endDate).format("DD-MM-YYYY") }),
    totalRecord,
    totalPremium,
    totalCommission,
    totalFees
  };

  const handleMenuClick = e => {
    const { key } = e;
    key === "1" ? reportPdf({ report: filteredData, printFileDetails }) : csvdataRender();
  };

  function csvdataRender() {
    let totalPremium = 0;
    let totalFees = 0;
    let totalCommission = 0;

    // CSV column labels
    const sr_no = "No.";
    const start_date = "Start Date";
    const end_date = "End Date";
    const premium = "Premium";
    const client_name = "Name";
    const client_mobile = "Mob No.";
    const policy_type_name = "Policy Type";
    const percentage = "Perc.";
    const commission = "Commission";
    const fee = "Fee";
    const amount = "Amount";

    const csvData = !!filteredData.length
      ? filteredData.map((policy, i) => {
          totalPremium += policy.premium || 0;
          totalFees += policy.fee || 0;
          totalCommission += policy.commission || 0;

          return {
            [sr_no]: i + 1,
            [policy_type_name]: policy.policy_type_name ? policy.policy_type_name : "",
            [start_date]: policy.start_date ? moment(policy.start_date).format("DD-MM-YYYY") : "",
            [end_date]: policy.end_date ? moment(policy.end_date).format("DD-MM-YYYY") : "",
            [client_name]: policy.client_name ? policy.client_name : "",
            [client_mobile]: policy.client_mobile ? policy.client_mobile : "",
            [premium]: policy.premium || 0,
            [percentage]: policy.percentage || 0,
            [commission]: policy.commission || 0,
            [fee]: policy.fee || 0,
            [amount]: policy.amount || 0
          };
        })
      : [];

    csvData.push({
      [sr_no]: "",
      [policy_type_name]: "",
      [start_date]: "",
      [end_date]: "",
      [client_name]: "",
      [client_mobile]: "",
      [premium]: totalPremium,
      [percentage]: "",
      [commission]: totalCommission,
      [fee]: totalFees,
      [amount]: totalPremium + totalFees
    });

    DownloadCSV({ csvData, exportFileName: `Commission Report (${moment(startDate).format("DD-MM-YYYY")} - ${moment(endDate).format("DD-MM-YYYY")})` });
  }
  return (
    <Section className="policies-page">
      <Space wrap className="policies-table-toolbar">
        <Title className="title" level={3}>
          Policies
        </Title>

        <Space wrap className="toolbar-right-item" size={isMobile ? "middle" : "small"}>
          <Input className="search-field" allowClear size="small" placeholder="Search by Policy No., Veh. No., Client name..." onChange={e => handleChangeSearchText(e)} />

          <Select
            className="date-selection-dropdown left-item"
            showSearch
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={value => handleSelectDateFrom(value)}
            value={selectedYear}
            size="small"
          >
            {options.map((date, i) => {
              return (
                <Select.Option key={i} value={date.value}>
                  {date.label}
                </Select.Option>
              );
            })}
          </Select>

          <Dropdown
            trigger="click"
            overlay={useMemo(() => {
              const handleFilterData = body => {
                setFilteredObj(body);
              };

              return <PoliciesFilterMenu closeMenu={() => setVisibleUserMenu(false)} startDate={startDate} endDate={endDate} filterPolicyDate={handleFilterData} />;
            }, [startDate, endDate])}
            visible={visibleUserMenu}
            onVisibleChange={setVisibleUserMenu}
          >
            {filteredObj ? (
              <Badge dot color="blue">
                <Button size="small">Filter</Button>
              </Badge>
            ) : (
              <Button size="small">Filter</Button>
            )}
          </Dropdown>

          <PrintDropdown handleMenuSelect={handleMenuClick} className="print-dropdown" />
          <PoliciesAddOrEditModal
            component={
              <Button type="primary" size="small" icon={<PlusOutlined />}>
                Add
              </Button>
            }
            data={{}}
          />
        </Space>
      </Space>
      {useMemo(() => {
        return <PoliciesTable data={filteredData} />;
      }, [filteredData])}

      {loading ? (
        ""
      ) : (
        <Space wrap className="policies-stats">
          <div>
            <strong>Total Policies : </strong>
            {numeral(printFileDetails.totalRecord).format("0,0")}
          </div>

          <div>
            <strong>Total Premium : </strong>
            {`₹ ${printFileDetails.totalPremium ? numeral(printFileDetails.totalPremium).format("0,0") : "0"}`}
          </div>

          <div>
            <strong>Total Commission : </strong>
            {`₹ ${printFileDetails.totalCommission ? numeral(printFileDetails.totalCommission).format("0,0") : "0"}`}
          </div>
        </Space>
      )}
    </Section>
  );
};

export default Policies;

import {
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_USER_LOGOUT,
  AUTH_USER_LOGOUT_SUCCESS,
  AUTH_USER_LOGOUT_FAILURE,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE
} from "../action/actionType";

let initialState = {
  userData: null,

  loginLoading: false,
  loginError: null,

  fetchUserError: null,
  fetchUsrLoading: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        loginLoading: true,
        loginError: null
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        userData: action.payload
      };
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginError: action.payload
      };

    case FETCH_USER:
      return {
        ...state,
        fetchUsrLoading: true,
        loginError: null,
        fetchUserError: null
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        fetchUsrLoading: false,
        userData: action.payload
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        fetchUsrLoading: false,
        loginError: action.payload
      };

    case AUTH_USER_LOGOUT:
      return {
        ...state,
        logoutLoading: true
      };
    case AUTH_USER_LOGOUT_SUCCESS:
      return {
        ...state,
        userData: {}
      };
    case AUTH_USER_LOGOUT_FAILURE:
      return {
        ...state,
        logoutLoading: false,
        logoutLoadingError: action.payload.error
      };

    default:
      return state;
  }
};

export default authReducer;

/* eslint-disable no-unused-vars */
import { map, remove } from "lodash";
import {
  ADD_LICENCE,
  ADD_LICENCE_SUCCESS,
  ADD_LICENCE_FAILURE,
  EDIT_LICENCE,
  EDIT_LICENCE_SUCCESS,
  EDIT_LICENCE_FAILURE,
  REMOVE_LICENCE,
  REMOVE_LICENCE_SUCCESS,
  REMOVE_LICENCE_FAILURE,
  GET_LICENCES,
  GET_LICENCES_SUCCESS,
  GET_LICENCES_FAILURE,
  LOCAL_ADD_LICENCE_BY_SOCKET,
  LOCAL_UPDATE_LICENCE_BY_SOCKET,
  LOCAL_DELETE_LICENCE_BY_SOCKET,
  ADD_LICENCE_RENEWAL,
  ADD_LICENCE_SUCCESS_RENEWAL,
  ADD_LICENCE_FAILURE_RENEWAL
} from "../action/actionType";

let initialState = {
  //LICENCE
  manipulateLicenceLoading: false,
  manipulateLicenceError: null,
  removeLicenceLoading: false,
  removeLicenceError: null,

  licences: [],
  fetchingLicencesLoading: false,
  fetchingLicencesError: null,

  error: null,

  searchValue: null
};

const licencesReducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD_LICENCE
    case ADD_LICENCE:
      return {
        ...state,
        manipulateLicenceLoading: true
      };
    case ADD_LICENCE_SUCCESS:
      return {
        ...state,
        manipulateLicenceLoading: false,
        licences: [action.payload.licence, ...state.licences]
      };
    case LOCAL_ADD_LICENCE_BY_SOCKET:
      return {
        ...state,
        licences: [action.payload, ...state.licences]
      };
    case ADD_LICENCE_FAILURE:
      return {
        ...state,
        manipulateLicenceLoading: false,
        cyError: action.payload.error
      };
    case ADD_LICENCE_RENEWAL:
      return {
        ...state,
        manipulateLicenceLoading: true
      };
    case ADD_LICENCE_SUCCESS_RENEWAL:
      const updatedLicence = state.licences.map(licence => {
        if (licence.id === action.payload.renewal_id) {
          return Object.assign(licence, { status: 1 });
        } else {
          return licence;
        }
      });
      return {
        ...state,
        manipulateLicenceLoading: false,
        licences: [action.payload.licence, ...updatedLicence]
      };
    case ADD_LICENCE_FAILURE_RENEWAL:
      return {
        ...state,
        cyError: action.payload.error
      };

    //EDIT_LICENCE
    case EDIT_LICENCE:
      return {
        ...state,
        manipulateLicenceLoading: true
      };
    case EDIT_LICENCE_SUCCESS:
      let licences = map(state.licences, licence => {
        if (licence.id === action.payload.licence.id) {
          return action.payload.licence;
        } else {
          return licence;
        }
      });
      return {
        ...state,
        manipulateLicenceLoading: false,
        licences: licences
      };

    case LOCAL_UPDATE_LICENCE_BY_SOCKET:
      let localLicences = map(state.licences, licence => {
        if (licence.id === action.payload.id) {
          return action.payload;
        } else {
          return licence;
        }
      });
      return {
        ...state,
        licences: localLicences
      };

    case EDIT_LICENCE_FAILURE:
      return {
        ...state,
        manipulateLicenceLoading: false,
        manipulateLicenceError: action.payload.error
      };

    //REMOVE_COMPANY
    case REMOVE_LICENCE:
      return {
        ...state,
        removeLicenceLoading: true
      };
    case REMOVE_LICENCE_SUCCESS:
      remove(state.licences, { id: action.payload.licenceId });
      return {
        ...state,
        removeLicenceLoading: false
      };

    case LOCAL_DELETE_LICENCE_BY_SOCKET:
      remove(state.licences, { id: action.payload.id });
      return {
        ...state
      };

    case REMOVE_LICENCE_FAILURE:
      return {
        ...state,
        removeLicenceLoading: false,
        removeLicenceError: action.payload.error
      };

    //GET_COMPANIES
    case GET_LICENCES:
      return {
        ...state,
        fetchingLicencesLoading: true
      };
    case GET_LICENCES_SUCCESS:
      return {
        ...state,
        licences: action.payload,
        fetchingLicencesLoading: false
      };
    case GET_LICENCES_FAILURE:
      return {
        ...state,
        fetchingLicencesLoading: false,
        fetchingCompaniesError: action.payload.error
      };

    // case HENDLE_PAGINATION_COMPANY:
    //   return {
    //     ...state,
    //     totalRecord: action.payload
    //   };
    // case HANDLE_SEARCH_COMPANY:
    //   return {
    //     ...state,
    //     searchValue: action.payload
    //   };

    default:
      return state;
  }
};

export default licencesReducer;

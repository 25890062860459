import {
  ADD_LICENCE,
  ADD_LICENCE_SUCCESS,
  ADD_LICENCE_FAILURE,
  EDIT_LICENCE,
  EDIT_LICENCE_SUCCESS,
  EDIT_LICENCE_FAILURE,
  REMOVE_LICENCE,
  REMOVE_LICENCE_SUCCESS,
  REMOVE_LICENCE_FAILURE,
  GET_LICENCES,
  GET_LICENCES_SUCCESS,
  GET_LICENCES_FAILURE,
  LOCAL_ADD_LICENCE_BY_SOCKET,
  LOCAL_UPDATE_LICENCE_BY_SOCKET,
  LOCAL_DELETE_LICENCE_BY_SOCKET,
  ADD_LICENCE_RENEWAL,
  ADD_LICENCE_SUCCESS_RENEWAL,
  ADD_LICENCE_FAILURE_RENEWAL
} from "./actionType";
import axios from "@config/axios";
import { generateQuery } from "@helpers";

//ADD LICENCE
const createLicence = payload => {
  return {
    type: ADD_LICENCE,
    payload: payload
  };
};
const createLicenceSuccess = payload => {
  return {
    type: ADD_LICENCE_SUCCESS,
    payload: payload
  };
};
const createLicenceFailure = payload => {
  return {
    type: ADD_LICENCE_FAILURE,
    payload: payload
  };
};
export const addLicence = body => async dispatch => {
  dispatch(createLicence());
  return await axios
    .post(`/create-licence`, body)
    .then(res => {
      dispatch(createLicenceSuccess({ licence: res.data.data.licence }));
    })
    .catch(err => {
      dispatch(createLicenceFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      throw new Error(err.response ? err.response.data.message : "Something went wrong!");
    });
};

//UPDATE LICENCE
const updateLicence = payload => {
  return {
    type: EDIT_LICENCE,
    payload: payload
  };
};
const updateLicenceSuccess = payload => {
  return {
    type: EDIT_LICENCE_SUCCESS,
    payload: payload
  };
};
const updateLicenceFailure = payload => {
  return {
    type: EDIT_LICENCE_FAILURE,
    payload: payload
  };
};
export const editLicence = (id, body) => async dispatch => {
  dispatch(updateLicence());
  return await axios
    .put(`/update-licence/${id}`, body)
    .then(res => {
      dispatch(updateLicenceSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(updateLicenceFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//REMOVE LICENCE
const removeLicence = payload => {
  return {
    type: REMOVE_LICENCE,
    payload: payload
  };
};
const removeLicenceSuccess = payload => {
  return {
    type: REMOVE_LICENCE_SUCCESS,
    payload: payload
  };
};
const removeLicenceFailure = payload => {
  return {
    type: REMOVE_LICENCE_FAILURE,
    payload: payload
  };
};
export const deleteLicence = id => async dispatch => {
  dispatch(removeLicence());
  return await axios
    .delete(`/delete-licence/${id}`)
    .then(res => {
      dispatch(removeLicenceSuccess({ licenceId: id }));
    })
    .catch(err => {
      dispatch(removeLicenceFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//FETCH COMPANY
const fetchLicences = payload => {
  return {
    type: GET_LICENCES,
    payload: payload
  };
};
const fetchLicencesSuccess = payload => {
  return {
    type: GET_LICENCES_SUCCESS,
    payload: payload
  };
};
const fetchLicencesFailure = payload => {
  return {
    type: GET_LICENCES_FAILURE,
    payload: payload
  };
};

export const getLicence =
  (where = {}) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchLicences());
    axios
      .get(`/get-all-licence?${filterdWhere}`)
      .then(res => {
        dispatch(fetchLicencesSuccess(res.data.data.licences));
      })
      .catch(err => {
        dispatch(fetchLicencesFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

export const localAddLicenceBySocket = payload => {
  return {
    type: LOCAL_ADD_LICENCE_BY_SOCKET,
    payload: payload
  };
};

export const localUpdateLicenceBySocket = payload => {
  return {
    type: LOCAL_UPDATE_LICENCE_BY_SOCKET,
    payload: payload
  };
};

export const localDeleteLicenceBySocket = payload => {
  return {
    type: LOCAL_DELETE_LICENCE_BY_SOCKET,
    payload: payload
  };
};

const createRenewalLicence = payload => {
  return {
    type: ADD_LICENCE_RENEWAL,
    payload: payload
  };
};
const createRenewalLicenceSuccess = payload => {
  return {
    type: ADD_LICENCE_SUCCESS_RENEWAL,
    payload: payload
  };
};
const createRenewalLicenceFailure = payload => {
  return {
    type: ADD_LICENCE_FAILURE_RENEWAL,
    payload: payload
  };
};
export const renewalLicence =
  ({ body, renewal_id }) =>
  async dispatch => {
    dispatch(createRenewalLicence());
    return await axios
      .post(`/create-licence`, { ...body, renewal_licence_id: renewal_id })
      .then(res => {
        dispatch(createRenewalLicenceSuccess({ licence: res.data.data.licence, renewal_id }));
      })
      .catch(err => {
        dispatch(createRenewalLicenceFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
        throw new Error(err.response ? err.response.data.message : "Something went wrong!");
      });
  };

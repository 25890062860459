import React, { useState } from "react";
import { Table, Tooltip, Card, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { deleteCompany } from "@redux/action";
// import { isEmpty } from "@helpers";
import Confirmation from "@components/Confirmation";
import CompanyAddOrEditModal from "@components/Companies/CompaniesAddOrEditModal";
import UsernameAvatar from "@components/UsernameAvatar";
import { isMobile } from "react-device-detect";

const CompaniesTable = () => {
  // const dispatch = useDispatch();

  const {
    data,
    loading,
    removeLoading,
    user: { is_master_access }
  } = useSelector(({ companies, auth }) => {
    return {
      data: companies.companies,
      loading: companies.fetchingCompaniesLoading,
      removeLoading: companies.removeCompanyLoading,
      user: auth.userData
    };
  });

  // useEffect(() => {
  //   if (isEmpty(data)) dispatch(getCompany());
  // }, [dispatch, data]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      render(text, record) {
        return {
          children: (
            <Card style={{ padding: "10px", fontSize: "12px", width: "100%", border: "transparent" }}>
              <Space direction="vertical">
                <div style={{ fontWeight: "700" }}>{record.name}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Space className="action-buttons" size="middle">
                    <CompanyAddOrEditModal component={<EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />} isEdit={true} data={record} />
                    {is_master_access && (
                      <Confirmation
                        title="Are you sure to delete this?"
                        dispatchActionMethod={deleteCompany}
                        loading={removeLoading}
                        data={record}
                        component={<DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />}
                      />
                    )}
                  </Space>
                </div>
              </Space>
            </Card>
          )
        };
      },
      responsive: ["xs"]
    },

    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      filteredValue: filteredInfo.name || null,
      // sorter: (a, b) => a.name.length - b.name.length,

      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "updated_by",
      key: "updated_by",
      align: "center",
      width: 40,
      render(text) {
        return {
          children: <UsernameAvatar username={text} />
        };
      },
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      ellipsis: true,
      fixed: isMobile ? "unset" : "right",
      width: 80,
      render(text, record) {
        return {
          children: record ? (
            <Space className="action-buttons">
              <CompanyAddOrEditModal
                component={
                  <Tooltip title="Edit" placement="bottom">
                    <EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />
                  </Tooltip>
                }
                isEdit={true}
                data={record}
              />
              {is_master_access && (
                <Confirmation
                  title="Are you sure to delete this?"
                  dispatchActionMethod={deleteCompany}
                  loading={removeLoading}
                  data={record}
                  component={
                    <Tooltip title="Delete" placement="bottom">
                      <DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />{" "}
                    </Tooltip>
                  }
                />
              )}
            </Space>
          ) : (
            ""
          )
        };
      },
      responsive: ["sm"]
    }
  ];

  return <Table bordered size="small" columns={columns} scroll={isMobile ? {} : { x: 1160 }} dataSource={data} onChange={handleChangeTable} loading={loading} />;
};

export default CompaniesTable;

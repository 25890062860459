/* eslint-disable no-unused-vars */
import { map, remove } from "lodash";
import {
  ADD_REFERENCE,
  ADD_REFERENCE_SUCCESS,
  ADD_REFERENCE_FAILURE,
  EDIT_REFERENCE,
  EDIT_REFERENCE_SUCCESS,
  EDIT_REFERENCE_FAILURE,
  REMOVE_REFERENCE,
  REMOVE_REFERENCE_SUCCESS,
  REMOVE_REFERENCE_FAILURE,
  GET_REFERENCES,
  GET_REFERENCES_SUCCESS,
  GET_REFERENCES_FAILURE,
  HENDLE_PAGINATION_REFERENCE
} from "../action/actionType";

let initialState = {
  //Reference
  manipulateReferenceLoading: false,
  manipulateReferenceError: null,
  removeReferenceLoading: false,
  removeReferenceError: null,

  references: [],
  fetchingReferencesLoading: false,
  fetchingReferencesError: null,
  searchValue: null,
  totalRecord: 0,
  error: null
};

const referencesReducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD Reference
    case ADD_REFERENCE:
      return {
        ...state,
        manipulateReferenceLoading: true
      };
    case ADD_REFERENCE_SUCCESS:
      return {
        ...state,
        manipulateReferenceLoading: false,
        references: [action.payload.reference, ...state.references]
      };
    case ADD_REFERENCE_FAILURE:
      return {
        ...state,
        manipulateReferenceLoading: false,
        manipulateReferenceError: action.payload.error
      };

    //EDIT reference
    case EDIT_REFERENCE:
      return {
        ...state,
        manipulateReferenceLoading: true
      };
    case EDIT_REFERENCE_SUCCESS:
      let references = map(state.references, reference => {
        if (reference.id === action.payload.reference.id) {
          return action.payload.reference;
        } else {
          return reference;
        }
      });
      return {
        ...state,
        manipulateReferenceLoading: false,
        references: references
      };

    case EDIT_REFERENCE_FAILURE:
      return {
        ...state,
        manipulateReferenceLoading: false,
        manipulateReferenceError: action.payload.error
      };

    //REMOVE REFERENCE
    case REMOVE_REFERENCE:
      return {
        ...state,
        removeReferenceLoading: true
      };
    case REMOVE_REFERENCE_SUCCESS:
      remove(state.references, { id: action.payload.referenceId });
      return {
        ...state,
        removeReferenceLoading: false
      };

    case REMOVE_REFERENCE_FAILURE:
      return {
        ...state,
        removeReferenceLoading: false,
        removeReferenceError: action.payload.error
      };

    //GET references
    case GET_REFERENCES:
      return {
        ...state,
        fetchingReferencesLoading: true
      };
    case GET_REFERENCES_SUCCESS:
      return {
        ...state,
        references: action.payload.references,
        searchValue: action.payload.searchValue,
        fetchingReferencesLoading: false
      };
    case GET_REFERENCES_FAILURE:
      return {
        ...state,
        fetchingReferencesLoading: true,
        fetchingReferencesError: action.payload.error
      };

    case HENDLE_PAGINATION_REFERENCE:
      return {
        ...state,
        totalRecord: action.payload
      };

    default:
      return state;
  }
};

export default referencesReducer;

import React, { useState } from "react";
import { Table, Tooltip, Card, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { deletePolicyType } from "@redux/action";
// import { isEmpty } from "@helpers";
import Confirmation from "@components/Confirmation";
import PolicyTypeAddOrEditModal from "@components/PolicyTypes/PolicyTypesAddOrEditModal";
import PolicyTypeTag from "@components/PolicyTypes/PolicyTypeTag";
import UsernameAvatar from "@components/UsernameAvatar";
import { isMobile } from "react-device-detect";
import "./index.scss";

const PolicyTypesTable = () => {
  // const dispatch = useDispatch();

  const {
    data,
    loading,
    removeLoading,
    user: { is_master_access }
  } = useSelector(({ policyType, auth }) => {
    return {
      data: policyType.policyTypes,
      loading: policyType.fetchingPolicyTypesLoading,
      removeLoading: policyType.removePolicyTypeLoading,
      user: auth.userData
    };
  });

  // useEffect(() => {
  //   if (isEmpty(data)) dispatch(getPolicyType());
  // }, [dispatch, data]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "first",
      render(text, record) {
        return {
          children: (
            <Card style={{ padding: "10px", fontSize: "12px", width: "100%", border: "transparent" }}>
              <Space direction="vertical" width="100%">
                <PolicyTypeTag color={record.color} text={record.name} />
                <Space>
                  <div>{`${record.percentage ? record.percentage : "0"}%`}</div>
                  <div>{`₹ ${record.feed ? record.feed : " 0"}`}</div>
                </Space>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Space size="middle" className="action-buttons">
                    <PolicyTypeAddOrEditModal component={<EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />} isEdit={true} data={record} />
                    {is_master_access && (
                      <Confirmation
                        title="Are you sure to delete this?"
                        dispatchActionMethod={deletePolicyType}
                        loading={removeLoading}
                        data={record}
                        component={<DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />}
                      />
                    )}
                  </Space>
                </div>
              </Space>
            </Card>
          )
        };
      },
      responsive: ["xs"]
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: filteredInfo.name || null,

      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      render(text, record) {
        return {
          children: (
            <div>
              <PolicyTypeTag color={record.color} text={text} />
            </div>
          )
        };
      },
      responsive: ["sm"]
    },

    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      sorter: (a, b) => a.percentage - b.percentage,
      sortOrder: sortedInfo.columnKey === "percentage" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      render(text, record) {
        return {
          children: <div>{`${text ? text : "0"}%`}</div>
        };
      },
      align: "right",
      responsive: ["sm"]
    },
    {
      title: "Fees",
      dataIndex: "fees",
      key: "fees",
      sorter: (a, b) => a.fees - b.fees,
      sortOrder: sortedInfo.columnKey === "fees" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      render(text, record) {
        return {
          children: <div>{`₹ ${text ? text : " 0"}`}</div>
        };
      },
      align: "right",
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "updated_by",
      key: "updated_by",
      align: "center",
      width: 40,
      render(text) {
        return {
          children: <UsernameAvatar username={text} />
        };
      },
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      fixed: isMobile ? "unset" : "right",
      width: 80,
      ellipsis: true,
      render(text, record) {
        return {
          children: record ? (
            <Space className="action-buttons">
              <PolicyTypeAddOrEditModal
                component={
                  <Tooltip title="Edit" placement="bottom">
                    <EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />
                  </Tooltip>
                }
                isEdit={true}
                data={record}
              />
              {is_master_access && (
                <Confirmation
                  title="Are you sure to delete this?"
                  dispatchActionMethod={deletePolicyType}
                  loading={removeLoading}
                  data={record}
                  component={
                    <Tooltip title="Delete" placement="bottom">
                      <DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />{" "}
                    </Tooltip>
                  }
                />
              )}
            </Space>
          ) : (
            ""
          )
        };
      },
      responsive: ["sm"]
    }
  ];

  return <Table bordered size="small" columns={columns} scroll={isMobile ? {} : { x: 1160 }} dataSource={data} onChange={handleChangeTable} loading={loading} />;
};

export default PolicyTypesTable;

import {
  ADD_AGENCY,
  ADD_AGENCY_SUCCESS,
  ADD_AGENCY_FAILURE,
  EDIT_AGENCY,
  EDIT_AGENCY_SUCCESS,
  EDIT_AGENCY_FAILURE,
  REMOVE_AGENCY,
  REMOVE_AGENCY_SUCCESS,
  REMOVE_AGENCY_FAILURE,
  GET_AGENCIES,
  GET_AGENCIES_SUCCESS,
  GET_AGENCIES_FAILURE,
  GET_AGENCIES_BY_COMPANY_ID,
  GET_AGENCIES_BY_COMPANY_ID_SUCCESS,
  GET_AGENCIES_BY_COMPANY_ID_FAILURE,
  HANDLE_SEARCH_AGENCY
} from "./actionType";
import { generateQuery } from "@helpers";
import axios from "@config/axios";

//ADD AGENCY
const createAgency = payload => {
  return {
    type: ADD_AGENCY,
    payload: payload
  };
};
const createAgencySuccess = payload => {
  return {
    type: ADD_AGENCY_SUCCESS,
    payload: payload
  };
};
const createAgencyFailure = payload => {
  return {
    type: ADD_AGENCY_FAILURE,
    payload: payload
  };
};
export const addAgency = body => async dispatch => {
  dispatch(createAgency());
  return await axios
    .post(`/create-agency`, body)
    .then(res => {
      dispatch(createAgencySuccess({ agency: res.data.data.agency }));
    })
    .catch(err => {
      dispatch(createAgencyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//UPDATE AGENCY
const updateAgency = payload => {
  return {
    type: EDIT_AGENCY,
    payload: payload
  };
};
const updateAgencySuccess = payload => {
  return {
    type: EDIT_AGENCY_SUCCESS,
    payload: payload
  };
};
const updateAgencyFailure = payload => {
  return {
    type: EDIT_AGENCY_FAILURE,
    payload: payload
  };
};
export const editAgency = (id, body) => async dispatch => {
  dispatch(updateAgency());
  return await axios
    .put(`/update-agency/${id}`, body)
    .then(res => {
      dispatch(updateAgencySuccess({ agency: res.data.data.agency }));
    })
    .catch(err => {
      dispatch(updateAgencyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//REMOVE AGENCY
const removeAgency = payload => {
  return {
    type: REMOVE_AGENCY,
    payload: payload
  };
};
const removeAgencySuccess = payload => {
  return {
    type: REMOVE_AGENCY_SUCCESS,
    payload: payload
  };
};
const removeAgencyFailure = payload => {
  return {
    type: REMOVE_AGENCY_FAILURE,
    payload: payload
  };
};
export const deleteAgency = id => async dispatch => {
  dispatch(removeAgency());
  return await axios
    .delete(`/delete-agency/${id}`)
    .then(res => {
      dispatch(removeAgencySuccess({ agencyId: id }));
    })
    .catch(err => {
      dispatch(removeAgencyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//FETCH AGENCIES
const fetchAgencies = payload => {
  return {
    type: GET_AGENCIES,
    payload: payload
  };
};
const fetchAgenciesSuccess = payload => {
  return {
    type: GET_AGENCIES_SUCCESS,
    payload: payload
  };
};
const fetchAgenciesFailure = payload => {
  return {
    type: GET_AGENCIES_FAILURE,
    payload: payload
  };
};

const handleSearchValue = payload => {
  return {
    type: HANDLE_SEARCH_AGENCY,
    payload: payload
  };
};

export const getAgencies =
  (where = {}) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchAgencies());
    axios
      .get(`/get-all-agency?${filterdWhere}`)
      .then(res => {
        dispatch(fetchAgenciesSuccess({ agencies: res.data.data.agencies }));
        dispatch(handleSearchValue(where));
      })
      .catch(err => {
        dispatch(fetchAgenciesFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

//FETCH AGENCIES
const fetchAgenciesByCompanyId = payload => {
  return {
    type: GET_AGENCIES_BY_COMPANY_ID,
    payload: payload
  };
};
const fetchAgenciesByCompanyIdSuccess = payload => {
  return {
    type: GET_AGENCIES_BY_COMPANY_ID_SUCCESS,
    payload: payload
  };
};
const fetchAgenciesByCompanyIdFailure = payload => {
  return {
    type: GET_AGENCIES_BY_COMPANY_ID_FAILURE,
    payload: payload
  };
};

export const getAgenciesByCompanyId = company_id => async dispatch => {
  dispatch(fetchAgenciesByCompanyId());
  axios
    .get(`/get-all-agency?company_id=${company_id}`)
    .then(res => {
      dispatch(fetchAgenciesByCompanyIdSuccess(res.data.data.agencies));
    })
    .catch(err => {
      dispatch(fetchAgenciesByCompanyIdFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

import React from "react";
import "./index.scss";
// import CustomModal from "@components/CustomModal";
import ClientsForm from "@components/Clients/ClientsForm";
import AddOrEditModal from "@components/AddOrEditModal";

const ClientsAddOrEditModal = ({ isEdit, component, data, callBack }) => {
  return <AddOrEditModal component={component} formInitialData={data} isEdit={isEdit} title={isEdit ? "Edit client" : "Add client"} formComponent={ClientsForm} callBack={callBack} />;
};

export default ClientsAddOrEditModal;

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import numeral from "numeral";

// Content - shows how tables can be integrated with any other pdf content

function headRows() {
  return [
    {
      sr: "No.",
      check: "",
      policy_type_name: "Policy Type",
      start_date: "Start Date",
      end_date: "End Date",
      client_name: "Name",
      client_mobile: "Mob No.",
      premium: "Premium",
      percentage: "Per",
      commission: "Commission",
      fees: "Fees",
      amount: "Amount"
    }
  ];
}

function bodyRows(reportData, printFileDetails) {
  var body = [];
  reportData.forEach((policy, i) => {
    body.push({
      sr: i + 1,
      check: "[  ]",
      policy_type_name: policy.policy_type_name,
      start_date: moment(policy.start_date).format("DD-MM-YYYY"),
      end_date: moment(policy.end_date).format("DD-MM-YYYY"),
      client_name: policy.client_name.length > 40 ? policy.client_name.slice(0, 35) + "..." : policy.client_name,
      client_mobile: policy.client_mobile ? `+${policy.client_mobile}` : "",
      premium: policy.premium ? numeral(policy.premium).format("0,0") : "0",
      percentage: policy.percentage ? `${policy.percentage}` : "0",
      commission: policy.commission ? `${numeral(policy.commission).format("0,0")}` : "0",
      fees: policy.fees ? `${policy.fees}` : "0",
      amount: policy.amount ? `${policy.amount}` : "0"
    });
  });
  body.push({
    sr: ``,
    check: "",
    policy_type_name: "",
    start_date: "",
    end_date: "",
    client_name: "",
    client_mobile: "",
    premium: `${printFileDetails.totalPremium ? numeral(printFileDetails.totalPremium).format("0,0") : "0"}`,
    percentage: "",
    commission: `${printFileDetails.totalCommission ? numeral(printFileDetails.totalCommission).format("0,0") : "0"}`,
    fees: `${printFileDetails.totalFees ? printFileDetails.totalFees : "0"}`,
    amount: `${numeral(printFileDetails.totalPremium || 0 + printFileDetails.totalFees || 0).format("0,0")}`
  });
  return body;
}

export function reportPdf({ report, printFileDetails }) {
  let marginTop = 25;
  if (report.length) {
    if (report == null) return;
    var doc = new jsPDF("l", "mm", "a4"); // for landscap size "l" and for normal "a4"

    doc.setFontSize(14);
    printFileDetails.start ? doc.text(`Commission Report (${printFileDetails.start} - ${printFileDetails.end})`, 80, 15) : doc.text(`Commission Report`, 120, 15);

    doc.setFontSize(11);
    doc.setTextColor(100);

    doc.text(`Total : ${report.length} policies`, 14, 20);
    doc.text(`Date : ${moment().format("DD-MM-YYYY")}`, 250, 20);

    if (!!printFileDetails.policyTypeName) {
      doc.text(`Policy Type : ${printFileDetails.policyTypeName || "-"}`, 14, marginTop);
      marginTop = marginTop + 5;
    }
    if (!!printFileDetails.companyName) {
      doc.text(`Company : ${printFileDetails.companyName || "-"}`, 14, marginTop);
      marginTop = marginTop + 5;
    }
    if (!!printFileDetails.referenceName) {
      doc.text(`Reference : ${printFileDetails.referenceName || "-"}`, 14, marginTop);
      marginTop = marginTop + 5;
    }
    if (!!printFileDetails.agencyName) {
      doc.text(`Agency : ${printFileDetails.agencyName || "-"}`, 14, marginTop);
      marginTop = marginTop + 5;
    }

    doc.autoTable({
      head: headRows(),
      body: bodyRows(report, printFileDetails),
      startY: marginTop,
      styles: {
        fontSize: 8
      },
      columnStyles: {
        1: { cellWidth: 10 },
        7: { halign: "right" },
        8: { halign: "right" },
        9: { halign: "right" },
        10: { halign: "right" },
        11: { halign: "right" }
      }
      // showHead: "everyPage"
    });

    printFileDetails.start ? doc.save(`Commission Report (${printFileDetails.start} - ${printFileDetails.end}).pdf`) : doc.save(`Commission Report.pdf`);
  } else {
    alert("There isn't any data to generate report!");
  }
}

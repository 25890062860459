import React from "react";
import { Modal } from "antd";

import HotKeyPressAction from "@components/ReactHotKey";

import "./index.scss";

const CustomModal = ({ visible, title, onOk, onCancel, children, isShortcutEnable, showModal }) => {
  return (
    <>
      {isShortcutEnable ? <HotKeyPressAction keyboardKey="ctrl+k" onKeyPress={showModal} /> : ""}
      {visible ? (
        <Modal className="custom-modal" title={title} visible={visible} onOk={onOk} onCancel={onCancel} footer={null}>
          {children}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default CustomModal;

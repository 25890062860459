import React, { useState, useEffect } from "react";
import { Card, Table, Tooltip, Space, Menu, Dropdown } from "antd";
import { EditOutlined, DeleteOutlined, CloseCircleTwoTone, CheckCircleTwoTone, RetweetOutlined, SyncOutlined, FolderTwoTone, MoreOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { deletePolicy, getPolicyType } from "@redux/action";
import Confirmation from "@components/Confirmation";
import PoliciesAddOrEditModal from "@components/Policies/PoliciesAddOrEditModal";
import moment from "moment";
import DownloadPDF from "@components/GeneratePDF/DownloadPDF";
import PolicyTypeTag from "@components/PolicyTypes/PolicyTypeTag";
import UsernameAvatar from "@components/UsernameAvatar";
import numeral from "numeral";
import { isMobile } from "react-device-detect";

const PoliciesTable = ({ data }) => {
  const dispatch = useDispatch();

  const {
    loading,
    removeLoading,
    policyTypes,
    user: { is_master_access }
  } = useSelector(({ policy, policyType, auth }) => {
    return {
      loading: policy.fetchingPoliciesLoading,
      removeLoading: policy.removePolicyLoading,
      policyTypes: policyType.policyTypes,
      user: auth.userData
    };
  });

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: "start_date",
    order: "descend"
  });

  const handleChangeTable = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    !policyTypes.length && dispatch(getPolicyType());
  }, [dispatch, policyTypes]);

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      showSorterTooltip: false,
      render(text, record, index) {
        let data = { ...record };
        const start_date = moment(moment(moment(data.start_date).format("YYYY-MM-DD"), "YYYY-MM-DD").add(12, "M").format("YYYY-MM-DD"));
        const end_date = moment(moment(moment(data.end_date).format("YYYY-MM-DD"), "YYYY-MM-DD").add(12, "M").format("YYYY-MM-DD"));
        data = Object.assign(data, { renewal_policy_id: data.id, sum_insured: 0, policy_number: "", premium: 0, start_date, end_date });

        const gst = (record.premium * (100 / (100 + process.env.REACT_APP_GST_RATE))).toFixed(1);
        const inHandAmount = record.premium - gst;
        return {
          children: (
            <Card style={{ padding: "10px", fontSize: "12px", width: "100%", border: "transparent" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <PolicyTypeTag color={record.policy_type_color} text={record.policy_type_name} />
                  </div>
                  <div>
                    {record.status === 0 ? (
                      !record.end_date || moment(moment(record.end_date).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? (
                        <Tooltip placement="bottom" title="Expired">
                          <CloseCircleTwoTone twoToneColor="#f44336" style={{ fontSize: "22px" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="bottom" title="Active">
                          <CheckCircleTwoTone twoToneColor="#4caf50" style={{ fontSize: "22px" }} />
                        </Tooltip>
                      )
                    ) : (
                      ""
                    )}

                    {record.status === 1 ? (
                      <Tooltip placement="bottom" title="Renewed">
                        <SyncOutlined style={{ fontSize: "22px", color: "#08c" }} />
                      </Tooltip>
                    ) : (
                      ""
                    )}

                    {record.status === 2 ? (
                      <Tooltip placement="bottom" title="Old system data">
                        <FolderTwoTone twoToneColor="#f44336" style={{ fontSize: "22px" }} />
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div style={{ fontWeight: "700" }}>{record.client_name}</div>
                {record.client_mobile ? <a href={`tel:+${record.client_mobile}`}>{`+${record.client_mobile}`}</a> : ""}
                <Space>
                  <div>{moment(record.start_date).format("DD-MM-YYYY")}</div> <span> - </span>{" "}
                  <div>{`${moment(record.end_date).format("DD-MM-YYYY")} (${moment(record.end_date).endOf("day").fromNow()})`}</div>
                </Space>
                {record.vehicle_number && <div>{record.vehicle_number}</div>}
                {record.vehicle_make && <div>{`${record.vehicle_make} ${record.model ? `[${record.model}]` : ""}`}</div>}

                <Space>
                  <div style={{ fontWeight: "700" }}>Sum Ins.: </div>
                  {`₹ ${record.sum_insured ? numeral(record.sum_insured).format("0,0") : " 0"}`}
                </Space>
                <Space>
                  <div style={{ fontWeight: "700" }}>Premium: </div>
                  <div>
                    {`₹ ${record.premium ? numeral(record.premium).format("0,0") : " 0"}  | ${
                      record.fees ? `Fee: ₹ ${record.fees}` : `Com: ₹ ${numeral((inHandAmount * (record.percentage || 0)) / 100).format("0")} (${record.percentage || 0}%)`
                    }
                    `}
                  </div>
                </Space>
              </Space>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space size="middle" className="action-buttons">
                  <DownloadPDF row={record} />
                  {record.status === 0 || record.status === 2 ? (
                    <PoliciesAddOrEditModal component={<RetweetOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />} isEdit={true} isRenew={true} data={data} />
                  ) : (
                    <RetweetOutlined style={{ fontSize: "18px", color: "#08c", visibility: "hidden" }} theme="outlined" />
                  )}
                  <PoliciesAddOrEditModal component={<EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />} isEdit={true} data={record} />
                  {is_master_access && (
                    <Confirmation
                      title="Are you sure to delete this?"
                      dispatchActionMethod={deletePolicy}
                      loading={removeLoading}
                      data={record}
                      component={<DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />}
                    />
                  )}
                </Space>
              </div>
            </Card>
          )
        };
      },
      responsive: ["xs"]
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "center",
      ellipsis: true,
      width: "50px",
      render(text, record, index) {
        return {
          children: (
            <div>
              {record.status === 0 ? (
                !record.end_date || moment(moment(record.end_date).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? (
                  <Tooltip placement="bottom" title="Expired">
                    <CloseCircleTwoTone twoToneColor="#f44336" style={{ fontSize: "22px" }} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="bottom" title="Active">
                    <CheckCircleTwoTone twoToneColor="#4caf50" style={{ fontSize: "22px" }} />
                  </Tooltip>
                )
              ) : (
                ""
              )}

              {record.status === 1 ? (
                <Tooltip placement="bottom" title="Renewed">
                  <SyncOutlined style={{ fontSize: "22px", color: "#08c" }} />
                </Tooltip>
              ) : (
                ""
              )}

              {record.status === 2 ? (
                <Tooltip placement="bottom" title="Old system data">
                  <FolderTwoTone twoToneColor="#f44336" style={{ fontSize: "22px" }} />
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Policy Type",
      dataIndex: "policy_type_name",
      key: "policy_type_name",
      filters: policyTypes.map(p_type => {
        return {
          text: <PolicyTypeTag color={p_type.color} text={p_type.name} />,
          value: p_type.name
        };
      }),
      onFilter: (value, record) => record.policy_type_name.indexOf(value) === 0,
      filteredValue: filteredInfo.policy_type_name || null,
      // sorter: (a, b) => a.policy_type_name && b.policy_type_name && a.policy_type_name.localeCompare(b.policy_type_name),
      // showSorterTooltip: false,
      // sortOrder: sortedInfo.columnKey === "policy_type_name" && sortedInfo.order,
      ellipsis: true,
      width: "120px",
      render(text, record) {
        return {
          children: (
            <div>
              <PolicyTypeTag color={record.policy_type_color} text={text} />
            </div>
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Vehicle",
      dataIndex: "",
      key: "",
      filteredValue: filteredInfo.vehicle_number || null,
      sortOrder: sortedInfo.columnKey === "vehicle_number" && sortedInfo.order,
      width: "160px",
      ellipsis: true,
      render(text, record) {
        return {
          children: record.vehicle_number ? (
            <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>
              <div style={{ letterSpacing: "0.5px" }}>{record.vehicle_number} </div>
              <div>{`${record.vehicle_make} (${record.model})`}</div>
            </div>
          ) : (
            ""
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Name",
      dataIndex: "client_name",
      key: "client_name",
      filteredValue: filteredInfo.client_name || null,
      // sorter: (a, b) => a.client_name && b.client_name && a.client_name.localeCompare(b.client_name),
      showSorterTooltip: false,
      sortOrder: sortedInfo.columnKey === "client_name" && sortedInfo.order,
      ellipsis: true,
      width: "150px",
      render(text, record) {
        return {
          children: <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>{text}</div>
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Mob No.",
      dataIndex: "client_mobile",
      key: "client_mobile",
      width: "120px",
      filteredValue: filteredInfo.client_mobile || null,
      sortOrder: sortedInfo.columnKey === "client_mobile" && sortedInfo.order,
      ellipsis: true,
      render(text, record) {
        return {
          children: (
            <div>
              {text ? (
                <Tooltip placement="bottom" title={`+${text}`}>
                  <a href={`tel:+${text}`}>{`+${text}`}</a>{" "}
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      // sorter: (a, b) => moment(a.start_date).unix() - moment(b.start_date).unix(),
      // showSorterTooltip: false,
      // sortOrder: sortedInfo.columnKey === "start_date" && sortedInfo.order,
      ellipsis: true,
      width: "90px",
      render(text, record) {
        return {
          children: moment(text).format("DD-MM-YYYY")
        };
      },
      responsive: ["sm"]
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      // sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      // showSorterTooltip: false,
      // sortOrder: sortedInfo.columnKey === "end_date" && sortedInfo.order,
      ellipsis: true,
      width: "90px",
      render(text, record) {
        return {
          children: (
            <div>
              <div>{`${moment(text).format("DD-MM-YYYY")}`}</div>
              <div style={{ fontSize: "10px" }}>{`(${moment(text).endOf("day").fromNow()})`}</div>
            </div>
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Sum Ins.",
      dataIndex: "sum_insured",
      key: "sum_insured",
      align: "right",
      width: "120px",
      // sorter: (a, b) => a.sum_insured - b.sum_insured,
      // showSorterTooltip: false,
      // sortOrder: sortedInfo.columnKey === "sum_insured" && sortedInfo.order,
      ellipsis: true,
      render(text, record) {
        return {
          children: <div>{`₹ ${text ? numeral(text).format("0,0") : " 0"}`}</div>
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      align: "right",
      sorter: (a, b) => a.premium - b.premium,
      showSorterTooltip: false,
      sortOrder: sortedInfo.columnKey === "premium" && sortedInfo.order,
      ellipsis: true,
      width: "130px",
      render(text, record) {
        const gst = (text * (100 / (100 + process.env.REACT_APP_GST_RATE))).toFixed(1);
        const inHandAmount = text - gst;
        return {
          children: (
            <div>
              <div>{`₹ ${text ? numeral(text).format("0,0") : " 0"}`}</div>
              {record.fees ? (
                <div style={{ fontSize: "11px" }}>{`Fee: ₹ ${record.fees}`}</div>
              ) : (
                <div style={{ fontSize: "11px" }}>{`Com: ₹ ${numeral((inHandAmount * (record.percentage || 0)) / 100).format("0")} (${record.percentage || 0}%)`}</div>
              )}
            </div>
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "updated_by",
      key: "updated_by",
      align: "center",
      width: "40px",
      render(text) {
        return {
          children: <UsernameAvatar username={text} />
        };
      },
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      ellipsis: true,
      fixed: isMobile ? "unset" : "right",
      width: 70,
      render(text, record) {
        let data = { ...record };
        const start_date = moment(moment(moment(data.start_date).format("YYYY-MM-DD"), "YYYY-MM-DD").add(12, "M").format("YYYY-MM-DD"));
        const end_date = moment(moment(moment(data.end_date).format("YYYY-MM-DD"), "YYYY-MM-DD").add(12, "M").format("YYYY-MM-DD"));
        data = Object.assign(data, { renewal_policy_id: data.id, sum_insured: 0, policy_number: "", premium: 0, start_date, end_date });
        return {
          children: record ? (
            <div className="action-buttons">
              {record.status === 0 || record.status === 2 ? (
                <PoliciesAddOrEditModal
                  component={
                    <Tooltip title="Renew" placement="bottom">
                      <RetweetOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />
                    </Tooltip>
                  }
                  isEdit={true}
                  isRenew={true}
                  data={data}
                />
              ) : (
                <RetweetOutlined style={{ fontSize: "18px", color: "#08c", visibility: "hidden" }} theme="outlined" />
              )}

              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <Space>
                        <DownloadPDF row={record} withLabel />
                      </Space>
                    </Menu.Item>
                    <Menu.Item key="2">
                      <PoliciesAddOrEditModal
                        component={
                          <Space>
                            <EditOutlined style={{ color: "#08c", cursor: "pointer" }} theme="outlined" /> Edit Policy
                          </Space>
                        }
                        isEdit={true}
                        data={record}
                      />
                    </Menu.Item>
                    {is_master_access && (
                      <Menu.Item key="3">
                        <Confirmation
                          title="Are you sure to delete this?"
                          dispatchActionMethod={deletePolicy}
                          loading={removeLoading}
                          data={record}
                          component={
                            <Space>
                              <DeleteOutlined /> Delete
                            </Space>
                          }
                        />
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <MoreOutlined style={{ cursor: "pointer" }} />
              </Dropdown>
            </div>
          ) : (
            ""
          )
        };
      },
      responsive: ["sm"]
    }
  ];

  return (
    <Table
      bordered
      size="small"
      columns={columns}
      scroll={isMobile ? {} : { x: 1160 }}
      dataSource={data}
      onChange={handleChangeTable}
      pagination={{
        pageSizeOptions: ["8", "10", "20", "50", "100"],
        defaultPageSize: localStorage.getItem("policies-pagesize") || "8",
        showSizeChanger: true,
        onShowSizeChange: (current, size) => {
          localStorage.setItem("policies-pagesize", size);
        }
      }}
      loading={loading}
    />
  );
};

export default PoliciesTable;

import React from "react";
import Pages from "@/pages";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import AppLayout from "@components/AppLayout";

import "@styles/app.scss";

// Mobile testing un comment below code
// import * as rdd from "react-device-detect";
// rdd.isMobile = true;

const App = () => {
  const hist = createBrowserHistory();
  return (
    <Router history={hist}>
      <AppLayout>
        <Pages />
      </AppLayout>
    </Router>
  );
};

export default App;

import Section from "@components/Section";
import { Space, Button, Typography } from "antd";
import "./index.scss";
import AgenciesTable from "@components/Agencies/AgenciesTable";
import AgenciesAddOrEditModal from "@components/Agencies/AgenciesAddOrEditModal";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const Agencies = () => {
  return (
    <Section className="agencies-page">
      <Space wrap className="agencies-table-toolbar">
        <Title className="title" level={3}>
          Agencies
        </Title>
        <AgenciesAddOrEditModal
          component={
            <Button type="primary" size="small" icon={<PlusOutlined />}>
              Add
            </Button>
          }
          data={{}}
        />
      </Space>
      <AgenciesTable />
    </Section>
  );
};

export default Agencies;

import React from "react";
import "./index.scss";
import AgenciesForm from "@components/Agencies/AgenciesForm";
import AddOrEditModal from "@components/AddOrEditModal";

const AgenciesAddOrEditModal = ({ isEdit, component, data }) => {
  return <AddOrEditModal component={component} formInitialData={data} isEdit={isEdit} title={isEdit ? "Edit agency" : "Add agency"} formComponent={AgenciesForm} />;
};

export default AgenciesAddOrEditModal;

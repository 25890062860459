import { Switch, Route, Redirect } from "react-router-dom";
import AuthLayout from "@components/Layouts/AuthPageLayout";
import Login from "./login";

function AuthPage() {
  return (
    <AuthLayout>
      <Switch>
        <Route exact path="/auth/login" component={Login} />
        <Redirect to="/auth/login" />
      </Switch>
    </AuthLayout>
  );
}

export default AuthPage;

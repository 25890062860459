import Section from "@components/Section";
import { Space, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import "./index.scss";

import PolicyTypesTable from "@components/PolicyTypes/PolicyTypesTable";
import PolicyTypeAddOrEditModal from "@components/PolicyTypes/PolicyTypesAddOrEditModal";
const { Title } = Typography;

const PolicyTypes = () => {
  return (
    <Section className="policy-types-page">
      <Space wrap className="policy-types-table-toolbar">
        <Title className="title" level={3}>
          Policy Types
        </Title>
        <PolicyTypeAddOrEditModal
          component={
            <Button type="primary" size="small" icon={<PlusOutlined />}>
              Add
            </Button>
          }
          data={{}}
        />
      </Space>

      <PolicyTypesTable />
    </Section>
  );
};

export default PolicyTypes;

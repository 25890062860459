import React from "react";
import { Popconfirm, message, Input } from "antd";
import { useDispatch } from "react-redux";

const Confirmation = ({ component, onConfirm, title, loading, data, dispatchActionMethod, ...props }) => {
  const dispatch = useDispatch();
  const [input, setInputValue] = React.useState("");
  // const [visible, setVisible] = React.useState(false);
  const { id } = data;

  const handleConfirm = () => {
    dispatch(dispatchActionMethod(id)).then(() => {
      // handleCancel();
      message.success("Item Deleted successfully.");
    });
    // setVisible(false);
  };

  // const showPopConfirm = () => {
  //   setVisible(true);
  // };

  // const handleCancel = () => {
  //   setVisible(false);
  // };

  const handleOnChange = e => {
    setInputValue(e.target.value);
  };

  return (
    <Popconfirm
      title={
        <div>
          <div>{title}</div>
          <Input size="small" placeholder="Enter Password" onChange={handleOnChange} />
        </div>
      }
      okText="Yes"
      cancelText="No"
      onConfirm={handleConfirm}
      // onCancel={handleCancel}
      // visible={visible}
      okButtonProps={{ loading: loading, disabled: input !== process.env.REACT_APP_DELETE_PASSCODE }}
      {...props}
    >
      <div
        // onClick={showPopConfirm}
        style={loading ? { opacity: 0.6, pointerEvents: "none" } : {}}
      >
        {component}
      </div>
    </Popconfirm>
  );
};

export default Confirmation;

import {
  ADD_POLICYTYPE,
  ADD_POLICYTYPE_SUCCESS,
  ADD_POLICYTYPE_FAILURE,
  EDIT_POLICYTYPE,
  EDIT_POLICYTYPE_SUCCESS,
  EDIT_POLICYTYPE_FAILURE,
  REMOVE_POLICYTYPE,
  REMOVE_POLICYTYPE_SUCCESS,
  REMOVE_POLICYTYPE_FAILURE,
  GET_POLICYTYPES,
  GET_POLICYTYPES_SUCCESS,
  GET_POLICYTYPES_FAILURE
} from "./actionType";
import { generateQuery } from "@helpers";
import axios from "@config/axios";

//ADD policy type
const createPolicyType = payload => {
  return {
    type: ADD_POLICYTYPE,
    payload: payload
  };
};
const createPolicyTypeSuccess = payload => {
  return {
    type: ADD_POLICYTYPE_SUCCESS,
    payload: payload
  };
};
const createPolicyTypeFailure = payload => {
  return {
    type: ADD_POLICYTYPE_FAILURE,
    payload: payload
  };
};
export const addPolicyType = body => async dispatch => {
  dispatch(createPolicyType());
  return await axios
    .post(`/create-policy-type`, body)
    .then(res => {
      dispatch(createPolicyTypeSuccess(res.data.data.policyType));
    })
    .catch(err => {
      dispatch(createPolicyTypeFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//UPDATE policy type
const updatePolicyType = payload => {
  return {
    type: EDIT_POLICYTYPE,
    payload: payload
  };
};
const updatePolicyTypeSuccess = payload => {
  return {
    type: EDIT_POLICYTYPE_SUCCESS,
    payload: payload
  };
};
const updatePolicyTypeFailure = payload => {
  return {
    type: EDIT_POLICYTYPE_FAILURE,
    payload: payload
  };
};
export const editPolicyType = (id, body) => async dispatch => {
  dispatch(updatePolicyType());
  return await axios
    .put(`/update-policy-type/${id}`, body)
    .then(res => {
      dispatch(updatePolicyTypeSuccess(res.data.data.policyType));
    })
    .catch(err => {
      dispatch(updatePolicyTypeFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//REMOVE policy type
const removePolicyType = payload => {
  return {
    type: REMOVE_POLICYTYPE,
    payload: payload
  };
};
const removePolicyTypeSuccess = payload => {
  return {
    type: REMOVE_POLICYTYPE_SUCCESS,
    payload: payload
  };
};
const removePolicyTypeFailure = payload => {
  return {
    type: REMOVE_POLICYTYPE_FAILURE,
    payload: payload
  };
};
export const deletePolicyType = id => async dispatch => {
  dispatch(removePolicyType());
  return await axios
    .delete(`/delete-policy-type/${id}`)
    .then(res => {
      dispatch(removePolicyTypeSuccess(id));
    })
    .catch(err => {
      dispatch(removePolicyTypeFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//FETCH policies type
const fetchPolicyType = payload => {
  return {
    type: GET_POLICYTYPES,
    payload: payload
  };
};
const fetchPolicyTypeSuccess = payload => {
  return {
    type: GET_POLICYTYPES_SUCCESS,
    payload: payload
  };
};
const fetchPolicyTypeFailure = payload => {
  return {
    type: GET_POLICYTYPES_FAILURE,
    payload: payload
  };
};

export const getPolicyType =
  (where = {}) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchPolicyType());
    axios
      .get(`/get-all-policy-type?${filterdWhere}`)
      .then(res => {
        dispatch(fetchPolicyTypeSuccess({ policyTypes: res.data.data.policyTypes, searchValue: where }));
      })
      .catch(err => {
        dispatch(fetchPolicyTypeFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

import React from "react";
import { pickBy, identity } from "lodash";
import { Form, Input, Button, Row, Col, message } from "antd";
import { addReference, editReference } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";

const layout = {
  labelCol: {
    span: 10
  },
  wrapperCol: {
    span: 24
  }
};
const ReferencesForm = ({ handleClose, isEdit, data = {}, callBack }) => {
  const dispatch = useDispatch();
  const { id, name = "", mobile = "" } = data;
  const { loading } = useSelector(({ references }) => {
    return {
      loading: references.manipulateReferenceLoading
    };
  });

  const onFinish = formData => {
    let { name = "", mobile = "" } = formData;

    mobile = mobile && mobile.match(/\d+/g).join("");
    if (mobile.length && mobile.length < 10) return message.error("Please enter complete phone number");

    //remove empty string for backend validation
    let body = pickBy(
      {
        name,
        mobile
      },
      identity
    );

    if (isEdit) {
      dispatch(editReference(id, body)).then(() => {
        handleClose();
        message.success("Reference updated successfully.");
      });
    } else {
      dispatch(addReference(body)).then(res => {
        message.success("Reference added successfully.");
        callBack && callBack(res);
        handleClose();
      });
    }
  };

  return (
    <Form {...layout} layout="vertical" onFinish={onFinish} key={"reference-form"} initialValues={{ name, mobile: mobile }} size="small">
      <Row>
        <Col xs={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is required"
              }
            ]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item
            name="mobile"
            label="Mobile"
            rules={[
              {
                required: !true,
                message: "Mobile is required"
              },
              {
                validator: async (_, value) => {
                  if (value && value.match(/\d+/g).join("").length !== 12) {
                    return Promise.reject(new Error("Invalid Mobile Number"));
                  }
                }
              }
            ]}
          >
            <InputMask className="ant-input" mask={"+\\91 (999) 999-9999"} autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>

      <div className="modal-actions">
        <Button size="small" onClick={handleClose} className="cancel-button" tabIndex="-1">
          Cancel
        </Button>
        <Button size="small" type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default ReferencesForm;

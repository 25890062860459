import { CheckCircleFilled, ClockCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";

const LicenceApplicationStatusTag = ({ application_status, text, tag }) => {
  return application_status === "COMPLETED" ? (
    <Space>
      <CheckCircleFilled color="#87d068" style={{ fontSize: "18px", marginRight: "4px", marginTop: "7px" }} />
      {tag ? "" : text}
    </Space>
  ) : (
    <Space>
      <ClockCircleOutlined style={{ fontSize: "18px", marginRight: "4px", marginTop: "7px" }} />
      {tag ? "" : text}
    </Space>
  );
};

export default LicenceApplicationStatusTag;

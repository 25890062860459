import React from "react";
import "./index.scss";
import ReferencesForm from "@components/References/ReferencesForm";
import AddOrEditModal from "@components/AddOrEditModal";

const ReferencesAddOrEditModal = ({ isEdit, component, data, callBack }) => {
  return <AddOrEditModal component={component} formInitialData={data} isEdit={isEdit} title={isEdit ? "Edit reference" : "Add reference"} formComponent={ReferencesForm} callBack={callBack} />;
};

export default ReferencesAddOrEditModal;

import React from 'react';
import { Card } from 'antd';
import clsx from 'clsx';
import styles from './index.module.scss';

const Section = ({ className, children, footer, ...props }) => {
  return (
    <Card className={clsx(className, styles.section)} bordered={false} {...props}>
      {children}
      {footer && <div className={styles.footer}>{footer}</div>}
    </Card>
  );
};

export default Section;

import {
  ADD_CLIENT,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  EDIT_CLIENT,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE,
  REMOVE_CLIENT,
  REMOVE_CLIENT_SUCCESS,
  REMOVE_CLIENT_FAILURE,
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
  HANDLE_SEARCH_CLIENT,
  GET_CLIENTS_BY_SEARCH_NAME,
  GET_CLIENTS_BY_SEARCH_NAME_SUCCESS,
  GET_CLIENTS_BY_SEARCH_NAME_FAILURE,
  CLEAR_CLIENTS_BY_SEARCH_NAME,
  LOCAL_ADD_CLIENT_BY_SOCKET,
  LOCAL_UPDATE_CLIENT_BY_SOCKET,
  LOCAL_DELETE_CLIENT_BY_SOCKET
} from "./actionType";
import { generateQuery } from "@helpers";
import axios from "@config/axios";
import { updatePolicyFromClient } from "./policy.action";
import { updateRenewalPolicyFromClient } from "./renewalPolicy.action";

//ADD policy type
const createClient = payload => {
  return {
    type: ADD_CLIENT,
    payload: payload
  };
};
const createClientSuccess = payload => {
  return {
    type: ADD_CLIENT_SUCCESS,
    payload: payload
  };
};
const createClientFailure = payload => {
  return {
    type: ADD_CLIENT_FAILURE,
    payload: payload
  };
};
export const addClient = body => async dispatch => {
  dispatch(createClient());
  return await axios
    .post(`/create-client`, body)
    .then(res => {
      dispatch(createClientSuccess(res.data.data.client));
      dispatch(fetchClientBySearchNameSuccess([res.data.data.client]));
      return res.data.data.client;
    })
    .catch(err => {
      dispatch(createClientFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//UPDATE policy type
const updateClient = payload => {
  return {
    type: EDIT_CLIENT,
    payload: payload
  };
};
const updateClientSuccess = payload => {
  return {
    type: EDIT_CLIENT_SUCCESS,
    payload: payload
  };
};
const updateClientFailure = payload => {
  return {
    type: EDIT_CLIENT_FAILURE,
    payload: payload
  };
};
export const editClient = (id, body) => async dispatch => {
  dispatch(updateClient());
  return await axios
    .put(`/update-client/${id}`, body)
    .then(res => {
      dispatch(updateClientSuccess(res.data.data));
      dispatch(updatePolicyFromClient(res.data.data));
      dispatch(updateRenewalPolicyFromClient(res.data.data));
    })
    .catch(err => {
      dispatch(updateClientFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//REMOVE policy type
const removeClient = payload => {
  return {
    type: REMOVE_CLIENT,
    payload: payload
  };
};
const removeClientSuccess = payload => {
  return {
    type: REMOVE_CLIENT_SUCCESS,
    payload: payload
  };
};
const removeClientFailure = payload => {
  return {
    type: REMOVE_CLIENT_FAILURE,
    payload: payload
  };
};
export const deleteClient = id => async dispatch => {
  dispatch(removeClient());
  return await axios
    .delete(`/delete-client/${id}`)
    .then(res => {
      dispatch(removeClientSuccess(id));
    })
    .catch(err => {
      dispatch(removeClientFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//FETCH policies type
const fetchClient = payload => {
  return {
    type: GET_CLIENTS,
    payload: payload
  };
};
const fetchClientSuccess = payload => {
  return {
    type: GET_CLIENTS_SUCCESS,
    payload: payload
  };
};
const fetchClientFailure = payload => {
  return {
    type: GET_CLIENTS_FAILURE,
    payload: payload
  };
};

const handleSearchValue = payload => {
  return {
    type: HANDLE_SEARCH_CLIENT,
    payload: payload
  };
};

export const getClient =
  (where = {}, isSearch) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchClient(isSearch));
    axios
      .get(`/get-all-client?${filterdWhere}`)
      .then(res => {
        dispatch(fetchClientSuccess(res.data.data.clients));
        dispatch(handleSearchValue(where));
      })
      .catch(err => {
        dispatch(fetchClientFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

//FETCH policies type
const fetchClientBySearchName = payload => {
  return {
    type: GET_CLIENTS_BY_SEARCH_NAME,
    payload: payload
  };
};
const fetchClientBySearchNameSuccess = payload => {
  return {
    type: GET_CLIENTS_BY_SEARCH_NAME_SUCCESS,
    payload: payload
  };
};

const fetchClientBySearchNameFailure = payload => {
  return {
    type: GET_CLIENTS_BY_SEARCH_NAME_FAILURE,
    payload: payload
  };
};

export const getClientBySearchName =
  (where = {}, isSearch) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchClientBySearchName());
    axios
      .get(`/get-all-client?${filterdWhere}`)
      .then(res => {
        dispatch(fetchClientBySearchNameSuccess(res.data.data.clients));
      })
      .catch(err => {
        dispatch(fetchClientBySearchNameFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

export const handleClearClientsSearchByName = payload => {
  return {
    type: CLEAR_CLIENTS_BY_SEARCH_NAME,
    payload: payload
  };
};

export const localAddClientBySocket = payload => {
  return {
    type: LOCAL_ADD_CLIENT_BY_SOCKET,
    payload: payload
  };
};

export const localUpdateClientBySocket = payload => {
  return {
    type: LOCAL_UPDATE_CLIENT_BY_SOCKET,
    payload: payload
  };
};

export const localDeleteClientBySocket = payload => {
  return {
    type: LOCAL_DELETE_CLIENT_BY_SOCKET,
    payload: payload
  };
};

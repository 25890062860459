import React, { useEffect } from "react";
import { pickBy, identity } from "lodash";
import { Form, Input, Button, Row, Col, Select, message } from "antd";
import { addClient, editClient, getReferences } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";
import ReferencesAddOrEditModal from "@components/References/ReferencesAddOrEditModal";
import InputMask from "react-input-mask";
import { UserAddOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 12
  },
  wrapperCol: {
    span: 22
  }
};

const ClientsForm = ({ isEdit, handleClose, data = {}, callBack }) => {
  const dispatch = useDispatch();
  const { id, name = "", address = "", mobile = "", phone = "", acc_no = "", refer_id = "" } = data;
  const [form] = Form.useForm();

  const { loading, references } = useSelector(({ client, references }) => {
    return {
      loading: client.manipulateClientLoading,
      references: references.references
    };
  });

  useEffect(() => {
    !references.length && dispatch(getReferences());
  }, [dispatch, references]);

  const onFinish = formData => {
    let { name = "", address = "", mobile = "", phone = "", acc_no = "", refer_id = "" } = form.getFieldsValue(true);

    mobile = mobile && mobile.match(/\d+/g).join("");

    phone = phone && phone.match(/\d+/g).join("");

    //remove empty string and null values for backend validation
    let body = pickBy(
      {
        name: name.toUpperCase(),
        address,
        mobile,
        phone,
        acc_no,
        refer_id
      },
      identity
    );

    if (isEdit) {
      dispatch(editClient(id, body)).then(() => {
        handleClose();
        message.success("Client updated successfully.");
      });
    } else {
      dispatch(addClient(body)).then(res => {
        handleClose();
        message.success("Client added successfully.");
        callBack && callBack(res);
      });
    }
  };

  const handleCallBackAddReferences = reference => {
    form.setFieldsValue({ refer_id: reference.id });
  };

  return (
    <Form {...layout} layout="vertical" form={form} onFinish={onFinish} key={"client-form"} initialValues={{ name, address, mobile, phone, acc_no, refer_id }} size="small">
      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is required"
              }
            ]}
          >
            <Input autoFocus style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="acc_no"
            label="Acc no."
            rules={[
              {
                required: !true
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="mobile"
            label="Primary Mobile"
            rules={[
              {
                required: !true
              },
              {
                validator: async (_, value) => {
                  if (value && value.match(/\d+/g).join("").length !== 12) {
                    return Promise.reject(new Error("Invalid Mobile Number"));
                  }
                }
              }
            ]}
            size={12}
          >
            <InputMask className="ant-input" mask={"+\\91 (999) 999-9999"} autoComplete="off" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="phone"
            label="Secondary Mobile"
            rules={[
              {
                required: !true
              },
              {
                validator: async (_, value) => {
                  if (value && value.match(/\d+/g).join("").length !== 12) {
                    return Promise.reject(new Error("Invalid Mobile Number"));
                  }
                }
              }
            ]}
          >
            <InputMask className="ant-input" mask={"+\\91 (999) 999-9999"} autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Address is required"
              }
            ]}
          >
            <TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col xs={20} sm={20} md={20} lg={20} xl={20}>
          <Form.Item label="Reference" name="refer_id">
            <Select
              showSearch
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              allowClear
            >
              {references.map((ref, i) => {
                return (
                  <Select.Option key={i} value={ref.id}>
                    {ref.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="client-add-button">
          <ReferencesAddOrEditModal component={<Button type="primary" shape="circle" icon={<UserAddOutlined />} size={"small"} />} data={{}} callBack={res => handleCallBackAddReferences(res)} />
        </Col>
      </Row>

      <div className="modal-actions">
        <Button size="small" onClick={handleClose} className="cancel-button" tabIndex="-1">
          Cancel
        </Button>
        <Button size="small" type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default ClientsForm;

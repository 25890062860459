import {
  ADD_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  EDIT_COMPANY,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
  REMOVE_COMPANY,
  REMOVE_COMPANY_SUCCESS,
  REMOVE_COMPANY_FAILURE,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  HANDLE_SEARCH_COMPANY
} from "./actionType";
import { generateQuery } from "@helpers";
import axios from "@config/axios";

//ADD COMPANY
const createCompany = payload => {
  return {
    type: ADD_COMPANY,
    payload: payload
  };
};
const createCompanySuccess = payload => {
  return {
    type: ADD_COMPANY_SUCCESS,
    payload: payload
  };
};
const createCompanyFailure = payload => {
  return {
    type: ADD_COMPANY_FAILURE,
    payload: payload
  };
};
export const addCompany = body => async dispatch => {
  dispatch(createCompany());
  return await axios
    .post(`/create-company`, body)
    .then(res => {
      dispatch(createCompanySuccess({ agency: res.data.data.company }));
    })
    .catch(err => {
      dispatch(createCompanyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//UPDATE COMPANY
const updateCompany = payload => {
  return {
    type: EDIT_COMPANY,
    payload: payload
  };
};
const updateCompanySuccess = payload => {
  return {
    type: EDIT_COMPANY_SUCCESS,
    payload: payload
  };
};
const updateCompanyFailure = payload => {
  return {
    type: EDIT_COMPANY_FAILURE,
    payload: payload
  };
};
export const editCompany = (id, body) => async dispatch => {
  dispatch(updateCompany());
  return await axios
    .put(`/update-company/${id}`, body)
    .then(res => {
      dispatch(updateCompanySuccess(res.data.data.company));
    })
    .catch(err => {
      dispatch(updateCompanyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//REMOVE COMPANY
const removeCompany = payload => {
  return {
    type: REMOVE_COMPANY,
    payload: payload
  };
};
const removeCompanySuccess = payload => {
  return {
    type: REMOVE_COMPANY_SUCCESS,
    payload: payload
  };
};
const removeCompanyFailure = payload => {
  return {
    type: REMOVE_COMPANY_FAILURE,
    payload: payload
  };
};
export const deleteCompany = id => async dispatch => {
  dispatch(removeCompany());
  return await axios
    .delete(`/delete-company/${id}`)
    .then(res => {
      dispatch(removeCompanySuccess({ agencyId: id }));
    })
    .catch(err => {
      dispatch(removeCompanyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//FETCH COMPANY
const fetchCompany = payload => {
  return {
    type: GET_COMPANIES,
    payload: payload
  };
};
const fetchCompanySuccess = payload => {
  return {
    type: GET_COMPANIES_SUCCESS,
    payload: payload
  };
};
const fetchCompanyFailure = payload => {
  return {
    type: GET_COMPANIES_FAILURE,
    payload: payload
  };
};

const handleSearchValue = payload => {
  return {
    type: HANDLE_SEARCH_COMPANY,
    payload: payload
  };
};

export const getCompany =
  (where = {}) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchCompany());
    axios
      .get(`/get-all-company?${filterdWhere}`)
      .then(res => {
        dispatch(fetchCompanySuccess(res.data.data.companies));
        dispatch(handleSearchValue(where));
      })
      .catch(err => {
        dispatch(fetchCompanyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

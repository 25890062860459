import { useEffect } from "react";
import { Layout } from "antd";
import Header from "./header";
import Sidebar from "./sidebar";
import styles from "./index.module.scss";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "../../../config/axios";
import {
  localAddLicenceBySocket,
  localUpdateLicenceBySocket,
  localDeleteLicenceBySocket,
  localAddClientBySocket,
  localUpdateClientBySocket,
  localDeleteClientBySocket,
  localAddPolicyBySocket,
  localUpdatePolicyBySocket,
  localDeletePolicyBySocket,
  localUpdateRenewalPolicyBySocket,
  localPolicyRenewalBySocket,
  getClient,
  getPolicies,
  getLicence,
  getPolicyType,
  getCompany,
  getAgencies,
  getReferences,
  getRenewalPolicies,
  getVehicleNames,
  localAddVehicleNameBySocket
} from "@redux/action";
import io from "socket.io-client";

export let socket;
const { Content } = Layout;

const AppLayout = ({ children }) => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { userData } = useSelector(state => state.auth);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 2);
  }, [pathname]);

  const start_date = moment(moment().format("MMMM YYYY"), "MMMM YYYY").startOf("month").format("YYYY-MM-DD");
  const end_date = moment(moment().format("MMMM YYYY"), "MMMM YYYY").endOf("month").format("YYYY-MM-DD");

  const selectedYearForPolicy = localStorage.getItem("policies_selected_year") === "0" ? +localStorage.getItem("policies_selected_year") : +localStorage.getItem("policies_selected_year") || 1;

  const policyStartDate = moment()
    .startOf("year")
    .add(-+selectedYearForPolicy, "years");

  const policyEndDate = moment(policyStartDate).add(20, "years");

  const selectedYearForLicence = localStorage.getItem("licence_selected_year") === "0" ? +localStorage.getItem("licence_selected_year") : +localStorage.getItem("licence_selected_year") || 1;

  const licenceStartDate = moment()
    .startOf("year")
    .add(-+selectedYearForLicence, "years");

  // Until 20 years it will show data
  const licenceEndDate = moment(licenceStartDate).add(20, "years");

  const handleSuccess = async () => {
    Promise.all([
      dispatch(
        getPolicies({
          sort_field: "start_date:desc",
          ...(selectedYearForPolicy && { end_date: policyEndDate.format() }),
          ...(selectedYearForPolicy && { start_date: policyStartDate.format() })
        })
      ),
      dispatch(
        getLicence({
          ...(selectedYearForLicence && { end_date: moment(licenceEndDate).format() }),
          ...(selectedYearForLicence && { start_date: moment(licenceStartDate).format() })
        })
      ),
      dispatch(getClient()),
      dispatch(getPolicyType()),
      dispatch(getCompany()),
      dispatch(getAgencies()),
      dispatch(getReferences()),
      dispatch(getVehicleNames()),
      dispatch(getRenewalPolicies({ sort_field: "end_date:asc", compare_field: "only_end_date", start_date, end_date, status: 0 }))
    ]).then(() => {});
  };

  useEffect(() => {
    handleSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket = io.connect(process.env.REACT_APP_BACKEND_URL, { secure: true, reconnection: true, rejectUnauthorized: true });
    socket.on("connect", () => {
      axios.defaults.headers.common["socketid"] = socket.id;
    });

    socket.on("db_changes", res => {
      const { data, type, requested_change_by_socketID } = res;
      const isDifferentUser = socket.id !== requested_change_by_socketID;

      if (isDifferentUser) {
        switch (type) {
          case "SOCKET_ADD_CLIENT": {
            return dispatch(localAddClientBySocket(data));
          }
          case "SOCKET_UPDATE_CLIENT": {
            return dispatch(localUpdateClientBySocket(data));
          }
          case "SOCKET_DELETE_CLIENT": {
            return dispatch(localDeleteClientBySocket(data));
          }

          case "SOCKET_ADD_POLICY": {
            return dispatch(localAddPolicyBySocket(data));
          }
          case "SOCKET_UPDATE_POLICY": {
            return dispatch(localUpdatePolicyBySocket(data));
          }
          case "SOCKET_DELETE_POLICY": {
            return dispatch(localDeletePolicyBySocket(data));
          }

          case "SOCKET_ADD_LICENCE": {
            return dispatch(localAddLicenceBySocket(data));
          }
          case "SOCKET_UPDATE_LICENCE": {
            return dispatch(localUpdateLicenceBySocket(data));
          }
          case "SOCKET_DELETE_LICENCE": {
            return dispatch(localDeleteLicenceBySocket(data));
          }
          case "SOCKET_UPDATE_RENEW_POLICY": {
            dispatch(localPolicyRenewalBySocket(data));
            dispatch(localUpdateRenewalPolicyBySocket(data));
            return;
          }
          case "SOCKET_ADD_VEHICLE_NAME": {
            return dispatch(localAddVehicleNameBySocket(data));
          }
          default:
            return null;
        }
      }
    });

    socket.on("disconnect", () => {
      console.log("socket disconnected ::", socket.id);
    });

    socket.on("connect_error", err => {
      console.log("socket connect_error ::", err);
    });
  }, [dispatch, userData]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <div className={styles.rightPanel}>
        <Header onMenuClick={() => setIsSidebarVisible(true)} />

        <div style={{ display: "flex" }}>
          <Sidebar isSidebarVisible={isSidebarVisible} onClose={() => setIsSidebarVisible(false)} />

          <Content className={styles.content}>{children}</Content>
        </div>
      </div>
    </Layout>
  );
};

export default AppLayout;

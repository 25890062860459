import React from "react";
import "./index.scss";
// import CustomModal from "@components/CustomModal";
import PoliciesForm from "@components/Policies/PoliciesForm";
import AddOrEditModal from "@components/AddOrEditModal";

const PoliciesAddOrEditModal = ({ isEdit, component, data, isRenew }) => {
  return (
    <AddOrEditModal
      component={component}
      formInitialData={data}
      isEdit={isEdit}
      isRenew={isRenew}
      title={isRenew ? "Renew Policy" : isEdit ? "Edit policy" : "Add Policy"}
      formComponent={PoliciesForm}
    />
  );
};

export default PoliciesAddOrEditModal;

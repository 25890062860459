import React from "react";

import { useHotkeys } from "react-hotkeys-hook";

const HotKeyPressAction = ({ keyboardKey, onKeyPress }) => {
  useHotkeys(keyboardKey, () => onKeyPress());

  return <></>;
};

export default HotKeyPressAction;

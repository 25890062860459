import React, { useState } from "react";
import CustomModal from "@components/CustomModal";

const AddOrEditModal = ({ isEdit, title, component, formInitialData, isRenew, formComponent, callBack }) => {
  const Form = formComponent;
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <div onClick={showModal}>{component}</div>

      <CustomModal title={title} visible={visible} onCancel={handleClose} isShortcutEnable={!isEdit} showModal={showModal}>
        <Form handleClose={handleClose} data={formInitialData} isEdit={isEdit} isRenew={isRenew} callBack={callBack} />
      </CustomModal>
    </div>
  );
};

export default AddOrEditModal;

/* eslint-disable no-unused-vars */
import { map, remove } from "lodash";
import {
  ADD_POLICYTYPE,
  ADD_POLICYTYPE_SUCCESS,
  ADD_POLICYTYPE_FAILURE,
  EDIT_POLICYTYPE,
  EDIT_POLICYTYPE_SUCCESS,
  EDIT_POLICYTYPE_FAILURE,
  REMOVE_POLICYTYPE,
  REMOVE_POLICYTYPE_SUCCESS,
  REMOVE_POLICYTYPE_FAILURE,
  GET_POLICYTYPES,
  GET_POLICYTYPES_SUCCESS,
  GET_POLICYTYPES_FAILURE,
  HENDLE_PAGINATION_POLICYTYPE,
  HENDLE_SEARCH_POLICYTYPE
} from "../action/actionType";

let initialState = {
  //AGENCY
  manipulatePolicyTypeLoading: false,
  manipulatePolicyTypeError: null,
  removePolicyTypeLoading: false,
  removePolicyTypeError: null,

  policyTypes: [],
  fetchingPolicyTypesLoading: false,
  fetchingPolicyTypeError: null,
  totalRecord: 0,
  error: null,
  searchValue: null
};

const policyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD POLICYTYPE
    case ADD_POLICYTYPE:
      return {
        ...state,
        manipulatePolicyTypeLoading: true
      };
    case ADD_POLICYTYPE_SUCCESS:
      return {
        ...state,
        manipulatePolicyTypeLoading: false,
        policyTypes: [action.payload, ...state.policyTypes]
      };
    case ADD_POLICYTYPE_FAILURE:
      return {
        ...state,
        manipulatePolicyTypeLoading: false,
        cyError: action.payload.error
      };

    //edit POLICYTYPE
    case EDIT_POLICYTYPE:
      return {
        ...state,
        manipulatePolicyTypeLoading: true
      };
    case EDIT_POLICYTYPE_SUCCESS:
      let policyTypes = map(state.policyTypes, agency => {
        if (agency.id === action.payload.id) {
          return action.payload;
        } else {
          return agency;
        }
      });
      return {
        ...state,
        manipulatePolicyTypeLoading: false,
        policyTypes: policyTypes
      };

    case EDIT_POLICYTYPE_FAILURE:
      return {
        ...state,
        manipulatePolicyTypeLoading: false,
        manipulatePolicyTypeError: action.payload.error
      };

    //remove POLICYTYPE
    case REMOVE_POLICYTYPE:
      return {
        ...state,
        removePolicyTypeLoading: true
      };
    case REMOVE_POLICYTYPE_SUCCESS:
      remove(state.policyTypes, { id: action.payload });
      return {
        ...state,
        removePolicyTypeLoading: false
      };

    case REMOVE_POLICYTYPE_FAILURE:
      return {
        ...state,
        removePolicyTypeLoading: false,
        removePolicyTypeError: action.payload.error
      };
    //get POLICYTYPE
    case GET_POLICYTYPES:
      return {
        ...state,
        fetchingPolicyTypesLoading: true
      };
    case GET_POLICYTYPES_SUCCESS:
      return {
        ...state,
        policyTypes: action.payload.policyTypes,
        searchValue: action.payload.searchValue,
        fetchingPolicyTypesLoading: false
      };
    case GET_POLICYTYPES_FAILURE:
      return {
        ...state,
        fetchingPolicyTypesLoading: true,
        fetchingPolicyTypeError: action.payload.error
      };
    case HENDLE_PAGINATION_POLICYTYPE:
      return {
        ...state,
        totalRecord: action.payload
      };

    default:
      return state;
  }
};

export default policyTypeReducer;

import React, { useState } from "react";
import { Table, Tooltip, Card, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { deleteClient } from "@redux/action";
import Confirmation from "@components/Confirmation";
import ClientsAddOrEditModal from "@components/Clients/ClientsAddOrEditModal";
import UsernameAvatar from "@components/UsernameAvatar";
import { isMobile } from "react-device-detect";

const ClientsTable = ({ data }) => {
  // const dispatch = useDispatch();

  const {
    loading,
    removeLoading,
    user: { is_master_access }
  } = useSelector(({ client, auth }) => {
    return {
      loading: client.fetchingClientLoading,
      removeLoading: client.removeClientLoading,
      user: auth.userData
    };
  });

  // useEffect(() => {
  //   !data.length && dispatch(getClient(1, 30));
  // }, [dispatch, data]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      filteredValue: filteredInfo.name || null,
      showSorterTooltip: false,
      render(text, record, index) {
        return {
          children: (
            <Card style={{ padding: "10px", fontSize: "12px", width: "100%", border: "transparent" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <div style={{ fontWeight: "700" }}>{`${record.name}`}</div>
                <div>{record.address}</div>
                {record.mobile ? <a href={`tel:+${record.mobile}`}>{`+${record.mobile}`}</a> : ""}
                {record.phone ? <a href={`tel:+${record.phone}`}>{`+${record.phone}`}</a> : ""}
                {record.refer_name ? <div style={{ fontSize: "10px" }}>{`Ref: ${record.refer_name}`}</div> : ""}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {record.acc_no ? <div style={{ fontWeight: "600" }}>[{record.acc_no}]</div> : <div />}
                  <Space size="middle">
                    <ClientsAddOrEditModal component={<EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />} isEdit={true} data={record} />
                    {is_master_access && (
                      <Confirmation
                        title="Are you sure to delete this?"
                        dispatchActionMethod={deleteClient}
                        loading={removeLoading}
                        data={record}
                        component={<DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />}
                      />
                    )}
                  </Space>
                </div>
              </Space>
            </Card>
          )
        };
      },
      responsive: ["xs"]
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // ...getColumnSearchProps("name"),
      filteredValue: filteredInfo.name || null,
      // sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      render(text, record) {
        return {
          children: <div style={{ whiteSpace: "break-spaces", fontSize: "13px" }}>{text}</div>
        };
      },
      responsive: ["md"]
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      filteredValue: filteredInfo.address || null,
      sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,
      ellipsis: true,
      render(text, record) {
        return {
          children: <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>{text}</div>
        };
      },
      responsive: ["md"]
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      width: "140px",
      // ...getColumnSearchProps("mobile"),
      filteredValue: filteredInfo.mobile || null,
      // sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      render(text, record) {
        return {
          children: (
            <div>
              {text ? (
                <Tooltip placement="bottom" title={`+${text}`}>
                  <a href={`tel:+${text}`}>{`+${text}`}</a>{" "}
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          )
        };
      },
      sortOrder: sortedInfo.columnKey === "mobile" && sortedInfo.order,
      ellipsis: true,
      responsive: ["md"]
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "140px",
      // ...getColumnSearchProps("phone"),
      filteredValue: filteredInfo.phone || null,
      // sorter: (a, b) => a.phone.localeCompare(b.phone),
      render(text, record) {
        return {
          children: (
            <div>
              {text ? (
                <Tooltip placement="bottom" title={`+${text}`}>
                  <a href={`tel:+${text}`}>{`+${text}`}</a>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          )
        };
      },
      sortOrder: sortedInfo.columnKey === "phone" && sortedInfo.order,
      ellipsis: true,
      responsive: ["md"]
    },
    {
      title: "Account No.",
      dataIndex: "acc_no",
      key: "acc_no",
      // ...getColumnSearchProps("acc_no"),
      filteredValue: filteredInfo.acc_no || null,
      // sorter: (a, b) => a.acc_no - b.acc_no,
      sortOrder: sortedInfo.columnKey === "acc_no" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      width: 150,
      responsive: ["md"]
    },
    {
      title: "Refer Name",
      dataIndex: "refer_name",
      key: "refer_name",
      filteredValue: filteredInfo.refer_name || null,
      // sorter: (a, b) => a.refer_name.localeCompare(b.refer_name),
      sortOrder: sortedInfo.columnKey === "refer_name" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      width: 170,
      render(text, record) {
        return {
          children: <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>{text}</div>
        };
      },
      responsive: ["md"]
    },
    {
      title: "",
      dataIndex: "updated_by",
      key: "updated_by",
      align: "center",
      width: "40px",

      render(text) {
        return {
          children: <UsernameAvatar username={text} />
        };
      },
      responsive: ["md"]
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      ellipsis: true,
      fixed: isMobile ? "unset" : "right",
      width: 80,
      render(text, record) {
        return {
          children: record ? (
            <Space className="action-buttons">
              <ClientsAddOrEditModal
                component={
                  <Tooltip title="Edit" placement="bottom">
                    <EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />
                  </Tooltip>
                }
                isEdit={true}
                data={record}
              />
              {is_master_access && (
                <Confirmation
                  title="Are you sure to delete this?"
                  dispatchActionMethod={deleteClient}
                  loading={removeLoading}
                  data={record}
                  component={
                    <Tooltip title="Delete" placement="bottom">
                      <DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />{" "}
                    </Tooltip>
                  }
                />
              )}
            </Space>
          ) : (
            ""
          )
        };
      },
      responsive: ["md"]
    }
  ];

  return (
    <Table
      bordered
      size="small"
      scroll={isMobile ? {} : { x: 1160 }}
      columns={columns}
      dataSource={data}
      onChange={handleChangeTable}
      pagination={{
        pageSizeOptions: ["8", "10", "20", "50", "100"],
        defaultPageSize: localStorage.getItem("clients-pagesize") || "8",
        showSizeChanger: true,
        onShowSizeChange: (current, size) => {
          localStorage.setItem("clients-pagesize", size);
        }
      }}
      loading={loading}
    />
  );
};

export default ClientsTable;

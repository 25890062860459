import React from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
// import { useSelector } from "react-redux";

import PublicRoute from "@components/RouteWrapper/PublicRoute/index";
import PrivateRoute from "@components/RouteWrapper/PrivateRoute/index";
import AuthPages from "./auth";
import AppPages from "./app";

const Pages = () => {
  const isLoading = false;
  // const isLoading = useSelector((state) => !state.auth.user);

  if (isLoading) {
    return "Loading...";
  }

  return (
    <Router>
      <Switch>
        <PublicRoute path="/auth" component={AuthPages} />
        <PrivateRoute path="/app" component={AppPages} />
        <Redirect to="/auth" />
      </Switch>
    </Router>
  );
};

export default Pages;

/* eslint-disable no-unused-vars */
import { map, remove } from "lodash";
import {
  ADD_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  EDIT_COMPANY,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
  REMOVE_COMPANY,
  REMOVE_COMPANY_SUCCESS,
  REMOVE_COMPANY_FAILURE,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  HENDLE_PAGINATION_COMPANY,
  HANDLE_SEARCH_COMPANY
} from "../action/actionType";

let initialState = {
  //AGENCY
  manipulateCompanyLoading: false,
  manipulateCompanyError: null,
  removeCompanyLoading: false,
  removeCompanyError: null,

  companies: [],
  fetchingCompaniesLoading: false,
  fetchingCompaniesError: null,

  error: null,

  searchValue: null
};

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    //ADD_COMPANY
    case ADD_COMPANY:
      return {
        ...state,
        manipulateCompanyLoading: true
      };
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        manipulateCompanyLoading: false,
        companies: [action.payload.agency, ...state.companies]
      };
    case ADD_COMPANY_FAILURE:
      return {
        ...state,
        manipulateCompanyLoading: false,
        cyError: action.payload.error
      };

    //EDIT_COMPANY
    case EDIT_COMPANY:
      return {
        ...state,
        manipulateCompanyLoading: true
      };
    case EDIT_COMPANY_SUCCESS:
      let companies = map(state.companies, company => {
        if (company.id === action.payload.id) {
          return action.payload;
        } else {
          return company;
        }
      });
      return {
        ...state,
        manipulateCompanyLoading: false,
        companies: companies
      };

    case EDIT_COMPANY_FAILURE:
      return {
        ...state,
        manipulateCompanyLoading: false,
        manipulateCompanyError: action.payload.error
      };

    //REMOVE_COMPANY
    case REMOVE_COMPANY:
      return {
        ...state,
        removeCompanyLoading: true
      };
    case REMOVE_COMPANY_SUCCESS:
      remove(state.companies, { id: action.payload.agencyId });
      return {
        ...state,
        removeCompanyLoading: false
      };

    case REMOVE_COMPANY_FAILURE:
      return {
        ...state,
        removeCompanyLoading: false,
        removeCompanyError: action.payload.error
      };

    //GET_COMPANIES
    case GET_COMPANIES:
      return {
        ...state,
        fetchingCompaniesLoading: true
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        fetchingCompaniesLoading: false
      };
    case GET_COMPANIES_FAILURE:
      return {
        ...state,
        fetchingCompaniesLoading: true,
        fetchingCompaniesError: action.payload.error
      };

    case HENDLE_PAGINATION_COMPANY:
      return {
        ...state,
        totalRecord: action.payload
      };
    case HANDLE_SEARCH_COMPANY:
      return {
        ...state,
        searchValue: action.payload
      };

    default:
      return state;
  }
};

export default companiesReducer;

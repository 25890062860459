import React from "react";
import { Form, Input, Button, Row, Col, Select, DatePicker, message, Checkbox } from "antd";
import { addLicence, editLicence, renewalLicence } from "@redux/action";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import { licenceStatusList } from "@config/const";
import "./index.scss";
import moment from "moment";
import LicenceApplicationStatusTag from "../LicenceApplicationStatusTag";
import { pickBy, identity } from "lodash";
const { TextArea } = Input;

const rangeConfig = {
  rules: [
    {
      type: "date",
      required: !true,
      message: "Please select time!"
    }
  ]
};

const layout = {
  labelCol: {
    span: 16
  },
  wrapperCol: {
    span: 24
  }
};

const PoliciesForm = ({ isEdit, handleClose, data = {}, isRenew }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    id,
    name = "",
    address = "",
    licence_no = "",
    date_of_birth = "",
    computer_test_date = "",
    drive_test_date = "",
    application_status = "IN_PROCESS",
    mobile = "",
    issue_date = "",
    expire_date = "",
    remark = "",
    application_no = "",
    is_form_taken = false
  } = data;

  const { loading } = useSelector(({ licences }) => {
    return {
      loading: licences.manipulateLicenceLoading
    };
  });

  const onFinish = formData => {
    let {
      name = "",
      address = "",
      licence_no = "",
      date_of_birth = "",
      computer_test_date = "",
      drive_test_date = "",
      application_status = "",
      mobile = "",
      issue_date = "",
      expire_date = "",
      remark = "",
      application_no = "",
      is_form_taken = false
    } = form.getFieldsValue(true);

    mobile = mobile && mobile.match(/\d+/g).join("");

    const body = pickBy(
      {
        name,
        address,
        licence_no,
        date_of_birth: date_of_birth ? moment(date_of_birth).format("YYYY-MM-DD") : "",
        computer_test_date: computer_test_date ? moment(computer_test_date).format("YYYY-MM-DD HH:mm:ss") : "",
        drive_test_date: drive_test_date ? moment(drive_test_date).format("YYYY-MM-DD HH:mm:ss") : "",
        issue_date: issue_date ? moment(issue_date).format("YYYY-MM-DD") : "",
        expire_date: expire_date ? moment(expire_date).format("YYYY-MM-DD") : "",
        application_status,
        mobile,
        remark,
        application_no,
        is_form_taken
      },
      identity
    );

    isRenew
      ? dispatch(renewalLicence({ body, renewal_id: id }))
          .then(() => {
            handleClose();
            message.success("Licence added successfully.");
          })
          .catch(err => {
            message.error("something went wrong");
          })
      : isEdit
      ? dispatch(editLicence(id, body))
          .then(() => {
            handleClose();
            message.success("Licence updated successfully.");
          })
          .catch(err => {
            message.error("something went wrong");
          })
      : dispatch(addLicence(body))
          .then(() => {
            handleClose();
            message.success("Licence added successfully.");
          })
          .catch(err => {
            message.error("something went wrong");
          });
  };

  const handleChangeExpireDate = value => {
    form.setFieldsValue({ expire_date: value });
    form.setFieldsValue({ application_status: "COMPLETED" });
  };

  return (
    <Form
      {...layout}
      layout="vertical"
      onFinish={onFinish}
      key={"licence-form"}
      initialValues={{
        name,
        address,
        licence_no,
        date_of_birth: date_of_birth ? moment(date_of_birth) : "",
        computer_test_date: computer_test_date ? moment(computer_test_date) : "",
        drive_test_date: drive_test_date ? moment(drive_test_date) : "",
        issue_date: issue_date ? moment(issue_date) : "",
        expire_date: expire_date ? moment(expire_date) : "",
        application_status,
        mobile,
        remark,
        application_no,
        is_form_taken: !!is_form_taken
      }}
      size="small"
      form={form}
    >
      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is required"
              }
            ]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="mobile"
            label="Mobile No."
            rules={[
              {
                required: true,
                message: "Mobile no. is required"
              }
            ]}
          >
            <InputMask className="ant-input" mask={"+\\91 (999) 999-9999"} autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Address is required"
              }
            ]}
          >
            <TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item name="application_no" label="Application No.">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="date_of_birth"
            label="Birth Date"
            {...rangeConfig}
            rules={[
              {
                required: true,
                message: "Birth date is required"
              }
            ]}
          >
            <DatePicker format="DD-MM-YYYY" className="w-full" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Status" name="application_status">
            <Select
              showSearch
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              disabled={isRenew}
              allowClear
            >
              {licenceStatusList.map((status, i) => {
                return (
                  <Select.Option key={status} value={status.value}>
                    <LicenceApplicationStatusTag application_status={status.value} text={status.label} />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="computer_test_date"
            label="Computer Test Date"
            {...rangeConfig}
            rules={[
              {
                required: true,
                message: "Computer test date is required"
              }
            ]}
          >
            <DatePicker showTime={{ defaultValue: moment("09:00:00", "HH:mm a"), minuteStep: 15 }} format="DD-MM-YYYY hh:mm a" className="w-full" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name="drive_test_date" label="Driving Test Date" {...rangeConfig}>
            <DatePicker
              showTime={{ defaultValue: moment("09:00:00", "HH:mm a"), minuteStep: 15 }}
              format="DD-MM-YYYY hh:mm a"
              className="w-full"
              onChange={() => {
                data.is_form_taken && form.setFieldsValue({ is_form_taken: false });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ display: "flex", alignItems: "center" }}>
          <Form.Item name="is_form_taken" valuePropName="checked">
            <Checkbox>Is form taken</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="licence_no"
            label="Licence No."
            // rules={[
            //   {
            //     type: "number",
            //     required: !true,
            //     message: "Licence No. is required"
            //   }
            // ]}
          >
            <Input disabled={isRenew} />
          </Form.Item>
        </Col>
      </Row>

      <Row wrap={true} gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name="issue_date" label="Issue Date" {...rangeConfig}>
            <DatePicker format="DD-MM-YYYY" className="w-full" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name="expire_date" label="Expire Date" {...rangeConfig}>
            <DatePicker format="DD-MM-YYYY" className="w-full" onChange={value => handleChangeExpireDate(value)} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Form.Item
            name="remark"
            label="Remark"
            rules={[
              {
                required: !true,
                message: "Remark is required"
              }
            ]}
          >
            <TextArea autoSize={{ minRows: 3, maxRows: 4 }} />
          </Form.Item>
        </Col>
      </Row>
      <div className="modal-actions">
        <Button size="small" onClick={handleClose} className="cancel-button" tabIndex="-1">
          Cancel
        </Button>
        <Button size="small" type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default PoliciesForm;

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

// Content - shows how tables can be integrated with any other pdf content

function headRows() {
  return [
    {
      sr: "No.",
      check: "",
      policy_type_name: "Policy Type",
      vehicle_number: "Vehicle No.",
      vehicle_make: "Vehicle Make",
      model: "Model Year",
      client_name: "Name",
      client_mobile: "Mob No.",
      start_date: "Start Date",
      end_date: "End Date",
      sum_insured: "Sum Ins.",
      premium: "Premium"
    }
  ];
}

function bodyRows(reportData) {
  var body = [];

  reportData.forEach((policy, i) => {
    body.push({
      sr: i + 1,
      check: "[  ]",
      policy_type_name: policy.policy_type_name,
      vehicle_number: policy.vehicle_number,
      vehicle_make: policy.vehicle_make,
      model: policy.model,
      client_name: policy.client_name,
      client_mobile: policy.client_mobile,
      start_date: moment(policy.start_date).format("DD-MM-YYYY"),
      end_date: moment(policy.end_date).format("DD-MM-YYYY"),
      sum_insured: policy.sum_insured,
      premium: policy.premium
    });
  });

  return body;
}

export function renewalReportPdf({ reportData }) {
  if (reportData.length) {
    if (reportData == null) return;
    var doc = new jsPDF("l", "mm", "a4");

    doc.setFontSize(14);
    doc.text(`Renewal Policies (${moment().format("MMM YYYY")})`, 100, 15);
    doc.setFontSize(11);
    doc.setTextColor(100);

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    doc.text(`Total : ${reportData.length} policies`, 14, 20);
    doc.text(`${moment().format("DD-MM-YYYY")}`, 263, 20);
    doc.autoTable({
      head: headRows(),
      body: bodyRows(reportData),
      startY: 25,
      styles: {
        fontSize: 8
      },
      columnStyles: {
        1: { cellWidth: 10 },
        10: { halign: "right" },
        11: { halign: "right" }
      }
      // showHead: "firstPage"
    });

    doc.save(`Renewal Policies (${moment().format("MMM YYYY")}).pdf`);
  } else {
    alert("There isn't any data to generate report!");
  }
}

import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector(({ auth }) => {
    return {
      user: auth.userData
    };
  });

  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return <Component {...props} />;
        }
        const path = (props.location.state || {}).from || "/app/dashboard";
        return <Redirect to={path} />;
      }}
    />
  );
};

export default PublicRoute;

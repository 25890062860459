/* eslint-disable no-unused-vars */
import { map, remove } from "lodash";
import {
  ADD_POLICY,
  ADD_POLICY_SUCCESS,
  ADD_POLICY_FAILURE,
  EDIT_POLICY,
  EDIT_POLICY_SUCCESS,
  EDIT_POLICY_FAILURE,
  REMOVE_POLICY,
  REMOVE_POLICY_SUCCESS,
  REMOVE_POLICY_FAILURE,
  GET_POLICIES,
  GET_POLICIES_SUCCESS,
  GET_POLICIES_FAILURE,
  HENDLE_PAGINATION_POLICY,
  HANDLE_SEARCH_POLICY,
  LOCAL_ADD_POLICY_BY_SOCKET,
  LOCAL_UPDATE_POLICY_BY_SOCKET,
  LOCAL_DELETE_POLICY_BY_SOCKET,
  ADD_POLICY_RENEWAL,
  ADD_POLICY_RENEWAL_SUCCESS,
  ADD_POLICY_RENEWAL_FAILURE,
  LOCAL_UPDATE_POLICY_RENEWAL_BY_SOCKET,
  GET_VEHICLE_NAMES,
  GET_VEHICLE_NAMES_SUCCESS,
  GET_VEHICLE_NAMES_FAILURE,
  LOCAL_ADD_VEHICLE_NAME_BY_SOCKET,
  ADD_VEHICLE_NAME,
  ADD_VEHICLE_NAME_SUCCESS,
  ADD_VEHICLE_NAME_FAILURE,
  GET_POLICIES_BY_SEARCH_TEXT,
  GET_POLICIES_BY_SEARCH_TEXT_SUCCESS,
  GET_POLICIES_BY_SEARCH_TEXT_FAILURE,
  UPDATE_POLICY_FROM_CLIENT
} from "../action/actionType";

let initialState = {
  manipulatePolicyLoading: false,
  manipulatePolicyError: null,
  removePolicyLoading: false,
  removePolicyError: null,

  policies: [],
  fetchingPoliciesLoading: false,
  fetchingPoliciesError: null,

  searchPolicies: [],
  fetchingSearchPoliciesLoading: false,
  fetchingSearchPoliciesError: null,

  vehicleNames: [],
  fetchingVehicleNamesLoading: false,
  fetchingVehicleNamesError: null,

  manipulateVehicleNameLoading: false,
  manipulateVehicleNameError: null,

  error: null,
  totalRecord: 0,
  searchValue: {},
  isRedirect: false
};

const policyReducer = (state = initialState, action) => {
  switch (action.type) {
    //TODO: ADD POLICY
    case ADD_POLICY:
      return {
        ...state,
        manipulatePolicyLoading: true
      };
    case ADD_POLICY_SUCCESS:
      return {
        ...state,
        manipulatePolicyLoading: false,
        policies: action.payload.renewal_policy_id ? state.policy.filter(policy => policy.id !== action.payload.policy.id) : [action.payload.policy, ...state.policies]
      };

    case LOCAL_ADD_POLICY_BY_SOCKET:
      return {
        ...state,
        policies: [action.payload, ...state.policies]
      };

    case ADD_POLICY_FAILURE:
      return {
        ...state,
        manipulatePolicyLoading: false,
        cyError: action.payload.error
      };

    case ADD_POLICY_RENEWAL:
      return {
        ...state,
        manipulatePolicyLoading: true
      };
    case ADD_POLICY_RENEWAL_SUCCESS:
      const updatedPolicies = state.policies.map(policy => {
        if (policy.id === action.payload.renewal_policy_id) {
          return Object.assign(policy, { status: 1 });
        } else {
          return policy;
        }
      });
      return {
        ...state,
        manipulatePolicyLoading: false,
        policies: [action.payload.policy, ...updatedPolicies]
      };

    case ADD_POLICY_RENEWAL_FAILURE:
      return {
        ...state,
        manipulatePolicyLoading: false
      };

    //EDIT policy
    case EDIT_POLICY:
      return {
        ...state,
        manipulatePolicyLoading: true
      };
    case EDIT_POLICY_SUCCESS:
      let policies = map(state.policies, policy => {
        if (policy.id === action.payload.policy.id) {
          return action.payload.policy;
        } else {
          return policy;
        }
      });
      return {
        ...state,
        manipulatePolicyLoading: false,
        policies: policies
      };

    case LOCAL_UPDATE_POLICY_RENEWAL_BY_SOCKET:
      const updatedPolicy = map(state.policies, policy => {
        if (policy.id === action.payload.id) {
          return Object.assign(policy, { status: 1 });
        } else {
          return policy;
        }
      });

      return {
        ...state,
        manipulatePolicyLoading: false,
        policies: updatedPolicy
      };

    case LOCAL_UPDATE_POLICY_BY_SOCKET:
      let localPolicies = map(state.policies, policy => {
        if (policy.id === action.payload.id) {
          return action.payload;
        } else {
          return policy;
        }
      });

      return {
        ...state,
        policies: localPolicies
      };

    case EDIT_POLICY_FAILURE:
      return {
        ...state,
        manipulatePolicyLoading: false,
        manipulatePolicyError: action.payload.error
      };

    //REMOVE policy
    case REMOVE_POLICY:
      return {
        ...state,
        removePolicyLoading: true
      };
    case REMOVE_POLICY_SUCCESS:
      remove(state.policies, { id: action.payload.policyId });
      return {
        ...state,
        removePolicyLoading: false
      };

    case LOCAL_DELETE_POLICY_BY_SOCKET:
      remove(state.policies, { id: action.payload.id });
      return {
        ...state
      };

    case REMOVE_POLICY_FAILURE:
      return {
        ...state,
        removePolicyLoading: false,
        removePolicyError: action.payload.error
      };

    //GET policies
    case GET_POLICIES:
      return {
        ...state,
        fetchingPoliciesLoading: true,
        policies: []
        // isRedirect: false,
        // policies: action.payload ? [] : [...state.policies]
      };
    case GET_POLICIES_SUCCESS:
      return {
        ...state,
        policies: [...action.payload.policies],
        fetchingPoliciesLoading: false
      };
    case GET_POLICIES_FAILURE:
      return {
        ...state,
        fetchingPoliciesLoading: true,
        fetchingPoliciesError: action.payload.error
      };

    case GET_POLICIES_BY_SEARCH_TEXT:
      return {
        ...state,
        fetchingSearchPoliciesLoading: true,
        searchPolicies: []
      };
    case GET_POLICIES_BY_SEARCH_TEXT_SUCCESS:
      return {
        ...state,
        searchPolicies: [...action.payload.policies],
        fetchingSearchPoliciesLoading: false
      };
    case GET_POLICIES_BY_SEARCH_TEXT_FAILURE:
      return {
        ...state,
        fetchingSearchPoliciesLoading: false,
        fetchingSearchPoliciesError: action.payload.error
      };

    case HENDLE_PAGINATION_POLICY:
      return {
        ...state,
        totalRecord: action.payload
      };

    case HANDLE_SEARCH_POLICY:
      const updatedObj = action.payload.isRedirect ? { ...action.payload.where } : Object.assign(state.searchValue, action.payload.where);
      return {
        ...state,
        searchValue: { ...updatedObj },
        isRedirect: action.payload.isRedirect
      };
    case GET_VEHICLE_NAMES:
      return {
        ...state,
        fetchingVehicleNamesLoading: true
      };
    case GET_VEHICLE_NAMES_SUCCESS:
      return {
        ...state,
        vehicleNames: [...action.payload],
        fetchingVehicleNamesLoading: false
      };
    case GET_VEHICLE_NAMES_FAILURE:
      return {
        ...state,
        fetchingVehicleNamesLoading: false,
        fetchingVehicleNamesError: action.payload.error
      };
    case LOCAL_ADD_VEHICLE_NAME_BY_SOCKET:
      return {
        ...state,
        vehicleNames: [action.payload, ...state.vehicleNames]
      };
    case ADD_VEHICLE_NAME:
      return {
        ...state,
        manipulateVehicleNameLoading: true
      };
    case ADD_VEHICLE_NAME_SUCCESS:
      return {
        ...state,
        manipulateVehicleNameLoading: false,
        vehicleNames: [action.payload, ...state.vehicleNames]
      };
    case ADD_VEHICLE_NAME_FAILURE:
      return {
        ...state,
        manipulateVehicleNameLoading: false,
        manipulateVehicleNameError: action.payload.error
      };
    case UPDATE_POLICY_FROM_CLIENT:
      let policyList = map(state.policies, policy => {
        if (policy.client_id === action.payload.client.id) {
          return { ...policy, client_name: action.payload.client.name, client_mobile: action.payload.client.mobile };
        } else {
          return policy;
        }
      });
      return {
        ...state,
        policies: policyList
      };
    default:
      return state;
  }
};

export default policyReducer;

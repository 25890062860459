import React from "react";
import "./index.scss";
import PolicyTypeForm from "@components/PolicyTypes/PolicyTypesForm";
import AddOrEditModal from "@components/AddOrEditModal";

const PolicyTypesAddOrEditModal = ({ isEdit, component, data }) => {
  return <AddOrEditModal component={component} formInitialData={data} isEdit={isEdit} title={isEdit ? "Edit policy type" : "Add policy type"} formComponent={PolicyTypeForm} />;
};

export default PolicyTypesAddOrEditModal;

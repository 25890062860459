import React from "react";
import { Drawer } from "antd";
import Sider from "./sider";

import styles from "./index.module.scss";
import { isMobile } from "react-device-detect";

const Sidebar = ({ isSidebarVisible, onClose }) => {
  return (
    <>
      {isMobile ? (
        <Drawer width={260} placement="left" closable={false} visible={isSidebarVisible} onClose={onClose} className={styles.drawer}>
          <Sider onClose={onClose} />
        </Drawer>
      ) : (
        <Sider onClose={onClose} />
      )}
    </>
  );
};

export default Sidebar;

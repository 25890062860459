import React, { useState } from "react";
import { Table, Tooltip, Card, Space } from "antd";
import { RetweetOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import PolicyTypeTag from "@components/PolicyTypes/PolicyTypeTag";
import PoliciesAddOrEditModal from "@components/Policies/PoliciesAddOrEditModal";

// import { getPolicyType } from "@redux/action";
import moment from "moment";
import numeral from "numeral";
import UsernameAvatar from "@components/UsernameAvatar";
import { isMobile } from "react-device-detect";
const PoliciesTable = ({ data }) => {
  // const dispatch = useDispatch();
  const { loading, policyTypes } = useSelector(({ renewalPolicy, policyType }) => {
    return {
      data: renewalPolicy.renewalPolicies,
      loading: renewalPolicy.fetchingRenewalPoliciesLoading,
      policyTypes: policyType.policyTypes
    };
  });

  // useEffect(() => {
  //   !policyTypes.length && dispatch(getPolicyType());
  // }, [dispatch, policyTypes]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      showSorterTooltip: false,
      render(text, record, index) {
        let data = { ...record };
        const start_date = moment(moment(moment(data.start_date).format("YYYY-MM-DD"), "YYYY-MM-DD").add(12, "M").format("YYYY-MM-DD"));
        const end_date = moment(moment(moment(data.end_date).format("YYYY-MM-DD"), "YYYY-MM-DD").add(12, "M").format("YYYY-MM-DD"));
        data = Object.assign(data, { renewal_policy_id: data.id, sum_insured: 0, policy_number: "", premium: 0, start_date, end_date });

        const gst = (record.premium * (100 / (100 + process.env.REACT_APP_GST_RATE))).toFixed(1);
        const inHandAmount = record.premium - gst;
        return {
          children: (
            <Card style={{ padding: "10px", fontSize: "12px", width: "100%", border: "transparent" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {/* <div style={{ fontWeight: "700" }}>{index + 1})</div> */}
                  <div>
                    <PolicyTypeTag color={record.policy_type_color} text={record.policy_type_name} />
                  </div>
                </div>
                <div style={{ fontWeight: "700" }}>{record.client_name}</div>
                {record.client_mobile ? <a href={`tel:+${record.client_mobile}`}>{`+${record.client_mobile}`}</a> : ""}
                <Space>
                  <div>{moment(record.start_date).format("DD-MM-YYYY")}</div> <span> - </span>{" "}
                  <div>{`${moment(record.end_date).format("DD-MM-YYYY")} (${moment(record.end_date).endOf("day").fromNow()})`}</div>
                </Space>
                {record.vehicle_number && <div>{record.vehicle_number}</div>}
                {record.vehicle_make && <div>{`${record.vehicle_make} ${record.model ? `[${record.model}]` : ""}`}</div>}

                <Space>
                  <div style={{ fontWeight: "700" }}>Sum Ins.: </div>
                  {`₹ ${record.sum_insured ? numeral(record.sum_insured).format("0,0") : " 0"}`}
                </Space>
                <Space>
                  <div style={{ fontWeight: "700" }}>Premium: </div>
                  <div>
                    {`₹ ${record.premium ? numeral(record.premium).format("0,0") : " 0"}  | ${
                      record.fees ? `Fee: ₹ ${record.fees}` : `Com: ₹ ${numeral((inHandAmount * (record.percentage || 0)) / 100).format("0")} (${record.percentage || 0}%)`
                    }
                    `}
                  </div>
                </Space>
              </Space>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space size="middle" className="action-buttons">
                  <PoliciesAddOrEditModal component={<EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />} isEdit={true} data={record} />

                  <PoliciesAddOrEditModal component={<RetweetOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />} isEdit={true} isRenew={true} data={data} />
                </Space>
              </div>
            </Card>
          )
        };
      },
      responsive: ["xs"]
    },
    {
      title: "Policy Type",
      dataIndex: "policy_type_name",
      key: "policy_type_name",
      filters: policyTypes.map(p_type => {
        return {
          text: <PolicyTypeTag color={p_type.color} text={p_type.name} />,
          value: p_type.name
        };
      }),
      onFilter: (value, record) => record.policy_type_name.indexOf(value) === 0,
      filteredValue: filteredInfo.policy_type_name || null,
      sorter: (a, b) => a.policy_type_name.localeCompare(b.policy_type_name),
      sortOrder: sortedInfo.columnKey === "policy_type_name" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      width: "120px",
      render(text, record) {
        return {
          children: (
            <div>
              <PolicyTypeTag color={record.policy_type_color} text={text} />
            </div>
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Vehicle",
      dataIndex: "",
      key: "",
      filteredValue: filteredInfo.vehicle_number || null,
      sortOrder: sortedInfo.columnKey === "vehicle_number" && sortedInfo.order,
      width: "150px",
      ellipsis: true,
      render(text, record) {
        return {
          children: record.vehicle_number ? (
            <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>
              <div style={{ letterSpacing: "0.5px" }}>{record.vehicle_number} </div>
              <div>{`${record.vehicle_make} (${record.model})`}</div>
            </div>
          ) : (
            ""
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Name",
      dataIndex: "client_name",
      key: "client_name",
      filteredValue: filteredInfo.client_name || null,
      // sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      sortOrder: sortedInfo.columnKey === "client_name" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      width: "140px",
      render(text, record) {
        return {
          children: <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>{text}</div>
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Mob No.",
      dataIndex: "client_mobile",
      key: "client_mobile",
      filteredValue: filteredInfo.client_mobile || null,
      sortOrder: sortedInfo.columnKey === "client_mobile" && sortedInfo.order,
      ellipsis: true,
      width: "100px",
      render(text, record) {
        return {
          children: <div>{text ? <a href={`tel:+${text}`}>{`+${text}`}</a> : ""}</div>
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      sorter: (a, b) => moment(a.start_date).unix() - moment(b.start_date).unix(),
      showSorterTooltip: false,
      sortOrder: sortedInfo.columnKey === "start_date" && sortedInfo.order,
      ellipsis: true,
      width: "70px",

      render(text, record) {
        return {
          children: <div>{moment(text).format("DD-MM-YYYY")}</div>
        };
      },
      responsive: ["sm"]
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      showSorterTooltip: false,
      sortOrder: sortedInfo.columnKey === "end_date" && sortedInfo.order,
      ellipsis: true,
      width: "70px",

      render(text, record) {
        return {
          children: (
            <div>
              <div>{`${moment(text).format("DD-MM-YYYY")}`}</div>
              <div style={{ fontSize: "10px" }}>{`(${moment(text).endOf("day").fromNow()})`}</div>
            </div>
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Sum Ins.",
      dataIndex: "sum_insured",
      key: "sum_insured",
      align: "right",
      sorter: (a, b) => a.sum_insured - b.sum_insured,
      sortOrder: sortedInfo.columnKey === "sum_insured" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      width: "120px",

      render(text, record) {
        return {
          children: <div>{`₹ ${text ? numeral(text).format("0,0") : " 0"}`}</div>
        };
      },
      responsive: ["sm"]
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      align: "right",

      sorter: (a, b) => a.premium - b.premium,
      sortOrder: sortedInfo.columnKey === "premium" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      width: "100px",
      render(text, record) {
        const gst = (text * (100 / (100 + process.env.REACT_APP_GST_RATE))).toFixed(1);
        const inHandAmount = text - gst;
        return {
          children: (
            <div>
              <div>{`₹ ${text ? numeral(text).format("0,0") : " 0"}`}</div>
              {record.fees ? (
                <div style={{ fontSize: "11px" }}>{`Fee: ₹ ${record.fees}`}</div>
              ) : (
                <div style={{ fontSize: "11px" }}>{`Com: ₹ ${numeral((inHandAmount * (record.percentage || 0)) / 100).format("0")} (${record.percentage || 0}%)`}</div>
              )}
            </div>
          )
        };
      },
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "updated_by",
      key: "updated_by",
      align: "center",
      width: "40px",
      render(text) {
        return {
          children: <UsernameAvatar username={text} />
        };
      },
      responsive: ["sm"]
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      ellipsis: true,
      fixed: isMobile ? "unset" : "right",
      width: 30,
      render(text, record) {
        let data = { ...record };
        const start_date = moment(data.start_date).add(12, "M");
        const end_date = moment(data.end_date).add(12, "M");
        data = Object.assign(data, { isRenewal: true, renewal_policy_id: data.id, sum_insured: "", policy_number: "", premium: "", start_date, end_date });
        return {
          children: record ? (
            <Space className="action-buttons">
              <PoliciesAddOrEditModal
                component={
                  <Tooltip title="Renew" placement="bottom">
                    <RetweetOutlined style={{ fontSize: "16px", color: "#08c", cursor: "pointer" }} theme="outlined" />
                  </Tooltip>
                }
                isEdit={true}
                isRenew={true}
                data={data}
              />
            </Space>
          ) : (
            ""
          )
        };
      },
      responsive: ["sm"]
    }
  ];

  return <Table bordered size="small" columns={columns} scroll={isMobile ? {} : { x: 1160 }} dataSource={data} onChange={handleChangeTable} loading={loading} />;
};

export default PoliciesTable;

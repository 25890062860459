import React from "react";
import Section from "@components/Section";
import { Space, DatePicker, Input, Typography } from "antd";
import "./index.scss";
import RenewalPoliciesTable from "@components/RenewalPolicies/RenewalPoliciesTable";
import moment from "moment";
import DownloadCSV from "@components/GenerateCSV";
import { useDispatch, useSelector } from "react-redux";
import { getRenewalPolicies } from "@redux/action";
import PrintDropdown from "@components/PrintDropdown";
import { renewalReportPdf } from "@components/GeneratePDF/RenewalReportPdf";
import _ from "lodash";
import numeral from "numeral";
import { isMobile } from "react-device-detect";

const { Title } = Typography;

const Policies = () => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(({ renewalPolicy }) => {
    return {
      data: renewalPolicy.renewalPolicies,
      loading: renewalPolicy.fetchingRenewalPoliciesLoading
    };
  });

  // const [selectedDate, setSelectedDate] = useState(moment().format("MMMM YYYY"));
  const [searchText, setSearchText] = React.useState("");

  // useEffect(() => {
  //   dispatch(getRenewalPolicies({ sort_field: "end_date:asc", compare_field: "only_end_date", start_date, end_date, status: 0 }));
  // }, [selectedDate]); // eslint-disable-line

  const handleChangeSearchText = _.debounce(e => {
    setSearchText(
      e.target.value
        ? e.target.value
            .toLowerCase()
            .trim()
            .split(" ")
            .map(word => `(?=.*${word})`)
            .join("")
        : ""
    );
  }, 200);

  const handleSelectDate = (date, dateString) => {
    // setSelectedDate(moment(date).format("MMMM YYYY"));
    const start_date = moment(date, "MMMM YYYY").startOf("month").format("YYYY-MM-DD");
    const end_date = moment(date, "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    dispatch(getRenewalPolicies({ sort_field: "end_date:asc", compare_field: "only_end_date", start_date, end_date, status: 0 }));
  };

  const disabledDate = current => {
    return false;
    // return current && current < moment().endOf("day");
  };

  const handleMenuClick = e => {
    const { key } = e;

    key === "1" ? renewalReportPdf({ reportData: data }) : csvdataRender();
  };

  function csvdataRender() {
    const csvData = !!data.length
      ? data.map((policy, i) => {
          const sum_insured = "Sum Ins.";
          const start_date = "Start Date";
          const end_date = "End Date";
          const premium = "Premium";
          const vehicle_number = "Vehicle Number";
          const vehicle_make = "Vehicle Make";
          const model = "Model Year";
          const client_name = "Name";
          const client_mobile = "Mob No.";
          const policy_type_name = "Policy Type";

          return {
            [policy_type_name]: policy.policy_type_name ? policy.policy_type_name : "",
            [vehicle_number]: policy.vehicle_number ? policy.vehicle_number : "",
            [vehicle_make]: policy.vehicle_make ? policy.vehicle_make : "",
            [model]: policy.model ? policy.model : "",
            [client_name]: policy.client_name ? policy.client_name : "",
            [client_mobile]: policy.client_mobile ? policy.client_mobile : "",
            [start_date]: policy.start_date ? moment(policy.start_date).format("DD-MM-YYYY") : "",
            [end_date]: policy.end_date ? moment(policy.end_date).format("DD-MM-YYYY") : "",
            [sum_insured]: policy.sum_insured ? policy.sum_insured : 0,
            [premium]: policy.premium ? policy.premium : 0
          };
        })
      : [];

    DownloadCSV({ csvData, exportFileName: `Renewal Policies (${moment().format("MMM YYYY")})` });
  }
  let filteredData = data;

  if (searchText) {
    filteredData = filteredData.filter(client => {
      return (
        (client.policy_number && client.policy_number.toLowerCase().match(new RegExp(searchText))) ||
        (client.vehicle_number && client.vehicle_number.toLowerCase().match(new RegExp(searchText))) ||
        (client.client_name && client.client_name.toLowerCase().match(new RegExp(searchText))) ||
        (client.client_mobile && client.client_mobile.toLowerCase().match(new RegExp(searchText)))
      );
    });
  }

  return (
    <Section className="renewal-policies-page">
      <Space className="renewal-policies-table-toolbar">
        <Title className="title" level={3}>
          {`Renewal Policies ${!loading ? `(${numeral(data.length).format("0,0")})` : ""}`}
        </Title>
        <Space wrap size={isMobile ? "middle" : "small"}>
          <Input className="search-field" size="small" allowClear placeholder="Policy No., Veh. No., Client name..." onChange={e => handleChangeSearchText(e)} />
          <DatePicker
            className="datePicker-field"
            size="small"
            onChange={handleSelectDate}
            picker="month"
            format={"MMMM YYYY"}
            defaultValue={moment()}
            allowClear={false}
            disabledDate={disabledDate}
          />

          <PrintDropdown handleMenuSelect={handleMenuClick} className="print-dropdown" />
        </Space>
      </Space>

      <RenewalPoliciesTable data={filteredData} />
    </Section>
  );
};

export default Policies;

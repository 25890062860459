import {
  ADD_POLICY,
  ADD_POLICY_SUCCESS,
  ADD_POLICY_FAILURE,
  EDIT_POLICY,
  EDIT_POLICY_SUCCESS,
  EDIT_POLICY_FAILURE,
  REMOVE_POLICY,
  REMOVE_POLICY_SUCCESS,
  REMOVE_POLICY_FAILURE,
  GET_POLICIES,
  GET_POLICIES_SUCCESS,
  GET_POLICIES_FAILURE,
  HANDLE_SEARCH_POLICY,
  LOCAL_ADD_POLICY_BY_SOCKET,
  LOCAL_UPDATE_POLICY_BY_SOCKET,
  LOCAL_DELETE_POLICY_BY_SOCKET,
  ADD_POLICY_RENEWAL,
  ADD_POLICY_RENEWAL_SUCCESS,
  ADD_POLICY_RENEWAL_FAILURE,
  LOCAL_UPDATE_POLICY_RENEWAL_BY_SOCKET,
  GET_VEHICLE_NAMES,
  GET_VEHICLE_NAMES_SUCCESS,
  GET_VEHICLE_NAMES_FAILURE,
  LOCAL_ADD_VEHICLE_NAME_BY_SOCKET,
  ADD_VEHICLE_NAME,
  ADD_VEHICLE_NAME_SUCCESS,
  ADD_VEHICLE_NAME_FAILURE,
  GET_POLICIES_BY_SEARCH_TEXT,
  GET_POLICIES_BY_SEARCH_TEXT_SUCCESS,
  GET_POLICIES_BY_SEARCH_TEXT_FAILURE,
  UPDATE_POLICY_FROM_CLIENT
} from "./actionType";
import { generateQuery } from "@helpers";
import axios from "@config/axios";
import { updateRenewalPolicySuccess } from "./renewalPolicy.action";

//ADD policy
const createPolicy = payload => {
  return {
    type: ADD_POLICY,
    payload: payload
  };
};
const createPolicySuccess = payload => {
  return {
    type: ADD_POLICY_SUCCESS,
    payload: payload
  };
};
const createPolicyFailure = payload => {
  return {
    type: ADD_POLICY_FAILURE,
    payload: payload
  };
};
export const addPolicy = body => async dispatch => {
  dispatch(createPolicy());
  return await axios
    .post(`/create-policy`, body)
    .then(res => {
      dispatch(createPolicySuccess({ policy: res.data.data.policy, renewal_policy_id: body.renewal_policy_id }));
    })
    .catch(err => {
      dispatch(createPolicyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      throw new Error(err);
    });
};

//UPDATE policy
const updatePolicy = payload => {
  return {
    type: EDIT_POLICY,
    payload: payload
  };
};
const updatePolicySuccess = payload => {
  return {
    type: EDIT_POLICY_SUCCESS,
    payload: payload
  };
};
const updatePolicyFailure = payload => {
  return {
    type: EDIT_POLICY_FAILURE,
    payload: payload
  };
};
export const editPolicy = (id, body) => async dispatch => {
  dispatch(updatePolicy());
  return await axios
    .put(`/update-policy/${id}`, body)
    .then(res => {
      dispatch(updatePolicySuccess({ policy: res.data.data.policy }));
      dispatch(updateRenewalPolicySuccess({ policy: res.data.data.policy }));
    })
    .catch(err => {
      dispatch(updatePolicyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      throw new Error(err);
    });
};

//REMOVE policy
const removePolicy = payload => {
  return {
    type: REMOVE_POLICY,
    payload: payload
  };
};
const removePolicySuccess = payload => {
  return {
    type: REMOVE_POLICY_SUCCESS,
    payload: payload
  };
};
const removePolicyFailure = payload => {
  return {
    type: REMOVE_POLICY_FAILURE,
    payload: payload
  };
};
export const deletePolicy = id => async dispatch => {
  dispatch(removePolicy());
  return await axios
    .delete(`/delete-policy/${id}`)
    .then(res => {
      dispatch(removePolicySuccess({ policyId: id }));
    })
    .catch(err => {
      dispatch(removePolicyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

//FETCH policies
const fetchPolicies = payload => {
  return {
    type: GET_POLICIES,
    payload: payload
  };
};
const fetchPoliciesSuccess = payload => {
  return {
    type: GET_POLICIES_SUCCESS,
    payload: payload
  };
};
const fetchPoliciesFailure = payload => {
  return {
    type: GET_POLICIES_FAILURE,
    payload: payload
  };
};

const handleSearchValue = payload => {
  return {
    type: HANDLE_SEARCH_POLICY,
    payload: payload
  };
};

export const handleSearchValueToRedirect = (where, isRedirect) => {
  return {
    type: HANDLE_SEARCH_POLICY,
    payload: { where: where, isRedirect: isRedirect }
  };
};

export const getPolicies =
  (where = {}, isSearch, isRedirect) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchPolicies(isSearch));
    axios
      .get(`/get-all-policy?${filterdWhere}`)
      .then(res => {
        dispatch(fetchPoliciesSuccess({ policies: res.data.data.policies }));
        dispatch(handleSearchValue({ where, isRedirect }));
      })
      .catch(err => {
        dispatch(fetchPoliciesFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

//FETCH policies
const fetchPoliciesBySearchText = payload => {
  return {
    type: GET_POLICIES_BY_SEARCH_TEXT,
    payload: payload
  };
};
const fetchPoliciesBySearchTextSuccess = payload => {
  return {
    type: GET_POLICIES_BY_SEARCH_TEXT_SUCCESS,
    payload: payload
  };
};
const fetchPoliciesBySearchTextFailure = payload => {
  return {
    type: GET_POLICIES_BY_SEARCH_TEXT_FAILURE,
    payload: payload
  };
};

export const getPoliciesBySearchText =
  (where = {}, isSearch, isRedirect) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchPoliciesBySearchText(isSearch));
    axios
      .get(`/get-all-policy?${filterdWhere}`)
      .then(res => {
        dispatch(fetchPoliciesBySearchTextSuccess({ policies: res.data.data.policies }));
      })
      .catch(err => {
        dispatch(fetchPoliciesBySearchTextFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

const createRenewalPolicy = payload => {
  return {
    type: ADD_POLICY_RENEWAL,
    payload: payload
  };
};
const createRenewalPolicySuccess = payload => {
  return {
    type: ADD_POLICY_RENEWAL_SUCCESS,
    payload: payload
  };
};
const createRenewalPolicyFailure = payload => {
  return {
    type: ADD_POLICY_RENEWAL_FAILURE,
    payload: payload
  };
};
export const addPolicyRenewal = (body, handleSuccess) => async dispatch => {
  dispatch(createRenewalPolicy());
  return await axios
    .post(`/create-policy`, body)
    .then(res => {
      dispatch(createRenewalPolicySuccess({ policy: res.data.data.policy, renewal_policy_id: body.renewal_policy_id }));

      handleSuccess && handleSuccess();
    })
    .catch(err => {
      dispatch(createRenewalPolicyFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
    });
};

export const localAddPolicyBySocket = payload => {
  return {
    type: LOCAL_ADD_POLICY_BY_SOCKET,
    payload: payload
  };
};

export const localUpdatePolicyBySocket = payload => {
  return {
    type: LOCAL_UPDATE_POLICY_BY_SOCKET,
    payload: payload
  };
};

export const localDeletePolicyBySocket = payload => {
  return {
    type: LOCAL_DELETE_POLICY_BY_SOCKET,
    payload: payload
  };
};

export const localPolicyRenewalBySocket = payload => {
  return {
    type: LOCAL_UPDATE_POLICY_RENEWAL_BY_SOCKET,
    payload: payload
  };
};

//FETCH vehicle name
const fetchVehicleNames = payload => {
  return {
    type: GET_VEHICLE_NAMES,
    payload: payload
  };
};
const fetchVehicleNamesSuccess = payload => {
  return {
    type: GET_VEHICLE_NAMES_SUCCESS,
    payload: payload
  };
};
const fetchVehicleNamesFailure = payload => {
  return {
    type: GET_VEHICLE_NAMES_FAILURE,
    payload: payload
  };
};

export const getVehicleNames =
  (where = {}, isSearch, isRedirect) =>
  async dispatch => {
    let filterdWhere = generateQuery(where);
    dispatch(fetchVehicleNames(isSearch));
    axios
      .get(`/get-all-vehicle-names?${filterdWhere}`)
      .then(res => {
        dispatch(fetchVehicleNamesSuccess(res.data.data.vehicleNames));
        dispatch(fetchVehicleNamesFailure({ where, isRedirect }));
      })
      .catch(err => {
        dispatch(fetchVehicleNamesFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      });
  };

export const localAddVehicleNameBySocket = payload => {
  return {
    type: LOCAL_ADD_VEHICLE_NAME_BY_SOCKET,
    payload: payload
  };
};

const createVehicleName = payload => {
  return {
    type: ADD_VEHICLE_NAME,
    payload: payload
  };
};
const createVehicleNameSuccess = payload => {
  return {
    type: ADD_VEHICLE_NAME_SUCCESS,
    payload: payload
  };
};
const createVehicleNameFailure = payload => {
  return {
    type: ADD_VEHICLE_NAME_FAILURE,
    payload: payload
  };
};
export const addVehicleName = body => async dispatch => {
  dispatch(createVehicleName());
  return await axios
    .post(`/create-vehicle-name`, body)
    .then(res => {
      dispatch(createVehicleNameSuccess(res.data.data.vehicleName));
      return res.data.data.vehicleName;
    })
    .catch(err => {
      dispatch(createVehicleNameFailure({ error: err.response ? err.response.data.message : "Something went wrong!" }));
      throw new Error(err);
    });
};

export const updatePolicyFromClient = client => {
  return {
    type: UPDATE_POLICY_FROM_CLIENT,
    payload: client
  };
};

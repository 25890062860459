export const defaultRowsPerPage = 15;

export const colorList = [
  {
    label: "Red",
    value: "red"
  },
  {
    label: "Blue",
    value: "blue"
  },
  {
    label: "Magenta",
    value: "magenta"
  },
  {
    label: "Volcano",
    value: "volcano"
  },
  {
    label: "Orange",
    value: "orange"
  },
  {
    label: "Gold",
    value: "gold"
  },
  {
    label: "Lime",
    value: "lime"
  },
  {
    label: "Green",
    value: "green"
  },
  {
    label: "Cyan",
    value: "cyan"
  },
  {
    label: "Geekblue",
    value: "geekblue"
  },
  {
    label: "Purple",
    value: "purple"
  }
];

export const licenceStatusList = [
  {
    label: "In Process",
    value: "IN_PROCESS"
  },
  {
    label: "Completed",
    value: "COMPLETED"
  }
];

export const smsTemplateList = [
  {
    label: "General Policy Reminder",
    value: "GENERAL_POLICY_REMINDER"
  },
  {
    label: "Motor Policy Reminder",
    value: "MOTOR_POLICY_REMINDER"
  }
];

/* eslint-disable no-unused-vars */
import { map, remove } from "lodash";
import moment from "moment";
import {
  ADD_RENEWAL_POLICY,
  ADD_RENEWAL_POLICY_SUCCESS,
  ADD_RENEWAL_POLICY_FAILURE,
  UPDATE_RENEWAL_POLICY,
  UPDATE_RENEWAL_POLICY_SUCCESS,
  UPDATE_RENEWAL_POLICY_FAILURE,
  DELETE_RENEWAL_POLICY,
  DELETE_RENEWAL_POLICY_SUCCESS,
  DELETE_RENEWAL_POLICY_FAILURE,
  GET_RENEWAL_POLICIES,
  GET_RENEWAL_POLICIES_SUCCESS,
  GET_RENEWAL_POLICIES_FAILURE,
  HENDLE_SEARCH_RENEWAL_POLICY,
  LOCAL_UPDATE_RENEWAL_POLICY_BY_SOCKET,
  UPDATE_RENEWAL_POLICY_FROM_CLIENT
} from "../action/actionType";

let initialState = {
  manipulateRenewalPolicyLoading: false,
  manipulateRenewalPolicyError: null,
  removeRenewalPolicyLoading: false,
  removeRenewalPolicyError: null,

  renewalPolicies: [],
  fetchingRenewalPoliciesLoading: false,
  fetchingRenewalPoliciesError: null,

  error: null,
  totalRecord: 0,
  searchValue: {},
  isRedirect: false
};

const renewalPolicy = (state = initialState, action) => {
  switch (action.type) {
    //TODO: ADD POLICY
    case ADD_RENEWAL_POLICY:
      return {
        ...state,
        manipulateRenewalPolicyLoading: true
      };
    case ADD_RENEWAL_POLICY_SUCCESS:
      return {
        ...state,
        manipulateRenewalPolicyLoading: false,
        renewalPolicies: state.renewalPolicies.filter(policy => policy.id !== action.payload.renewal_policy_id)
      };
    case LOCAL_UPDATE_RENEWAL_POLICY_BY_SOCKET:
      return {
        ...state,
        renewalPolicies: state.renewalPolicies.filter(policy => policy.id !== action.payload.id)
      };
    case ADD_RENEWAL_POLICY_FAILURE:
      return {
        ...state,
        manipulateRenewalPolicyLoading: false,
        cyError: action.payload.error
      };

    //EDIT policy
    case UPDATE_RENEWAL_POLICY:
      return {
        ...state,
        manipulateRenewalPolicyLoading: true
      };
    case UPDATE_RENEWAL_POLICY_SUCCESS:
      let renewalPolicies = map(state.renewalPolicies, policy => {
        if (policy.id === action.payload.policy.id) {
          return action.payload.policy;
        } else {
          return policy;
        }
      });
      return {
        ...state,
        manipulateRenewalPolicyLoading: false,
        renewalPolicies: renewalPolicies
      };

    case UPDATE_RENEWAL_POLICY_FAILURE:
      return {
        ...state,
        manipulateRenewalPolicyLoading: false,
        manipulateRenewalPolicyError: action.payload.error
      };

    //REMOVE policy
    case DELETE_RENEWAL_POLICY:
      return {
        ...state,
        removeRenewalPolicyLoading: true
      };
    case DELETE_RENEWAL_POLICY_SUCCESS:
      remove(state.renewalPolicies, { id: action.payload.policyId });
      return {
        ...state,
        removeRenewalPolicyLoading: false
      };

    case DELETE_RENEWAL_POLICY_FAILURE:
      return {
        ...state,
        removeRenewalPolicyLoading: false,
        removeRenewalPolicyError: action.payload.error
      };

    //GET renewalPolicies
    case GET_RENEWAL_POLICIES:
      return {
        ...state,
        fetchingRenewalPoliciesLoading: true

        // isRedirect: false,
      };
    case GET_RENEWAL_POLICIES_SUCCESS:
      // const policiesList = action.payload.sort(function (o1, o2) {
      //   return moment(o1.end_date).isBefore(o2.end_date) ? 1 : moment(o1.end_date).isAfter(o2.end_date) ? -1 : 0;
      // });
      return {
        ...state,
        renewalPolicies: action.payload,
        fetchingRenewalPoliciesLoading: false
      };
    case GET_RENEWAL_POLICIES_FAILURE:
      return {
        ...state,
        fetchingRenewalPoliciesLoading: true,
        fetchingRenewalPoliciesError: action.payload.error
      };

    case HENDLE_SEARCH_RENEWAL_POLICY:
      return {
        ...state,
        searchValue: action.payload.where,
        isRedirect: action.payload.isRedirect
      };

    case UPDATE_RENEWAL_POLICY_FROM_CLIENT:
      let updatedRenewalPolicies = map(state.renewalPolicies, policy => {
        if (policy.client_id === action.payload.client.id) {
          return { ...policy, client_name: action.payload.client.name, client_mobile: action.payload.client.mobile };
        } else {
          return policy;
        }
      });
      return {
        ...state,
        renewalPolicies: updatedRenewalPolicies
      };

    default:
      return state;
  }
};

export default renewalPolicy;

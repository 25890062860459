import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const isAuthenticated = true;
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const { user } = useSelector(({ auth }) => {
    return {
      user: auth.userData
    };
  });

  return (
    <Route
      {...rest}
      render={props => {
        if (user) {
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: "/auth/login", state: { from: props.location } }} />;
      }}
    />
  );
};

export default PrivateRoute;

import React from "react";
import Section from "@components/Section";
import { Space, Button, Input, Typography } from "antd";
import "./index.scss";
import ClientsTable from "@components/Clients/ClientsTable";
import ClientsAddOrEditModal from "@components/Clients/ClientsAddOrEditModal";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import _ from "lodash";
import numeral from "numeral";
import { isMobile } from "react-device-detect";

const { Title } = Typography;

const Clients = () => {
  const [searchText, setSearchText] = React.useState("");
  const { clients, loading } = useSelector(({ client, auth }) => {
    return {
      clients: client.clients,
      loading: client.fetchingClientLoading
    };
  });

  const handleChange = _.debounce(e => {
    setSearchText(
      e.target.value
        ? e.target.value
            .toLowerCase()
            .trim()
            .split(" ")
            .map(word => `(?=.*${word})`)
            .join("")
        : ""
    );
  }, 200);

  let filteredData = clients;

  if (searchText) {
    filteredData = filteredData.filter(client => {
      return (
        (client.name && client.name.toLowerCase().match(new RegExp(searchText))) ||
        (client.address && client.address.toLowerCase().match(new RegExp(searchText))) ||
        (client.mobile && client.mobile.toLowerCase().match(new RegExp(searchText))) ||
        (client.phone && client.phone.toLowerCase().match(new RegExp(searchText))) ||
        (client.acc_no && client.acc_no.toLowerCase().match(new RegExp(searchText)))
      );
    });
  }

  if (searchText) {
    filteredData = filteredData.sort((a, b) => {
      if (
        searchText
          .split("|")
          .map(word => {
            return a.name.toLowerCase().split(" ").includes(word);
          })
          .filter(bool => bool).length >
        searchText
          .split("|")
          .map(word => {
            return b.name.toLowerCase().split(" ").includes(word);
          })
          .filter(bool => bool).length
      ) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  return (
    <Section className="clients-page">
      <Space wrap className="clients-table-toolbar">
        <Title className="title" level={3}>
          {`Clients ${!loading ? `(${numeral(clients.length).format("0,0")})` : ""}`}
        </Title>

        <Space wrap size={isMobile ? "middle" : "small"}>
          <Input className="search-field" size="small" allowClear placeholder="Search by Name, Mobile, Phone & Acc..." onChange={e => handleChange(e)} />
          <ClientsAddOrEditModal
            component={
              <Button type="primary" size="small" icon={<PlusOutlined />}>
                Add
              </Button>
            }
            data={{}}
          />
        </Space>
      </Space>
      <ClientsTable data={filteredData} />
    </Section>
  );
};

export default Clients;

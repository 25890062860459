import React from "react";
import "./index.scss";
import VehicleNamesForm from "@components/VehicleNames/VehicleNamesForm";
import AddOrEditModal from "@components/AddOrEditModal";

const VehicleNamesAddOrEditModal = ({ isEdit, component, data, callBack }) => {
  return <AddOrEditModal component={component} formInitialData={data} isEdit={isEdit} title={isEdit ? "Edit vehicle make" : "Add vehicle make"} formComponent={VehicleNamesForm} callBack={callBack} />;
};

export default VehicleNamesAddOrEditModal;

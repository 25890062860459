import Section from "@components/Section";
import { Space, Button, Typography } from "antd";
import "./index.scss";
import ReferencesTable from "@components/References/ReferencesTable";
import ReferencesAddOrEditModal from "@components/References/ReferencesAddOrEditModal";
import { PlusOutlined } from "@ant-design/icons";
const { Title } = Typography;

const References = () => {
  return (
    <Section className="references-page">
      <Space wrap className="references-table-toolbar">
        <Title className="title" level={3}>
          References
        </Title>
        <ReferencesAddOrEditModal
          component={
            <Button type="primary" size="small" icon={<PlusOutlined />}>
              Add
            </Button>
          }
          data={{}}
        />
      </Space>
      <ReferencesTable />
    </Section>
  );
};

export default References;

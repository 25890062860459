import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Form, Input, Button, Alert, Image } from "antd"; //Typography

import { useDispatch, useSelector } from "react-redux";

import { login } from "@redux/action";
import Logo from "@images/logo.png";
import "./index.scss";

// const { Title } = Typography;

const Login = () => {
  const dispatch = useDispatch();

  const [isErrorMsg, setErrorMsg] = useState(false);

  const { loading, error } = useSelector(({ auth }) => {
    return {
      loading: auth.loginLoading,
      error: auth.loginError,
      user: auth.userData
    };
  });

  const handleLoginSuccess = () => {};

  const handleLoginFailure = () => {
    setErrorMsg(true);
  };

  const onFinish = values => {
    dispatch(login(values, handleLoginSuccess, handleLoginFailure));
  };

  return (
    <div className="form">
      <Image src={Logo} preview={false} />
      <Form layout="vertical" initialValues={{ remember: false }} requiredMark={false} onFinish={onFinish}>
        {/* <Title level={3}>Login</Title> */}

        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!"
            }
          ]}
        >
          <Input size="large" placeholder="Enter username" />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
          <Input.Password size="large" placeholder="Enter password" />
        </Form.Item>

        {isErrorMsg && <Alert type="error" showIcon message={error} style={{ marginTop: "20px" }} closable onClose={() => setErrorMsg(false)} />}

        <Form.Item style={{ marginTop: "26px" }}>
          <Button type="primary" size="large" htmlType="submit" block loading={loading}>
            Login
          </Button>
        </Form.Item>
        {/* <div style={{ marginTop: '30px' }}>
        Don't have an account yet? <Link to="/auth/register">Sign up here</Link>
      </div> */}
      </Form>
    </div>
  );
};

export default Login;

import React from 'react'
import { Row, Col } from 'antd'

import './index.scss'


  const AuthLayout = ({ children }) => {


    return (

      <div>
      <Row style={{ height: '100vh' }}>
        <Col xs={24} sm={24} md={12} lg={12} 
        className="left"
        >
          <span></span>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} 
        className="right"
        >
        {children}
        </Col>
      </Row>
    </div>
    );
}

export default AuthLayout;

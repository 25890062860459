import React, { useState } from "react";
import { Table, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined, CloseCircleTwoTone, CheckCircleTwoTone, SyncOutlined, RetweetOutlined, FileDoneOutlined, CommentOutlined, ClockCircleTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { deleteLicence } from "@redux/action";
import Confirmation from "@components/Confirmation";
import LicencesAddOrEditModal from "@components/Licences/LicencesAddOrEditModal";
import UsernameAvatar from "@components/UsernameAvatar";
import moment from "moment";
// import LicenceApplicationStatusTag from "../LicenceApplicationStatusTag";
import { isMobile } from "react-device-detect";

const PoliciesTable = ({ data }) => {
  // const dispatch = useDispatch();

  const {
    loading,
    removeLoading,
    user: { is_master_access }
  } = useSelector(({ licences, auth }) => {
    return {
      loading: licences.fetchingLicencesLoading,
      removeLoading: licences.removeLicenceLoading,
      user: auth.userData
    };
  });

  // useEffect(() => {
  //   !data.length && dispatch(getLicence());
  // }, [dispatch, data.length]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChangeTable = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "center",
      ellipsis: true,
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <div>
              {record.status ? (
                <Tooltip placement="bottom" title="Renewed">
                  <SyncOutlined style={{ fontSize: "22px", color: "#08c" }} />
                </Tooltip>
              ) : !record.expire_date ? (
                <Tooltip placement="bottom" title="In Process">
                  <ClockCircleTwoTone twoToneColor="#4285f4" style={{ fontSize: "22px" }} />
                </Tooltip>
              ) : moment(moment(record.expire_date).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? (
                <Tooltip placement="bottom" title="Expired">
                  <CloseCircleTwoTone twoToneColor="#f44336" style={{ fontSize: "22px" }} />
                </Tooltip>
              ) : (
                <Tooltip placement="bottom" title="Active">
                  <CheckCircleTwoTone twoToneColor="#4caf50" style={{ fontSize: "22px" }} />
                </Tooltip>
              )}
            </div>
          )
        };
      }
    },
    {
      title: "",
      dataIndex: "is_form_taken",
      key: "application_status",
      align: "center",
      ellipsis: true,
      width: 50,
      render(text, record) {
        return {
          children: record.is_form_taken ? (
            <Tooltip placement="bottom" title="Form Taken">
              <FileDoneOutlined style={{ fontSize: "18px" }} />
            </Tooltip>
          ) : (
            ""
          )
        };
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      filteredValue: filteredInfo.name || null,
      // sorter: (a, b) => a.name.localeCompare(b.name),
      // sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      // showSorterTooltip: false,
      ellipsis: true,
      render(text, record) {
        return {
          children: (
            <Tooltip placement="bottom" title={text}>
              <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>{text.length > 30 ? text.slice(0, 25) + "..." : text}</div>
            </Tooltip>
          )
        };
      }
    },
    {
      title: "Licence No",
      dataIndex: "licence_no",
      key: "licence_no",
      filteredValue: filteredInfo.licence_no || null,
      sortOrder: sortedInfo.columnKey === "licence_no" && sortedInfo.order,
      ellipsis: true,
      width: 130,
      render(text, record) {
        return {
          children: <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>{text}</div>
        };
      }
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 150,
      filteredValue: filteredInfo.address || null,
      // sorter: (a, b) => a.address.localeCompare(b.address),
      sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,
      showSorterTooltip: false,
      ellipsis: true,
      render(text, record) {
        return {
          children: (
            <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>
              <Tooltip placement="bottom" title={text}>
                {text.length > 30 ? text.slice(0, 25) + "..." : text}
              </Tooltip>
            </div>
          )
        };
      }
    },
    {
      title: "Mob No.",
      dataIndex: "mobile",
      key: "mobile",
      width: "120px",
      filteredValue: filteredInfo.mobile || null,
      sortOrder: sortedInfo.columnKey === "mobile" && sortedInfo.order,
      ellipsis: true,
      render(text, record) {
        return {
          children: <div>{text ? <a href={`tel:+${text}`}>{`+${text}`}</a> : ""}</div>
        };
      }
    },
    {
      title: "Computer Test Date",
      dataIndex: "computer_test_date",
      key: "computer_test_date",
      filteredValue: filteredInfo.computer_test_date || null,
      sorter: (a, b) => moment(a.computer_test_date).unix() - moment(b.computer_test_date).unix(),
      showSorterTooltip: false,
      sortOrder: sortedInfo.columnKey === "computer_test_date" && sortedInfo.order,
      ellipsis: true,
      width: 150,
      render(text, record) {
        return {
          children: <div>{text ? moment(text).format("DD-MM-YYYY h:mm a") : ""}</div>
        };
      }
    },
    {
      title: "Driving Test Date",
      dataIndex: "drive_test_date",
      key: "drive_test_date",
      filteredValue: filteredInfo.drive_test_date || null,
      sorter: (a, b) => moment(a.drive_test_date).unix() - moment(b.drive_test_date).unix(),
      showSorterTooltip: false,
      sortOrder: sortedInfo.columnKey === "drive_test_date" && sortedInfo.order,
      ellipsis: true,
      width: 150,
      render(text, record) {
        return {
          children: <div>{text ? moment(text).format("DD-MM-YYYY h:mm a") : ""}</div>
        };
      }
    },
    {
      title: "Expire Date",
      dataIndex: "expire_date",
      key: "expire_date",
      filteredValue: filteredInfo.expire_date || null,
      sorter: (a, b) => moment(a.expire_date).unix() - moment(b.expire_date).unix(),
      showSorterTooltip: false,
      sortOrder: sortedInfo.columnKey === "expire_date" && sortedInfo.order,
      ellipsis: true,
      width: 130,
      render(text, record) {
        return {
          children: text ? (
            <div>
              <div>{`${moment(text).format("DD-MM-YYYY")}`}</div>
              <div style={{ fontSize: "10px" }}>{`(${moment(text).endOf("day").fromNow()})`}</div>
            </div>
          ) : (
            ""
          )
        };
      }
    },
    {
      title: "",
      dataIndex: "remark",
      key: "remark",
      filteredValue: filteredInfo.remark || null,
      sortOrder: sortedInfo.columnKey === "remark" && sortedInfo.order,
      ellipsis: true,
      width: 40,
      render(text, record) {
        return {
          children: (
            <Tooltip placement="bottomRight" title={text}>
              {text ? <CommentOutlined style={{ fontSize: "18px" }} /> : ""}
            </Tooltip>
          )
        };
      }
    },
    {
      title: "",
      dataIndex: "updated_by",
      key: "updated_by",
      align: "center",
      width: 40,
      render(text) {
        return {
          children: <UsernameAvatar username={text} />
        };
      }
    },

    {
      title: "",
      dataIndex: "",
      key: "",
      ellipsis: true,
      fixed: isMobile ? "unset" : "right",
      width: 100,
      render(text, record) {
        return {
          children: record ? (
            <div className="action-buttons">
              {!record.status && !!record.expire_date ? (
                <LicencesAddOrEditModal
                  component={
                    <Tooltip title="Renew" placement="bottom">
                      <RetweetOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />
                    </Tooltip>
                  }
                  isEdit={true}
                  data={record}
                  isRenew={true}
                />
              ) : (
                <RetweetOutlined style={{ fontSize: "18px", color: "#08c", visibility: "hidden" }} theme="outlined" />
              )}
              <LicencesAddOrEditModal
                component={
                  <Tooltip title="Edit" placement="bottom">
                    <EditOutlined style={{ fontSize: "18px", color: "#08c", cursor: "pointer" }} theme="outlined" />
                  </Tooltip>
                }
                isEdit={true}
                data={record}
              />
              {is_master_access && (
                <Confirmation
                  title="Are you sure to delete this?"
                  dispatchActionMethod={deleteLicence}
                  loading={removeLoading}
                  data={record}
                  component={
                    <Tooltip title="Delete" placement="bottom">
                      <DeleteOutlined style={{ fontSize: "18px", cursor: "pointer" }} />{" "}
                    </Tooltip>
                  }
                />
              )}
            </div>
          ) : (
            ""
          )
        };
      }
    }
  ];

  return <Table bordered size="small" scroll={{ x: 1160 }} columns={columns} dataSource={data} onChange={handleChangeTable} loading={loading} />;
};

export default PoliciesTable;

//auth

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const AUTH_USER_LOGOUT = "AUTH_USER_LOGOUT";
export const AUTH_USER_LOGOUT_SUCCESS = "AUTH_USER_LOGOUT_SUCCESS";
export const AUTH_USER_LOGOUT_FAILURE = "AUTH_USER_LOGOUT_FAILURE";

//policies
export const ADD_POLICY = "ADD_POLICY";
export const ADD_POLICY_SUCCESS = "ADD_POLICY_SUCCESS";
export const ADD_POLICY_FAILURE = "ADD_POLICY_FAILURE";

export const EDIT_POLICY = "EDIT_POLICY";
export const EDIT_POLICY_SUCCESS = "EDIT_POLICY_SUCCESS";
export const EDIT_POLICY_FAILURE = "EDIT_POLICY_FAILURE";

export const UPDATE_POLICY_FROM_CLIENT = "UPDATE_POLICY_FROM_CLIENT";

export const REMOVE_POLICY = "REMOVE_POLICY";
export const REMOVE_POLICY_SUCCESS = "REMOVE_POLICY_SUCCESS";
export const REMOVE_POLICY_FAILURE = "REMOVE_POLICY_FAILURE";

export const GET_POLICIES = "GET_POLICIES";
export const GET_POLICIES_SUCCESS = "GET_POLICIES_SUCCESS";
export const GET_POLICIES_FAILURE = "GET_POLICIES_FAILURE";

export const GET_POLICIES_BY_SEARCH_TEXT = "GET_POLICIES_BY_SEARCH_TEXT";
export const GET_POLICIES_BY_SEARCH_TEXT_SUCCESS = "GET_POLICIES_BY_SEARCH_TEXT_SUCCESS";
export const GET_POLICIES_BY_SEARCH_TEXT_FAILURE = "GET_POLICIES_BY_SEARCH_TEXT_FAILURE";

export const LOCAL_ADD_POLICY_BY_SOCKET = "LOCAL_ADD_POLICY_BY_SOCKET";
export const LOCAL_UPDATE_POLICY_BY_SOCKET = "LOCAL_UPDATE_POLICY_BY_SOCKET";
export const LOCAL_DELETE_POLICY_BY_SOCKET = "LOCAL_DELETE_POLICY_BY_SOCKET";

export const ADD_POLICY_RENEWAL = "ADD_POLICY_RENEWAL";
export const ADD_POLICY_RENEWAL_SUCCESS = "ADD_POLICY_RENEWAL_SUCCESS";
export const ADD_POLICY_RENEWAL_FAILURE = "ADD_POLICY_RENEWAL_FAILURE";

export const GET_VEHICLE_NAMES = "GET_VEHICLE_NAMES";
export const GET_VEHICLE_NAMES_SUCCESS = "GET_VEHICLE_NAMES_SUCCESS";
export const GET_VEHICLE_NAMES_FAILURE = "GET_VEHICLE_NAMES_FAILURE";

export const LOCAL_ADD_VEHICLE_NAME_BY_SOCKET = "LOCAL_ADD_VEHICLE_NAME_BY_SOCKET";

export const ADD_VEHICLE_NAME = "ADD_VEHICLE_NAME";
export const ADD_VEHICLE_NAME_SUCCESS = "ADD_VEHICLE_NAME_SUCCESS";
export const ADD_VEHICLE_NAME_FAILURE = "ADD_VEHICLE_NAME_FAILURE";

//Agencies
export const ADD_AGENCY = "ADD_AGENCY";
export const ADD_AGENCY_SUCCESS = "ADD_AGENCY_SUCCESS";
export const ADD_AGENCY_FAILURE = "ADD_AGENCY_FAILURE";

export const EDIT_AGENCY = "EDIT_AGENCY";
export const EDIT_AGENCY_SUCCESS = "EDIT_AGENCY_SUCCESS";
export const EDIT_AGENCY_FAILURE = "EDIT_AGENCY_FAILURE";

export const REMOVE_AGENCY = "REMOVE_AGENCY";
export const REMOVE_AGENCY_SUCCESS = "REMOVE_AGENCY_SUCCESS";
export const REMOVE_AGENCY_FAILURE = "REMOVE_AGENCY_FAILURE";

export const GET_AGENCIES = "GET_AGENCIES";
export const GET_AGENCIES_SUCCESS = "GET_AGENCIES_SUCCESS";
export const GET_AGENCIES_FAILURE = "GET_AGENCIES_FAILURE";

export const GET_AGENCIES_BY_COMPANY_ID = "GET_AGENCIES_BY_COMPANY_ID";
export const GET_AGENCIES_BY_COMPANY_ID_SUCCESS = "GET_AGENCIES_BY_COMPANY_ID_SUCCESS";
export const GET_AGENCIES_BY_COMPANY_ID_FAILURE = "GET_AGENCIES_BY_COMPANY_ID_FAILURE";

//References
export const ADD_REFERENCE = "ADD_REFERENCE";
export const ADD_REFERENCE_SUCCESS = "ADD_REFERENCE_SUCCESS";
export const ADD_REFERENCE_FAILURE = "ADD_REFERENCE_FAILURE";

export const EDIT_REFERENCE = "EDIT_REFERENCE";
export const EDIT_REFERENCE_SUCCESS = "EDIT_REFERENCE_SUCCESS";
export const EDIT_REFERENCE_FAILURE = "EDIT_REFERENCE_FAILURE";

export const REMOVE_REFERENCE = "REMOVE_REFERENCE";
export const REMOVE_REFERENCE_SUCCESS = "REMOVE_REFERENCE_SUCCESS";
export const REMOVE_REFERENCE_FAILURE = "REMOVE_REFERENCE_FAILURE";

export const GET_REFERENCES = "GET_REFERENCES";
export const GET_REFERENCES_SUCCESS = "GET_REFERENCES_SUCCESS";
export const GET_REFERENCES_FAILURE = "GET_REFERENCES_FAILURE";

//policy type

export const ADD_POLICYTYPE = "ADD_POLICYTYPE";
export const ADD_POLICYTYPE_SUCCESS = "ADD_POLICYTYPE_SUCCESS";
export const ADD_POLICYTYPE_FAILURE = "ADD_POLICYTYPE_FAILURE";

export const EDIT_POLICYTYPE = "EDIT_POLICYTYPE";
export const EDIT_POLICYTYPE_SUCCESS = "EDIT_POLICYTYPE_SUCCESS";
export const EDIT_POLICYTYPE_FAILURE = "EDIT_POLICYTYPE_FAILURE";

export const REMOVE_POLICYTYPE = "REMOVE_POLICYTYPE";
export const REMOVE_POLICYTYPE_SUCCESS = "REMOVE_POLICYTYPE_SUCCESS";
export const REMOVE_POLICYTYPE_FAILURE = "REMOVE_POLICYTYPE_FAILURE";

export const GET_POLICYTYPES = "GET_POLICYTYPES";
export const GET_POLICYTYPES_SUCCESS = "GET_POLICYTYPES_SUCCESS";
export const GET_POLICYTYPES_FAILURE = "GET_POLICYTYPES_FAILURE";

//Client

export const ADD_CLIENT = "ADD_CLIENT";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";

export const EDIT_CLIENT = "EDIT_CLIENT";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_FAILURE = "EDIT_CLIENT_FAILURE";

export const REMOVE_CLIENT = "REMOVE_CLIENT";
export const REMOVE_CLIENT_SUCCESS = "REMOVE_CLIENT_SUCCESS";
export const REMOVE_CLIENT_FAILURE = "REMOVE_CLIENT_FAILURE";

export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

export const GET_CLIENTS_BY_SEARCH_NAME = "GET_CLIENTS_BY_SEARCH_NAME";
export const GET_CLIENTS_BY_SEARCH_NAME_SUCCESS = "GET_CLIENTS_BY_SEARCH_NAME_SUCCESS";
export const GET_CLIENTS_BY_SEARCH_NAME_FAILURE = "GET_CLIENTS_BY_SEARCH_NAME_FAILURE";

export const CLEAR_CLIENTS_BY_SEARCH_NAME = "CLEAR_CLIENTS_BY_SEARCH_NAME";

export const LOCAL_ADD_CLIENT_BY_SOCKET = "LOCAL_ADD_CLIENT_BY_SOCKET";
export const LOCAL_UPDATE_CLIENT_BY_SOCKET = "LOCAL_UPDATE_CLIENT_BY_SOCKET";
export const LOCAL_DELETE_CLIENT_BY_SOCKET = "LOCAL_DELETE_CLIENT_BY_SOCKET";

//company

export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";

export const EDIT_COMPANY = "EDIT_COMPANY";
export const EDIT_COMPANY_SUCCESS = "EDIT_COMPANY_SUCCESS";
export const EDIT_COMPANY_FAILURE = "EDIT_COMPANY_FAILURE";

export const REMOVE_COMPANY = "REMOVE_COMPANY";
export const REMOVE_COMPANY_SUCCESS = "REMOVE_COMPANY_SUCCESS";
export const REMOVE_COMPANY_FAILURE = "REMOVE_COMPANY_FAILURE";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";

//REPORT
export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";

//PAGINATION

export const HENDLE_PAGINATION_CLIENT = "HENDLE_PAGINATION_CLIENT";
export const HENDLE_PAGINATION_POLICY = "HENDLE_PAGINATION_POLICY";
export const HENDLE_PAGINATION_AGENCY = "HENDLE_PAGINATION_AGENCY";
export const HENDLE_PAGINATION_REFERENCE = "HENDLE_PAGINATION_REFERENCE";
export const HENDLE_PAGINATION_POLICYTYPE = "HENDLE_PAGINATION_POLICYTYPE";
export const HENDLE_PAGINATION_COMPANY = "HENDLE_PAGINATION_COMPANY";

// search value
export const HANDLE_SEARCH_CLIENT = "HANDLE_SEARCH_CLIENT";
export const HANDLE_SEARCH_POLICY = "HANDLE_SEARCH_POLICY";
export const HENDLE_SEARCH_RENEWAL_POLICY = "HENDLE_SEARCH_RENEWAL_POLICY";
export const HANDLE_SEARCH_AGENCY = "HANDLE_SEARCH_AGENCY";
export const HENDLE_SEARCH_REFERENCE = "HENDLE_SEARCH_REFERENCE";
export const HENDLE_SEARCH_POLICYTYPE = "HENDLE_SEARCH_POLICYTYPE";
export const HANDLE_SEARCH_COMPANY = "HANDLE_SEARCH_COMPANY";

// notification
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

//renewal policies

export const GET_RENEWAL_POLICIES = "GET_RENEWAL_POLICIES";
export const GET_RENEWAL_POLICIES_SUCCESS = "GET_RENEWAL_POLICIES_SUCCESS";
export const GET_RENEWAL_POLICIES_FAILURE = "GET_RENEWAL_POLICIES_FAILURE";

export const ADD_RENEWAL_POLICY = "ADD_RENEWAL_POLICY";
export const ADD_RENEWAL_POLICY_SUCCESS = "ADD_RENEWAL_POLICY_SUCCESS";
export const ADD_RENEWAL_POLICY_FAILURE = "ADD_RENEWAL_POLICY_FAILURE";

export const UPDATE_RENEWAL_POLICY = "UPDATE_RENEWAL_POLICY";
export const UPDATE_RENEWAL_POLICY_SUCCESS = "UPDATE_RENEWAL_POLICY_SUCCESS";
export const UPDATE_RENEWAL_POLICY_FAILURE = "UPDATE_RENEWAL_POLICY_FAILURE";

export const UPDATE_RENEWAL_POLICY_FROM_CLIENT = "UPDATE_RENEWAL_POLICY_FROM_CLIENT";

export const DELETE_RENEWAL_POLICY = "DELETE_RENEWAL_POLICY";
export const DELETE_RENEWAL_POLICY_SUCCESS = "DELETE_RENEWAL_POLICY_SUCCESS";
export const DELETE_RENEWAL_POLICY_FAILURE = "DELETE_RENEWAL_POLICY_FAILURE";

export const LOCAL_UPDATE_RENEWAL_POLICY_BY_SOCKET = "LOCAL_UPDATE_RENEWAL_POLICY_BY_SOCKET";

export const LOCAL_UPDATE_POLICY_RENEWAL_BY_SOCKET = "LOCAL_UPDATE_POLICY_RENEWAL_BY_SOCKET";

//licence

export const ADD_LICENCE = "ADD_LICENCE";
export const ADD_LICENCE_SUCCESS = "ADD_LICENCE_SUCCESS";
export const ADD_LICENCE_FAILURE = "ADD_LICENCE_FAILURE";

export const EDIT_LICENCE = "EDIT_LICENCE";
export const EDIT_LICENCE_SUCCESS = "EDIT_LICENCE_SUCCESS";
export const EDIT_LICENCE_FAILURE = "EDIT_LICENCE_FAILURE";

export const REMOVE_LICENCE = "REMOVE_LICENCE";
export const REMOVE_LICENCE_SUCCESS = "REMOVE_LICENCE_SUCCESS";
export const REMOVE_LICENCE_FAILURE = "REMOVE_LICENCE_FAILURE";

export const GET_LICENCES = "GET_LICENCES";
export const GET_LICENCES_SUCCESS = "GET_LICENCES_SUCCESS";
export const GET_LICENCES_FAILURE = "GET_LICENCES_FAILURE";

export const LOCAL_ADD_LICENCE_BY_SOCKET = "LOCAL_ADD_LICENCE_BY_SOCKET";
export const LOCAL_UPDATE_LICENCE_BY_SOCKET = "LOCAL_UPDATE_LICENCE_BY_SOCKET";
export const LOCAL_DELETE_LICENCE_BY_SOCKET = "LOCAL_DELETE_LICENCE_BY_SOCKET";

export const ADD_LICENCE_RENEWAL = "ADD_LICENCE_RENEWAL";
export const ADD_LICENCE_SUCCESS_RENEWAL = "ADD_LICENCE_SUCCESS_RENEWAL";
export const ADD_LICENCE_FAILURE_RENEWAL = "ADD_LICENCE_FAILURE_RENEWAL";
